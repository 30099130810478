import React, { FC } from "react";
import { ToastContainer } from "react-toastify";
import { ToastCloseButton } from "./components";
import { generateClassName } from "./utils";

export const Toast: FC = () => {
  return (
    <ToastContainer
      toastClassName={generateClassName}
      closeButton={ToastCloseButton}
      bodyClassName="w-fit m-0 p-0 h-min"
      className="text-sm font-semibold max-w-xs flex flex-col items-end"
      position="top-right"
      autoClose={5000}
      icon={false}
      newestOnTop={false}
      closeOnClick
      pauseOnFocusLoss={false}
      hideProgressBar
      rtl={false}
    />
  );
};
