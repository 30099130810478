import moment from "moment";
import { TimeRange, Timesheet } from "types/timesheets";

export const getDailyTimesheetsForMonth = (
  timesheets: Timesheet[],
  activeMonth: TimeRange
) => {
  const startOfMonth = moment(activeMonth.start);
  const endOfMonth = moment(activeMonth.end);

  const days = [];

  for (let day = startOfMonth.date(); day <= endOfMonth.date(); day++) {
    const todaysTimesheets = timesheets
      .filter((timesheet) => moment(timesheet.startTime).date() === day)
      .toSorted((timesheet1, timesheet2) =>
        timesheet1.startTime.localeCompare(timesheet2.startTime)
      );

    days.push(todaysTimesheets);
  }

  return days;
};
