import { QueryHookOptions, gql, useQuery } from "@apollo/client";
import { Project } from "types/assignments";

export type GetProjectData = {
  project: Pick<
    Project,
    | "id"
    | "name"
    | "startDate"
    | "endDate"
    | "billingType"
    | "status"
    | "serviceType"
    | "category"
    | "headcount"
    | "deliveryManagerId"
  >;
};

export type GetProjectVars = {
  projectId: string;
};

export const GET_PROJECT = gql`
  query GetProject($projectId: uuid!) {
    project: projects_by_pk(id: $projectId) {
      id
      name
      status
      endDate: end_date
      startDate: start_date
      serviceType: service_type
      billingType: billing_type
      category
      headcount(args: {})
      deliveryManagerId: supervisor_id
    }
  }
`;

export const useProject = (
  options?: QueryHookOptions<GetProjectData, GetProjectVars>
) => useQuery<GetProjectData, GetProjectVars>(GET_PROJECT, options);
