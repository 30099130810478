import { Table } from "@tanstack/react-table";
import { Search, SearchProps } from "components";
import { TableData } from "components/table/types";
import React, { ReactElement, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

const DEBOUNCE_WAIT_IN_MS = 300;

type SearchFilterProps<D extends TableData> = Partial<SearchProps> &
  Pick<Table<D>, "setGlobalFilter"> & {
    globalFilter: string | undefined;
  };

export const SearchFilter = <D extends TableData>({
  globalFilter,
  setGlobalFilter,
  ...props
}: SearchFilterProps<D>): ReactElement => {
  const [searchValue, setSearchValue] = useState(globalFilter);
  const setFiltersDebounce = useDebouncedCallback<SearchProps["handleChange"]>(
    (value = "") => {
      setGlobalFilter(value);
    },
    DEBOUNCE_WAIT_IN_MS
  );

  useEffect(() => {
    setSearchValue(globalFilter);
  }, [globalFilter]);

  return (
    <Search
      handleChange={(value) => {
        setSearchValue(value);
        setFiltersDebounce(value);
      }}
      value={searchValue || ""}
      {...props}
    />
  );
};
