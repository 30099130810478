import { ApolloError } from "@apollo/client";
import { Button, Drawer, Error, Loading, TabPanel, Tabs } from "components";
import { useUpdateAssignedNotifications } from "hooks";
import React, { FC, useCallback, useState } from "react";
import {
  NOTIFICATION_STATUS,
  NotificationAssignment,
} from "types/notifications";
import { NOTIFICATIONS_TAB, NOTIFICATIONS_TABS } from "types/tabs";
import { NotificationsList } from "./components/NotificationsList";
import { NotificationsPaginationTabs } from "../notificationsInfo/useNotificationsInfo";

type NotificationsSidebarProps = {
  handleClose: () => void;
  notificationsAll: NotificationAssignment[];
  notificationsUnread: NotificationAssignment[];
  changePage: (tab: NOTIFICATIONS_TAB, page: number) => void;
  paginationAll: NotificationsPaginationTabs;
  paginationUnread: NotificationsPaginationTabs;
  loading: boolean;
  error: ApolloError | undefined;
};

export const NotificationsSidebar: FC<NotificationsSidebarProps> = ({
  handleClose,
  notificationsAll,
  notificationsUnread,
  changePage,
  paginationAll,
  paginationUnread,
  loading,
  error,
}) => {
  const { updateAssignedNotifications } = useUpdateAssignedNotifications();

  const markNotificationsAsRead = useCallback(async () => {
    await updateAssignedNotifications({
      assignedNotifications: notificationsUnread.map(({ id }) => ({ id })),
      status: NOTIFICATION_STATUS.READ,
    });
  }, [notificationsUnread, updateAssignedNotifications]);

  const [activeTab, setActiveTab] = useState<NOTIFICATIONS_TAB>(
    NOTIFICATIONS_TAB.UNREAD
  );

  return (
    <Drawer isOpen onClose={handleClose} title="Notifications">
      <Tabs
        tabs={NOTIFICATIONS_TABS}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        withSearchParams={false}
      />
      <div className="flex h-full flex-col items-end justify-center">
        {error ? (
          <Error />
        ) : loading ? (
          <Loading />
        ) : (
          <>
            <TabPanel
              activeTab={activeTab}
              className="flex h-full w-full flex-col items-center px-0 md:pt-5"
            >
              {activeTab === NOTIFICATIONS_TAB.UNREAD && (
                <NotificationsList
                  notifications={notificationsUnread}
                  onItemClick={handleClose}
                  pagination={paginationUnread}
                  changePage={(page) =>
                    changePage(NOTIFICATIONS_TAB.UNREAD, page)
                  }
                />
              )}
              {activeTab === NOTIFICATIONS_TAB.ALL && (
                <NotificationsList
                  notifications={notificationsAll}
                  onItemClick={handleClose}
                  pagination={paginationAll}
                  changePage={(page) => changePage(NOTIFICATIONS_TAB.ALL, page)}
                />
              )}
            </TabPanel>

            <div className="flex justify-end gap-2">
              <Button
                variant="primary"
                data-cy="cancel-button"
                onClick={handleClose}
              >
                Close
              </Button>
              <Button
                variant="secondary"
                onClick={markNotificationsAsRead}
                data-cy="mark-as-read-button"
                disabled={
                  notificationsUnread && notificationsUnread.length === 0
                }
              >
                Mark all as read
              </Button>
            </div>
          </>
        )}
      </div>
    </Drawer>
  );
};
