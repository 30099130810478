import { SelectedValueType } from "components/breadcrumbsSelector/useBreadcrumbsSelectorInner";
import { useURLSearchParams } from "hooks";
import { useCallback, useState } from "react";
import { generatePath, useHistory } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { BreadcrumbsSelectorContentProps } from "./BreadcrumbsSelectorContent";

export enum SELECTOR_TABS {
  EMPLOYEE = "Employee",
  MY_EMPLOYEE = "My employee",
  PROJECT = "Project",
}
export type HandleOptionSelectType = ({
  selectedValue,
}: {
  selectedValue: SelectedValueType;
}) => void;

type UseBreadcrumbsSelectorContentProps = BreadcrumbsSelectorContentProps;

const DEBOUNCE_TIMEOUT_MS = 300;

export const useBreadcrumbsSelectorContent = ({
  handleCloseSelector,
  selectorConfig,
}: UseBreadcrumbsSelectorContentProps) => {
  const [searchValue, setSearchValue] = useState("");
  const [searchValueDebounced] = useDebounce(searchValue, DEBOUNCE_TIMEOUT_MS);

  const { searchParams } = useURLSearchParams();
  const history = useHistory();

  const [activeTab, setActiveTab] = useState<SELECTOR_TABS>(
    SELECTOR_TABS.MY_EMPLOYEE
  );

  const handleOptionSelect = useCallback<HandleOptionSelectType>(
    ({ selectedValue }) => {
      handleCloseSelector(selectedValue);

      if (selectedValue.type === SELECTOR_TABS.MY_EMPLOYEE) {
        searchParams.set("myEmployees", "true");
      } else {
        searchParams.delete("myEmployees");
      }

      const path = generatePath(selectorConfig?.detailsItem?.path ?? "", {
        employeeId: selectedValue.id,
      });

      if (selectorConfig?.detailsItem?.withSearchParam) {
        searchParams.set("employeeId", selectedValue.id);
      }
      history.push({ search: `?${searchParams}`, pathname: path });
    },
    [handleCloseSelector, history, searchParams, selectorConfig]
  );

  return {
    searchValueDebounced,
    searchValue,
    setSearchValue,
    activeTab,
    setActiveTab,
    handleOptionSelect,
  };
};
