import React, { FC, PropsWithChildren } from "react";

interface BreadcrumbsSelectorTabPanelProps extends PropsWithChildren {
  activeTab: string;
  tabLabel: string;
}

export const BreadcrumbsSelectorTabPanel: FC<
  BreadcrumbsSelectorTabPanelProps
> = ({ activeTab, tabLabel, children }) => {
  return (
    <div
      role="tabpanel"
      hidden={activeTab !== tabLabel}
      id={`tabpanel-${tabLabel}`}
      aria-labelledby={`tab-${tabLabel}`}
      className="overflow-scroll sm:max-h-72 sm:w-105"
    >
      {activeTab === tabLabel && children}
    </div>
  );
};
