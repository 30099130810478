import { ColumnFilterValue } from "@tanstack/react-table";
import { Button, Dropdown } from "components";
import { TableData } from "components/table/types";
import React from "react";
import { FiltersProps } from "../../Filters";
import { UseFilters } from "../../useFilters";
import styles from "./ColumnMultiFilters.styles";
import {
  ColumnMultiFilterButtons,
  ColumnMultiFilterDropdownContent,
  ColumnMultiFilterItemsList,
  ColumnMultiFilterName,
} from "./components";

export const ADDITIONAL_FILTERS_KEY = "additionalFilters";

export type ColumnMultiFiltersProps<D extends TableData> = Omit<
  UseFilters,
  "isFiltersSectionOpen" | "setFiltersSectionOpen" | "applyCustomFilters"
> &
  Pick<
    FiltersProps<D>,
    "table" | "columnMultiFilters" | "columnAdditionalFilters"
  >;

export const ColumnMultiFilters = <D extends TableData>({
  applyFilters,
  columnMultiFilters,
  columnAdditionalFilters,
  checkedItems,
  closeOpenedFilter,
  openedFilter,
  handleCheck,
  toggleOpenedFilter,
  table,
  clearFilters,
  tableFiltersCount,
}: ColumnMultiFiltersProps<D>) => {
  const isOpenAdditional = openedFilter === ADDITIONAL_FILTERS_KEY;

  const getAdditionalFiltersCheckedValues = () => {
    const values: ColumnFilterValue = {};
    if (columnAdditionalFilters && columnAdditionalFilters.length) {
      columnAdditionalFilters.forEach((item) => {
        if (checkedItems[item.columnId]) {
          values[item.label] = item.label;
        }
      });
    }
    return values;
  };
  const checkedAdditionalValues = getAdditionalFiltersCheckedValues();

  return (
    <div className="flex flex-row flex-wrap gap-4">
      {!!columnMultiFilters?.length && (
        <>
          {columnMultiFilters.map((filter) => {
            const { columnId, withSearch, isTableFilteredWithCellData } =
              filter;
            const items = isTableFilteredWithCellData
              ? table
                  .getPreFilteredRowModel()
                  .flatRows?.map((row) => row.getValue<string>(columnId))
              : filter.items;

            const isOpen = openedFilter === columnId;
            const checkedValues = checkedItems[columnId];
            return (
              <Dropdown
                key={columnId}
                rootProps={{
                  open: isOpen,
                  onOpenChange: (open) => {
                    open ? toggleOpenedFilter(columnId) : closeOpenedFilter();
                  },
                }}
                triggerContent={
                  <ColumnMultiFilterName
                    checkedValues={checkedValues}
                    isOpen={isOpen}
                    toggleOpenedFilter={toggleOpenedFilter}
                    filter={filter}
                  />
                }
                contentContent={
                  <div className={styles.dropdown}>
                    <ColumnMultiFilterDropdownContent
                      withSearch={withSearch}
                      checkedValues={checkedValues}
                      handleCheck={handleCheck}
                      items={items}
                      columnId={columnId}
                    />
                    <ColumnMultiFilterButtons
                      onApply={() => applyFilters(columnId)}
                      onCancel={closeOpenedFilter}
                    />
                  </div>
                }
              />
            );
          })}
        </>
      )}
      {!!columnAdditionalFilters?.length && (
        <Dropdown
          rootProps={{
            open: isOpenAdditional,
            onOpenChange: (open) => {
              open
                ? toggleOpenedFilter(ADDITIONAL_FILTERS_KEY)
                : closeOpenedFilter();
            },
          }}
          triggerContent={
            <ColumnMultiFilterName
              checkedValues={checkedAdditionalValues}
              isOpen={isOpenAdditional}
              toggleOpenedFilter={toggleOpenedFilter}
              filter={{
                label: "Additional filters",
                columnId: ADDITIONAL_FILTERS_KEY,
                items: [],
              }}
            />
          }
          contentContent={
            <div className={styles.dropdown}>
              {columnAdditionalFilters.map((filter) => (
                <ColumnMultiFilterItemsList
                  key={filter.label}
                  checkedValues={checkedAdditionalValues}
                  handleCheck={(item) => handleCheck(filter.columnId, item)}
                  items={[filter.label]}
                  columnId={filter.columnId}
                />
              ))}
              <ColumnMultiFilterButtons
                onApply={() => applyFilters()}
                onCancel={closeOpenedFilter}
              />
            </div>
          }
        />
      )}
      {tableFiltersCount > 0 && (
        <Button
          onClick={clearFilters}
          className="p-0 text-purple-2 hover:underline"
        >
          Clear filters
        </Button>
      )}
    </div>
  );
};
