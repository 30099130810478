import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React, { FC, useState } from "react";
import { Arrow2Icon } from "resources/icons";

export type ItemComponentType<D, T extends object = object> = FC<
  { item: D } & T
>;

export type TableCellAccordionProps<D, T extends object = object> = {
  items?: D[];
  ItemComponent: ItemComponentType<D, T>;
  itemComponentProps?: T;
};

export function TableCellAccordion<D, T extends object = object>({
  items,
  ItemComponent,
  itemComponentProps,
}: TableCellAccordionProps<D, T>) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [firstItem, ...restItems] = items || [];
  return (
    <Accordion
      expanded={isExpanded}
      onChange={() => setIsExpanded((prev) => !prev)}
      elevation={0}
      onClick={(e) => items?.length && e.stopPropagation()}
      classes={{
        root: "bg-transparent h-full p-0 m-0 border-none shadow-none [&.Mui-disabled]:bg-transparent before:h-0",
      }}
      disabled={!items?.length}
      disableGutters
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        classes={{
          root: "[&.Mui-disabled]:opacity-100 text-grey-1 px-0 justify-start gap-2 min-h-5 max-h-5 mb-0",
          content: "m-0 grow-0 xs:grow",
        }}
        expandIcon={
          !!restItems?.length && <Arrow2Icon className="h-6 w-6 -rotate-90" />
        }
      >
        {firstItem ? (
          <ItemComponent item={firstItem} {...(itemComponentProps as T)} />
        ) : (
          "-"
        )}
      </AccordionSummary>
      <AccordionDetails
        classes={{
          root: "py-0 px-0",
        }}
      >
        <div className="mt-1 flex flex-col items-start gap-1">
          {!!restItems?.length &&
            restItems.map((item, i) => (
              <ItemComponent
                key={i}
                item={item}
                {...(itemComponentProps as T)}
              />
            ))}
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
