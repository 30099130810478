import {
  Button,
  DateTimePickerControl,
  Drawer,
  Field,
  Modal,
  ModalRefType,
} from "components";
import arrayMutators from "final-form-arrays";
import { useApproveRejectTimesheet } from "hooks";
import moment, { Moment } from "moment";
import React, { FC, useCallback, useRef, useState } from "react";
import { Form } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { InfoOutlinedIcon, LoadingIcon } from "resources/icons";
import {
  Timesheet,
  TimesheetApprovalFormType,
  TimesheetStateType,
} from "types/timesheets";
import { transformDatetimeToMinutes } from "utils/timesheets.utils";
import {
  FormCommonPropsType,
  useTimeSheetsWeekContext,
} from "../../../context";
import { DayOffInfo, TimesheetInfo, TimesheetProjectInfo } from "../components";
import styles from "./TimesheetApprovalForm.styles";
import { validator } from "./validators/validators";

const calculateTime = (inputValue: Moment | null, timesheet?: Timesheet) => {
  const inputTime = inputValue ? transformDatetimeToMinutes(inputValue) : 0;
  const minutesDifference = (timesheet?.minutes ?? 0) - inputTime;

  return moment(timesheet?.startTime).set({
    hours: minutesDifference > 0 ? Math.floor(minutesDifference / 60) : 0,
    minutes: minutesDifference > 0 ? minutesDifference % 60 : 0,
  });
};

// form to approve rejected log/day-off or edit approved log/day-off
export const TimesheetApprovalForm: FC<FormCommonPropsType> = ({
  handleModalClose,
  formData,
  switchApproveRejectForm,
}) => {
  const timesheets = formData?.timesheets;
  const isDayOff = timesheets?.[0].logType !== "standard_hours";
  const isEdit = timesheets?.[0].state === TimesheetStateType.APPROVED;

  const [isHoursWarningVisible, setHoursWarningVisible] =
    useState<boolean>(false);

  const initialValues = {
    timesheets: timesheets
      ?.sort(
        (a, b) =>
          new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
      )
      ?.map((t) => {
        const isBillable = t.assignment?.billableStatus;
        const billableTime = moment(t.startTime).set({
          hours: isBillable
            ? Math.floor((t.billableMinutes ?? t.minutes) / 60)
            : 0,
          minutes: isBillable ? (t.billableMinutes ?? t.minutes ?? 0) % 60 : 0,
        });
        const minutesDifference = t.minutes - (t.billableMinutes ?? 0);
        const nonBillableTime = moment(t.startTime).set({
          hours:
            isBillable || minutesDifference < 0
              ? 0
              : Math.floor(minutesDifference / 60),
          minutes:
            isBillable || minutesDifference < 0 ? 0 : minutesDifference % 60,
        });
        return {
          ...t,
          billableTime,
          nonBillableTime,
        };
      }),
  };
  const context = useTimeSheetsWeekContext();
  const { approveTimesheet } = useApproveRejectTimesheet(context);

  const modalRef = useRef<ModalRefType>(null);
  const handleSubmit = useCallback(
    async (values: TimesheetApprovalFormType) => {
      const mappedValues = values.timesheets.map((t) => ({
        id: t.id,
        billableMinutes: transformDatetimeToMinutes(t.billableTime),
      }));
      modalRef.current?.closeModal();
      await approveTimesheet(mappedValues);
      handleModalClose();
    },
    [approveTimesheet, handleModalClose]
  );
  return (
    <Drawer
      isOpen
      onClose={handleModalClose}
      title={`${isEdit ? "Edit approved" : "Approving"} ${
        isDayOff ? "absence" : "working hours"
      }`}
    >
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        keepDirtyOnReinitialize
        mutators={{
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
          ...arrayMutators,
        }}
        validate={validator}
      >
        {({ handleSubmit, submitting, hasValidationErrors, form }) => {
          return (
            <form className="flex h-full flex-col justify-between">
              <FieldArray name="timesheets">
                {({ fields }) => (
                  <div className="mb-8 max-h-110 overflow-auto pr-2">
                    {fields.map((name, index) => {
                      const timesheet: Timesheet = fields.value[index];
                      return (
                        <div key={name}>
                          {!!timesheet.assignment && (
                            <TimesheetProjectInfo
                              name={timesheet.assignment.project.name}
                            />
                          )}
                          {!!timesheet && (
                            <>
                              {isDayOff ? (
                                <DayOffInfo dayOff={timesheet} />
                              ) : (
                                <TimesheetInfo timesheet={timesheet} />
                              )}
                            </>
                          )}

                          {isDayOff ? null : (
                            <div className="flex gap-4">
                              <Field
                                name={`${name}.billableTime`}
                                label="Billable hours *"
                                component={DateTimePickerControl}
                                variant="time"
                                mask="__:__"
                                inputOnChange={(value: Moment | null) => {
                                  // set "nonBillableTime" input value when this changes
                                  if (value && value.isValid()) {
                                    const newNonBillableTime = calculateTime(
                                      value,
                                      timesheet
                                    );
                                    form.mutators.setValue(
                                      `${name}.nonBillableTime`,
                                      newNonBillableTime
                                    );

                                    const currentBillableTime = value
                                      ? transformDatetimeToMinutes(value)
                                      : 0;
                                    const showWarning =
                                      currentBillableTime >
                                      (timesheet?.minutes ?? 0);
                                    setHoursWarningVisible(showWarning);
                                  }
                                }}
                              />

                              <Field
                                name={`${name}.nonBillableTime`}
                                label="Non-Billable hours"
                                component={DateTimePickerControl}
                                variant="time"
                                mask="__:__"
                                inputOnChange={(value: Moment | null) => {
                                  // set "billableTime" input value when this changes
                                  if (value && value.isValid()) {
                                    const newBillableTime = calculateTime(
                                      value,
                                      timesheet
                                    );
                                    form.mutators.setValue(
                                      `${name}.billableTime`,
                                      newBillableTime
                                    );
                                  }
                                }}
                              />
                            </div>
                          )}

                          {isHoursWarningVisible && (
                            <div className={styles.warning}>
                              <InfoOutlinedIcon />
                              <span className="ml-1 text-xs text-grey-1">
                                More hours were entered than reported by the
                                employee
                              </span>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                )}
              </FieldArray>

              <div className="flex justify-end gap-2">
                <Button variant="secondary" onClick={handleModalClose}>
                  Cancel
                </Button>
                {isEdit && (
                  <Button
                    variant="secondary"
                    onClick={() => switchApproveRejectForm?.(timesheets)}
                  >
                    Change to rejected
                  </Button>
                )}
                <Button
                  variant="primary"
                  onClick={() => modalRef.current?.openModal()}
                  disabled={submitting || hasValidationErrors}
                  className={`${
                    isEdit ? "min-w-18" : "min-w-24"
                  } flex items-center justify-center`}
                >
                  {submitting ? (
                    <LoadingIcon className="animate-spin" />
                  ) : isEdit ? (
                    "Save"
                  ) : (
                    "Approve"
                  )}
                </Button>
              </div>
              <Modal
                ref={modalRef}
                onSubmit={handleSubmit}
                contentMessage={
                  isDayOff
                    ? "Approve employee absence plan."
                    : "Approve employee logged hours."
                }
              />
            </form>
          );
        }}
      </Form>
    </Drawer>
  );
};
