import { Header, UserAvatar } from "components";
import React, { FC } from "react";
import { EvaluationSurveyProps } from "../evaluationSurvey/EvaluationSurvey";

type SurveyViewHeaderProps = Pick<EvaluationSurveyProps, "employeeData">;

export const SurveyViewHeader: FC<SurveyViewHeaderProps> = ({
  employeeData,
}) => {
  return (
    <div className="mb-5 flex flex-wrap items-center justify-between gap-2 px-5 md:mb-0 md:px-0">
      <Header label="Talk2Grow" styles="md:mb-0 mb-0 px-0" />
      <div className="flex items-center md:hidden">
        <UserAvatar
          styles="w-9 h-9 mr-2 text-xs"
          picture={employeeData?.employee?.picture || ""}
          firstName={employeeData?.employee?.firstName || ""}
          lastName={employeeData?.employee?.lastName || ""}
        />
        <p className="text-sm font-semibold text-purple-1">
          {`${employeeData?.employee?.firstName} ${employeeData?.employee?.lastName}`}
        </p>
      </div>
    </div>
  );
};
