import { Search } from "components";
import { HandleCloseSelectorType } from "components/breadcrumbsSelector/useBreadcrumbsSelectorInner";
import { View } from "config/views";
import React, { FC } from "react";
import {
  BreadcrumbsSelectorTabPanel,
  BreadcrumbsSelectorTabs,
  EmployeesTabMemo,
  MyEmployeesTabMemo,
} from "./components";
import {
  SELECTOR_TABS,
  useBreadcrumbsSelectorContent,
} from "./useBreadcrumbsSelectorContent";

export type BreadcrumbsSelectorContentProps = {
  handleCloseSelector: HandleCloseSelectorType;
  selectorConfig: View["breadcrumbs"];
};

export const BreadcrumbsSelectorContent: FC<
  BreadcrumbsSelectorContentProps
> = ({ handleCloseSelector, selectorConfig }) => {
  const {
    searchValueDebounced,
    setSearchValue,
    activeTab,
    setActiveTab,
    searchValue,
    handleOptionSelect,
  } = useBreadcrumbsSelectorContent({ handleCloseSelector, selectorConfig });

  return (
    <>
      <BreadcrumbsSelectorTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <div className="py-4 sm:px-2">
        <Search value={searchValue} handleChange={setSearchValue} />
      </div>
      <BreadcrumbsSelectorTabPanel
        activeTab={activeTab}
        tabLabel={SELECTOR_TABS.EMPLOYEE}
      >
        <EmployeesTabMemo
          searchValue={searchValueDebounced}
          handleOptionSelect={handleOptionSelect}
        />
      </BreadcrumbsSelectorTabPanel>
      <BreadcrumbsSelectorTabPanel
        activeTab={activeTab}
        tabLabel={SELECTOR_TABS.MY_EMPLOYEE}
      >
        <MyEmployeesTabMemo
          searchValue={searchValueDebounced}
          handleOptionSelect={handleOptionSelect}
        />
      </BreadcrumbsSelectorTabPanel>
    </>
  );
};
