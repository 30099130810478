import { ApolloError, gql, useMutation } from "@apollo/client";
import { useCallback } from "react";
import { toast } from "react-toastify";

export interface SubmitAnswersData {
  errors: string;
  submitted: number;
  timestamp: string;
}

export const SUBMIT_ANSWERS = gql`
  mutation {
    submit_answers {
      errors
      submitted
      timestamp
    }
  }
`;

export const useSubmitAnswers = () => {
  const [processSubmitAnswers] = useMutation<SubmitAnswersData>(SUBMIT_ANSWERS);

  const submitAnswers = useCallback(async () => {
    try {
      await processSubmitAnswers();
    } catch (ex) {
      toast.error((ex as ApolloError)?.message);
    }
  }, [processSubmitAnswers]);

  return { submitAnswers };
};
