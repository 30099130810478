export const styles: Record<"actionButton" | "tableRow" | "table", string> = {
  table: `
    my-4
    border-[#ECEEF2] 
    w-full
    border-b-2`,
  tableRow: `
    min-w-full 
    odd:bg-purple-7 
    h-20`,
  actionButton: `
    xs:grow
    p-2 
    min-w-18`,
};
