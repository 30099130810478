const styles = {
  button: `
    md:text-purple-1
    md:text-sm 
    md:w-full
    md:bg-grey-7
    md:hover:bg-grey-6
    md:rounded-t-none
    md:hover:text-purple-1
    md:px-8 
    md:py-3`,
};

export default styles;
