import { Button } from "components";
import { getAnswerButtonStyle } from "pages/managerCareerPathForm/components/managerSurvey/components/questionsTable/questionsTable.styles";
import { getQuestionDetails } from "pages/managerCareerPathForm/components/utils";
import {
  AnswersGroupedByQuestionIdType,
  CurrentSubcategory,
  HandleManagerAnswerType,
} from "pages/managerCareerPathForm/hooks";
import React, { FC } from "react";
import { TableHead } from "../tableHead/TableHead";
import { getStyle } from "./evaluationQuestionsTable.styles";

type EvaluationQuestionsTableProps = {
  currentSubCategory: CurrentSubcategory;
  answersGroupedByQuestionId: AnswersGroupedByQuestionIdType;
  answersGroupedByQuestionIdState: AnswersGroupedByQuestionIdType;
  handleManagerAnswer: HandleManagerAnswerType;
  isEvaluationSurveyPreview: boolean;
};

export const EvaluationQuestionsTable: FC<EvaluationQuestionsTableProps> = ({
  currentSubCategory,
  answersGroupedByQuestionId,
  answersGroupedByQuestionIdState,
  handleManagerAnswer,
  isEvaluationSurveyPreview,
}) => {
  return (
    <table className="table-fixed border-collapse border-spacing-0">
      <TableHead
        categoryName={currentSubCategory?.categoryName}
        subCategoryName={currentSubCategory?.subCategoryName}
      />
      <tbody>
        {currentSubCategory?.questions?.map(({ id, text }) => {
          const {
            isEmployeeYesAnswer,
            isManagerYesAnswer,
            giveAnswerArgs,
            isFinalAnswer,
            isFinalYesAnswer,
            isFinalNoAnswer,
            managerAnswer,
          } = getQuestionDetails({
            answersGroupedByQuestionId,
            id,
            answersGroupedByQuestionIdState,
          });
          const style = getStyle(isFinalAnswer);
          return (
            <tr
              className={`h-20 min-w-full border-2 odd:bg-purple-7 ${style.row}`}
              key={id}
            >
              <td
                className={`w-auto py-4 pl-12 pr-3 text-sm font-medium ${style.cell}`}
              >
                {text}
              </td>
              <td className={`px-3 py-4 text-sm font-medium ${style.cell}`}>
                {isEmployeeYesAnswer ? "Yes" : "No"}
              </td>
              <td className={`px-3 py-4 text-sm font-medium ${style.cell}`}>
                {isManagerYesAnswer ? "Yes" : "No"}
              </td>

              {isEvaluationSurveyPreview ? (
                <td
                  className={`py-4 pl-3 pr-12 text-sm font-bold ${style.cell}`}
                >
                  {isFinalYesAnswer ? "Yes" : "No"}
                </td>
              ) : (
                <td className={`py-4 pl-3 pr-12 ${style.cell}`}>
                  <div className="flex gap-6">
                    <Button
                      variant={isFinalYesAnswer ? "primary" : "tertiary"}
                      className={getAnswerButtonStyle(
                        false,
                        false,
                        isFinalYesAnswer
                      )}
                      onClick={() =>
                        handleManagerAnswer({
                          id,
                          managerAnswer,
                          finalAnswer: true,
                          shouldNotGiveAnswer: isFinalYesAnswer,
                          giveAnswerArgs: {
                            ...giveAnswerArgs,
                            finalAnswer: true,
                          },
                        })
                      }
                    >
                      Yes
                    </Button>
                    <Button
                      variant={isFinalNoAnswer ? "primary" : "tertiary"}
                      className={getAnswerButtonStyle(
                        false,
                        false,
                        isFinalNoAnswer
                      )}
                      onClick={() =>
                        handleManagerAnswer({
                          id,
                          managerAnswer,
                          finalAnswer: false,
                          shouldNotGiveAnswer: isFinalNoAnswer,
                          giveAnswerArgs: {
                            ...giveAnswerArgs,
                            finalAnswer: false,
                          },
                        })
                      }
                    >
                      No
                    </Button>
                  </div>
                </td>
              )}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
