type Props = {
  isActive: boolean;
  hasAnswer: boolean;
  isDisabled: boolean;
};

const getStyles = ({ isActive, hasAnswer, isDisabled }: Props) => ({
  questionContainer: `
    w-full
    flex
    flex-col
    items-center
    mb-10
    last:mb-[50vh]`,
  questionText: `
    font-bold
    xs:text-sm
    text-base
    mb-8
    xs:text-left
    text-center
    ${
      isDisabled
        ? "text-neutral-n3"
        : hasAnswer || isActive
        ? "text-acai-dark-purple-3"
        : "text-neutral-n2"
    }`,
  buttonsContainer: `
    flex 
    sm:flex-col
    gap-4
    w-full 
    items-center`,
  chosen: `
    border-grey-2
    text-white
    bg-purple-3`,
  notChosen: `
    ${isDisabled ? "stroke-neutral-n3" : ""}
    border-neutral-n5`,
  answerButton: `
    group
    border-grey-2
    hover:border-purple-3
    hover:bg-purple-3
    hover:text-white
    border
    w-full 
    font-bold 
    text-left
    xs:text-sm
    text-base
    text-purple-1
    flex-row
    gap-4
    py-2 
    disabled:pointer-events-none
    disabled:border-neutral-n5
    disabled:bg-neutral-n5
    disabled:text-neutral-n3
    ease-linear 
    transition-all 
    duration-150
    px-4 
    sm:max-w-48`,
});

export default getStyles;
