import { ColumnFilterValue } from "@tanstack/react-table";
import { ColumnMultiFilterType } from "components/table/types";
import React, { forwardRef } from "react";
import { Arrow2Icon } from "resources/icons";
import styles from "./ColumnMultiFilterName.styles";

type ColumnMultiFilterNameProps = {
  checkedValues: ColumnFilterValue;
  isOpen: boolean;
  toggleOpenedFilter: (columnId: string) => void;
  filter: ColumnMultiFilterType;
};

export const ColumnMultiFilterName = forwardRef<
  HTMLDivElement,
  ColumnMultiFilterNameProps
>(({ checkedValues, isOpen, toggleOpenedFilter, filter, ...props }, ref) => {
  const { label, columnId } = filter;
  const filtersCount = (Object.keys(checkedValues ?? {}) ?? []).length;

  return (
    <div
      {...props}
      ref={ref}
      role="button"
      id={`dropdownRoot-${columnId}`}
      aria-expanded={isOpen}
      className={`${styles.dropdownRoot} ${
        filtersCount ? "border-purple-2" : isOpen ? "border-grey-1" : ""
      }`}
      onClick={() => toggleOpenedFilter(columnId)}
    >
      <span className="mr-3">{label}</span>
      <span className="flex">
        {filtersCount ? (
          <div className={styles.filtersCounter}>{filtersCount}</div>
        ) : null}
        <span
          className={`ml-2 [&>svg]:text-xl ${
            isOpen ? "rotate-90" : "-rotate-90"
          }`}
        >
          <Arrow2Icon />
        </span>
      </span>
    </div>
  );
});
ColumnMultiFilterName.displayName = "ColumnMultiFilterName";
