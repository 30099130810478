const styles = {
  background: `
    mx-4 
    py-8
    sm:mx-8 
    lg:py-0
    lg:my-auto
    flex 
    flex-col 
    justify-center
    items-center`,
  container: `
    m-auto
    flex 
    flex-col 
    w-full
    px-6
    py-12
    sm:px-24 
    sm:py-16 
    max-w-160
    rounded-sm 
    bg-white 
    items-center`,
};

export default styles;
