import { VIEWS } from "config/views";
import { useAuthContext } from "contexts";
import { useURLSearchParams } from "hooks";
import moment from "moment";
import { matchPath, useLocation } from "react-router-dom";

type MatchParams = { employeeId?: string; projectId?: string };

export const useGetParams = () => {
  const { isManager } = useAuthContext();
  const { searchParams } = useURLSearchParams();
  const { pathname } = useLocation();

  const foundItem = Object.values(isManager() ? VIEWS.manager : VIEWS.employee)
    ?.map((view) => {
      const match = matchPath<MatchParams>(pathname, {
        path: view.path,
        exact: true,
        strict: true,
      });
      return view.breadcrumbs?.visible && match ? { match, view } : null;
    })
    .filter(Boolean)?.[0];

  const projectId = foundItem?.match?.params?.projectId || "";
  const employeeId =
    searchParams.get("employeeId") ||
    foundItem?.match?.params?.employeeId ||
    "";
  const myEmployees = searchParams.get("myEmployees") || "";
  const startOfPrevWeek = moment()
    .startOf("week")
    .subtract(7, "days")
    .toISOString();
  const endOfPrevWeek = moment()
    .endOf("week")
    .subtract(7, "days")
    .toISOString();

  return {
    view: foundItem?.view,
    path: foundItem?.match?.path || "",
    employeeId,
    projectId,
    startOfPrevWeek,
    endOfPrevWeek,
    myEmployees,
  };
};
