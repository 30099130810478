import { gql, useQuery } from "@apollo/client";
import { Role } from "types/employee";

interface GetRolesData {
  roles: Role[];
}

const GET_ROLES = gql`
  query getRoles {
    roles: erp_employee_role(order_by: { erp_employee_role: asc }) {
      id
      name: erp_employee_role
    }
  }
`;

export const useRoles = () => useQuery<GetRolesData>(GET_ROLES);
