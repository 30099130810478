import axios, { AxiosError } from "axios";
import API from "config/api";
import { useAuthContext } from "contexts";
import { ProjectReportType } from "pages/projectReport/useProjectReport";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";

export type ExportReportParams = {
  filename?: string;
  params: { startDate?: string; endDate?: string; projectId?: string };
};

export const useGetProjectReport = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { token } = useAuthContext();

  const getProjectReportJson = useCallback(
    async ({ params }: ExportReportParams) => {
      setLoading(true);
      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}${API.projectReport}`,
          {
            start_day: params?.startDate,
            end_day: params?.endDate,
            project_id: params?.projectId,
          },
          {
            headers: {
              "Authorization": token ? `Bearer ${token}` : "",
              "Content-Type": "application/json",
            },
            transformResponse: (response) => {
              const data: ProjectReportType = JSON.parse(response);
              //TODO to remove when handleOnRowClick will provide whole row object instead of id
              return {
                ...data,
                employees: data?.employees?.map(
                  ({ id: _, employee_id, ...rest }) => ({
                    id: employee_id,
                    ...rest,
                  })
                ),
              };
            },
          }
        );
        return data;
      } catch (err) {
        setError(true);
        toast.error((err as AxiosError).message);
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  return { loading, error, getProjectReportJson };
};
