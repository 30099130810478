import React from "react";
import Select, { GroupBase, Props } from "react-select";
import styles from "./SelectFilter.styles";

export type SelectFilterOptionType<
  ValueType = string,
  ExtendedOptionType extends object = Record<string, unknown>
> = {
  value: ValueType;
  label: string;
} & ExtendedOptionType;

interface SelectFilterProps<
  ValueType = string,
  ExtendedOptionType extends object = Record<string, unknown>
> extends Props<
    SelectFilterOptionType<ValueType, ExtendedOptionType>,
    false,
    GroupBase<SelectFilterOptionType<ValueType, ExtendedOptionType>>
  > {
  placeholder?: string;
  isLoading?: boolean;
  isError?: boolean;
}

export function SelectFilter<
  ValueType = string,
  ExtendedOptionType extends object = Record<string, unknown>
>({
  isLoading,
  isError = false,
  placeholder = "Pick value",
  ...props
}: SelectFilterProps<ValueType, ExtendedOptionType>) {
  return (
    <Select<SelectFilterOptionType<ValueType, ExtendedOptionType>>
      placeholder={placeholder}
      isLoading={isLoading}
      noOptionsMessage={() =>
        isError ? "There is something wrong with data" : "No options"
      }
      classNames={{
        control: () => styles.control,
        valueContainer: () => styles.valueContainer,
        indicatorsContainer: () => styles.indicatorsContainer,
        input: () => styles.inputContainer,
        menuPortal: () => styles.menuPortal,
        option: ({ isFocused, isSelected }) =>
          `${styles.option} ${
            isSelected
              ? styles.optionSelected
              : isFocused
              ? styles.optionFocused
              : ""
          }`,
        multiValueRemove: () => styles.multiValueRemove,
      }}
      classNamePrefix="react-select"
      className="w-full sm:min-w-50"
      {...props}
    />
  );
}
