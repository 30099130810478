import axios, { AxiosError } from "axios";
import { useAuthContext } from "contexts";
import { useState } from "react";
import { toast } from "react-toastify";
import { downloadBlob } from "utils/general.utils";

export const useGetReport = () => {
  const [loading, setLoading] = useState(false);
  const { token } = useAuthContext();

  const getFile = async ({
    path,
    params,
  }: {
    path: string;
    params: Record<string, string | string[] | number>;
  }) => {
    setLoading(true);
    try {
      const { data, headers } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}${path}`,
        params,
        {
          headers: {
            "Authorization": token ? `Bearer ${token}` : "",
            "Content-Type": "application/json",
          },
          responseType: "blob",
        }
      );
      const filename =
        headers?.["content-disposition"]?.split("filename=")?.[1];
      downloadBlob(new Blob([data]), filename || "report.xls");
    } catch (err) {
      let message = "";

      try {
        message = JSON.parse(
          await (err as AxiosError<any, any>).response!.data.text()
        ).error;
      } catch {
        message = (err as AxiosError).message;
      } finally {
        toast.error(message);
      }
    } finally {
      setLoading(false);
    }
  };

  return { loading, getFile };
};
