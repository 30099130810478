import { Drawer, Dropdown } from "components";
import { useAuthContext } from "contexts";
import React, { FC } from "react";
import { Arrow2Icon } from "resources/icons";
import styles from "./BreadcrumbsSelector.styles";
import {
  SelectedValueType,
  useBreadcrumbsSelectorInner,
} from "./useBreadcrumbsSelectorInner";

type BreadcrumbsSelectorProps = {
  initialSelectedValue: SelectedValueType;
};

export const BreadcrumbsSelectorInner: FC<BreadcrumbsSelectorProps> = ({
  initialSelectedValue,
}) => {
  const { isManager } = useAuthContext();
  const {
    baseComponent,
    contentComponent,
    isMobile,
    isSelectorOpen,
    setIsSelectorOpen,
    title,
    withDetailsItem,
    withDropdown,
  } = useBreadcrumbsSelectorInner(initialSelectedValue);

  return (
    <div className={styles.container}>
      <p className="text-md hidden font-bold text-grey-1 md:block">{title}</p>
      {isManager() ? (
        <>
          {withDetailsItem && (
            <>
              <span className="hidden rotate-180 md:block">
                <Arrow2Icon />
              </span>
              {!withDropdown ? (
                baseComponent
              ) : isMobile ? (
                <>
                  {baseComponent}
                  <Drawer
                    isOpen={isSelectorOpen}
                    title="Select employee"
                    onClose={() => setIsSelectorOpen(false)}
                  >
                    {contentComponent}
                  </Drawer>
                </>
              ) : (
                <Dropdown
                  rootProps={{
                    open: isSelectorOpen,
                    onOpenChange: setIsSelectorOpen,
                  }}
                  triggerContent={baseComponent}
                  contentContent={
                    <div className={styles.dropdown}>{contentComponent}</div>
                  }
                  contentProps={{
                    className: "z-30",
                  }}
                />
              )}
            </>
          )}
        </>
      ) : null}
    </div>
  );
};
