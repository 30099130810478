import { QueryHookOptions, gql, useQuery } from "@apollo/client";
import { Project } from "types/assignments";

export interface GetProjectAssignmentsVars {
  projectId: string;
  startDate: string;
  endDate: string;
}

export interface GetProjectAssignmentsData {
  project: Pick<Project, "id" | "assignments">;
}

const GET_PROJECT_ASSIGNMENTS = gql`
  query projectAssignments(
    $projectId: uuid!
    $startDate: timestamptz!
    $endDate: timestamptz!
  ) {
    project: projects_by_pk(id: $projectId) {
      id
      assignments: project_employee_assignments(
        order_by: { employee: { last_name: asc } }
        where: {
          _or: [
            { start_date: { _lte: $endDate }, end_date: { _gt: $startDate } }
            {
              _and: [
                {
                  start_date: { _lte: $endDate }
                  end_date: { _gt: $startDate }
                }
                { employee: { status: { _eq: ACTIVE } } }
              ]
            }
            {
              work_logs: {
                start_time: { _gte: $startDate }
                end_time: { _lte: $endDate }
              }
            }
          ]
        }
      ) {
        id
        employee {
          id
          picture
          firstName: first_name
          lastName: last_name
        }
      }
    }
  }
`;

export const useGetProjectAssignments = (
  options?: QueryHookOptions<
    GetProjectAssignmentsData,
    GetProjectAssignmentsVars
  >
) =>
  useQuery<GetProjectAssignmentsData, GetProjectAssignmentsVars>(
    GET_PROJECT_ASSIGNMENTS,
    options
  );
