import { LinkButton } from "components";
import ROUTES from "config/routes";
import React, { FC, PropsWithChildren } from "react";
import { generatePath } from "react-router-dom";

type ProjectButtonProps = {
  projectId: string;
  projectName: string;
};

export const ProjectButton: FC<PropsWithChildren<ProjectButtonProps>> = ({
  projectId,
  projectName,
  children,
}) => {
  return (
    <LinkButton
      to={{
        pathname: generatePath(ROUTES.manager.project, { projectId }),
      }}
      className="flex items-center justify-end gap-2 p-0 text-sm font-normal sm:flex-row-reverse sm:justify-end [&>p]:hover:underline [&>p]:hover:underline-offset-2"
    >
      <p className="max-w-30 overflow-hidden text-ellipsis whitespace-nowrap text-end sm:text-start">
        {projectName}
      </p>
      {children && <div className="min-w-6 w-fit">{children}</div>}
    </LinkButton>
  );
};
