import { AnswersGroupedByQuestionIdType } from "pages/managerCareerPathForm/hooks";
import React, { FC, useMemo } from "react";
import { Subcategory } from "types/career-path";

type InconsistentAnswersProps = {
  subcategories?: Subcategory[];
  answersGroupedByQuestionId: AnswersGroupedByQuestionIdType;
  answersGroupedByQuestionIdState: AnswersGroupedByQuestionIdType;
};

export const InconsistentAnswers: FC<InconsistentAnswersProps> = ({
  answersGroupedByQuestionId,
  answersGroupedByQuestionIdState,
  subcategories,
}) => {
  const totalQuestionsToResolve = useMemo<number>(
    () =>
      subcategories?.reduce<number>((acc, { questions }) => {
        const unResolvedQuestions =
          questions?.filter(({ id }) => {
            const pathAnswer = answersGroupedByQuestionId?.[id];
            return (
              answersGroupedByQuestionIdState?.[id]?.finalAnswer === null ||
              pathAnswer?.finalAnswer === null
            );
          })?.length || 0;
        return acc + unResolvedQuestions;
      }, 0) || 0,
    [answersGroupedByQuestionId, answersGroupedByQuestionIdState, subcategories]
  );
  return (
    <>
      {!!totalQuestionsToResolve && (
        <div className="flex items-center justify-center bg-purple-7 pb-4">
          <div className="mx-6 grow rounded bg-red-3 p-2 text-center">
            {totalQuestionsToResolve} inconsistent answers
          </div>
        </div>
      )}
    </>
  );
};
