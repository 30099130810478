const styles = {
  label: `
    text-purple-1 
    font-sans
    text-sm
    mb-2`,
  control: `
    p-1
    bg-white
    shadow-none
    focus:border-purple-2
    hover:border-purple-2
    border
    border-solid
    border-grey-3`,
  valueContainer: `
    text-sm
    font-medium
    py-0
    px-0.5`,
  indicatorsContainer: `
    [&>div]:p-1.5`,
  inputContainer: `text-xs`,
  menuPortal: `z-100`,
  option: `text-sm`,
  optionSelected: `
    bg-purple-6 
    text-purple-1`,
  optionFocused: `
    bg-purple-7 
    active:bg-purple-5`,
  multiValueRemove: `
    hover:bg-purple-5
    hover:text-purple-4`,
};

export default styles;
