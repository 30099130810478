import { UserAvatarProps } from "components";
import { useIsMobile } from "hooks";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  BreadcrumbsSelectorBaseButton,
  BreadcrumbsSelectorContent,
} from "./components";
import { useGetParams } from "./components/content/components/hooks";
import { SELECTOR_TABS } from "./components/content/useBreadcrumbsSelectorContent";

export type SelectorConfig = {
  title: string;
  link: { path: string; withSearchParam?: boolean };
  withDropdown?: boolean;
};

export type SelectedValueType = {
  id: string;
  value: string;
  type: SELECTOR_TABS;
  iconProps?: UserAvatarProps;
};

export type HandleCloseSelectorType = (
  selectedValue: SelectedValueType
) => void;

export const useBreadcrumbsSelectorInner = (
  initialSelectedValue: SelectedValueType
) => {
  const { isMobile } = useIsMobile();
  const { view } = useGetParams();

  const [isSelectorOpen, setIsSelectorOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState<
    SelectedValueType | undefined
  >(initialSelectedValue);

  useEffect(() => {
    setSelectedValue(initialSelectedValue);
  }, [initialSelectedValue]);

  const openSelector = useCallback(() => setIsSelectorOpen(true), []);
  const handleCloseSelector = useCallback<HandleCloseSelectorType>(
    (selectedValue) => {
      setSelectedValue(selectedValue);
      setIsSelectorOpen(false);
    },
    []
  );

  const { contentComponent, baseComponent } = useMemo(
    () => ({
      contentComponent: view?.breadcrumbs?.detailsItem?.withDropdown ? (
        <BreadcrumbsSelectorContent
          handleCloseSelector={handleCloseSelector}
          selectorConfig={view.breadcrumbs}
        />
      ) : undefined,
      baseComponent: (
        <BreadcrumbsSelectorBaseButton
          handleOpenSelector={openSelector}
          selectedValue={selectedValue}
          isClickable={!!view?.breadcrumbs?.detailsItem?.withDropdown}
        />
      ),
    }),
    [handleCloseSelector, openSelector, selectedValue, view]
  );

  return {
    baseComponent,
    contentComponent,
    isMobile,
    isSelectorOpen,
    setIsSelectorOpen,
    withDetailsItem: view?.breadcrumbs?.detailsItem || false,
    title: view?.name ?? "",
    withDropdown: view?.breadcrumbs?.detailsItem?.withDropdown || false,
  };
};
