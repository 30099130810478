import moment from "moment";
import React, { FC } from "react";
import { Timesheet } from "types/timesheets";
import { useTimeSheetsContext } from "../../../../context";
import styles from "./DayOffInfo.styles";

type Props = {
  dayOff: Timesheet;
};

export const DayOffInfo: FC<Props> = ({ dayOff }) => {
  const { configData } = useTimeSheetsContext();

  return (
    <>
      <p className={styles.label}>TYPE</p>
      <p className={`${styles.value} font-semibold`}>
        {
          configData?.timesheetsLogsTypes.find(
            (t) => t.value === dayOff.logType
          )?.label
        }
      </p>
      <p className={styles.label}>DATE</p>
      <p className={`${styles.value} font-semibold`}>
        {moment(dayOff.startTime).format("dddd, D MMMM YYYY")}
      </p>
      <p className={styles.label}>DESCRIPTION</p>
      <p className={styles.value}>{dayOff.description}</p>
    </>
  );
};
