const styles = {
  listItem: `
    w-full
    justify-between 
    hover:bg-purple-9
    p-2`,
  listItemSelected: `
    xs:rounded
    bg-purple-6 
    text-purple-1`,
  listItemName: `
    ml-2 
    mr-auto
    text-sm 
    text-purple-1 
    overflow-hidden 
    text-ellipsis 
    whitespace-nowrap`,
  avatar: `
    w-6
    h-6
    text-xs`,
  chipWrapper: `
    max-w-min 
    ml-2 
    flex-auto`,
};
export default styles;
