import { Collapse } from "@mui/material";
import React, { FC, useState } from "react";
import { Arrow2Icon } from "resources/icons";
import { getProjectIcon } from "utils/timesheets.utils";
import { ProjectsAccordionProps } from "../../ProjectsAccordion";
import { ProjectButton } from "../projectButton/ProjectButton";

type MobileAccordionProps = ProjectsAccordionProps;

export const MobileAccordion: FC<MobileAccordionProps> = ({ projects }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const Icon = getProjectIcon();
  const [firstProject, ...restProjects] = projects;
  return (
    <div onClick={(e) => e.stopPropagation()}>
      {firstProject ? (
        <ProjectButton
          projectId={firstProject.id}
          projectName={firstProject.name}
        >
          <Icon className="h-6 w-6" />
        </ProjectButton>
      ) : (
        "-"
      )}
      {!!restProjects?.length && (
        <>
          <Collapse in={isExpanded}>
            <div className="mt-2 flex flex-col items-end gap-3">
              {restProjects.map(({ id, name }) => (
                <ProjectButton key={id} projectId={id} projectName={name}>
                  <Icon className="h-6 w-6" />
                </ProjectButton>
              ))}
            </div>
          </Collapse>
          <div
            className={`text-purple-1 mt-2 flex items-center gap-2`}
            onClick={() => setIsExpanded((prevState) => !prevState)}
          >
            <p className="w-full text-end">
              Show {isExpanded ? "less" : "more"}
            </p>
            <div>
              <Arrow2Icon
                className={`h-6 w-6 text-sm transition ${
                  isExpanded ? "rotate-90" : "-rotate-90"
                }`}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
