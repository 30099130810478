import moment, { Moment } from "moment";
import { TimesheetsRequestParams } from "../useTimesheetsTableData/useTimesheetsTableData";

export const DAY_WORK_HOURS = 8;

export const getWorkingsDaysFromPeriod = (start: Moment, end: Moment) => {
  let workDays = 0;
  for (let i = 0; i <= end.diff(start, "days"); i++) {
    const date = moment(start).add(i, "days");
    // 5, 6 weekend days
    const isWeekend = [5, 6].includes(date.weekday());
    if (!isWeekend) {
      workDays += 1;
    }
  }
  return workDays;
};

export const getEstimatedMonthWorkHours = (
  holidayAmount: number,
  dateValue: TimesheetsRequestParams
) => {
  const start = moment().set(dateValue).startOf("month");
  const end = moment().set(dateValue).endOf("month");
  const workDays = getWorkingsDaysFromPeriod(start, end);
  return `Estimated month work time: ${
    (workDays - holidayAmount) * DAY_WORK_HOURS
  }h`;
};
