import { Button } from "components";
import React, { FC } from "react";
import { AcaisoftIcon } from "resources/icons";
import styles from "./FormWelcome.styles";

interface Props {
  startForm: () => void;
}

export const CareerPathFormWelcome: FC<Props> = ({ startForm }) => {
  return (
    <div className="m-auto">
      <div className={styles.background}>
        <div className={styles.container}>
          <div className="flex h-full flex-col items-center text-center">
            <span className="mb-3 [&>svg]:h-6 [&>svg]:w-32">
              <AcaisoftIcon />
            </span>
            <p className="text-neutral-n3 mb-12 text-xs font-bold uppercase">
              Self-Evaluation
            </p>
            <span className="mb-8 text-4xl">👋</span>

            <h1 className="text-purple-2 mb-2 text-3xl font-bold">
              Employee self-evaluation:
            </h1>
            <h2 className="text-purple-2 mb-6 text-base font-bold">
              Your voice matters!
            </h2>
            <p className="text-neutral-n3 mb-8 text-sm">
              By sharing your honest feedback, you&apos;re not only helping us
              understand how we can better support you, but also contributing to
              your own growth and development within the company.
            </p>
            <p className="text-purple-2 mb-12 text-base font-bold">
              Thank you for taking the time to participate.
            </p>

            <Button onClick={() => startForm()} variant="surveyPrimary">
              Let&apos;s start!
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
