import { gql, useQuery } from "@apollo/client";
import { CONTRACTS_TYPE } from "types/employee";

export type GetContractsData = {
  contracts: Array<{
    type: CONTRACTS_TYPE;
  }>;
};

const GET_CONTRACTS = gql`
  query {
    contracts {
      type
    }
  }
`;

export const useGetContracts = () => {
  const { data } = useQuery<GetContractsData>(GET_CONTRACTS);
  return { contracts: data?.contracts };
}

