import React, { FC } from "react"
import getStyles from "./ProgressItem.styles"
import { ConfirmCheckIcon } from "resources/icons";

interface Props {
  name: string
  completed: boolean
  isActive: boolean
}

export const FormProgressItemCategory: FC<Props> = ({ name, completed, isActive }) => {
  const styles = getStyles({ isActive, completed })

  return (
    <span className="flex w-6 relative flex-col items-center overflow-visible">
      <span className={styles.circle}>
        {completed ? <ConfirmCheckIcon className="stroke-white" /> : null}
      </span>
      <p className={styles.categoryName}>{name}</p>
    </span>
  )
}
