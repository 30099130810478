import React, { FC } from "react";
import { TimesheetsWeekState } from "types/timesheets";

interface WeekStatusChipProps {
  status: TimesheetsWeekState;
}

const statusesColorMap = {
  [TimesheetsWeekState.WEEK_NOT_REVIEWED]: "bg-grey-5",
  [TimesheetsWeekState.WEEK_FULLY_REJECTED]: "bg-red-3",
  [TimesheetsWeekState.WEEK_FULLY_APPROVED]: "bg-green-3",
  [TimesheetsWeekState.WEEK_PARTLY_REVIEWED]: "bg-orange-3",
  [TimesheetsWeekState.WEEK_FULLY_REVIEWED]: "bg-purple-5",
};

export const WeekStatusChip: FC<WeekStatusChipProps> = ({ status }) => {
  return (
    <div
      className={`${statusesColorMap[status]} whitespace-nowrap rounded p-1 text-sm font-medium text-purple-1`}
    >
      {status}
    </div>
  );
};
