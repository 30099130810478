import { Button, LinkButton } from "components";
import { USER_ROLES_ENUM } from "config/constants";
import ROUTES from "config/routes";
import { useLastSubmittedAnswers, useSubmitAnswers } from "hooks";
import React, { FC } from "react";
import { Link, generatePath, useParams } from "react-router-dom";
import { ArrowIcon } from "resources/icons";
import { QueryParams } from "types/career-path";
import styles from "./FormEnd.styles";

interface Props {
  handleBack: () => void;
}

export const CareerPathFormEnd: FC<Props> = ({ handleBack }) => {
  const { employeeId } = useParams<QueryParams>();
  const path = generatePath(
    ROUTES[USER_ROLES_ENUM.EMPLOYEE].careerPathDetails,
    { employeeId }
  );
  const { refetch } = useLastSubmittedAnswers({ employeeId });

  const { submitAnswers } = useSubmitAnswers();
  const submitSurvey = async () => {
    await submitAnswers();
    // TODO: Instead of calling refetch manually, we can use refetchQueries
    refetch();
  };

  return (
    <div className={styles.background}>
      <Button
        className={styles.backButton}
        variant="surveySecondary"
        onClick={handleBack}
      >
        <ArrowIcon />
        Back to editing
      </Button>
      <div className={styles.container}>
        <div className="mb-10 rounded border-2 border-acai-dark-purple-4">
          <img
            src="https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExOTJkZjJlNGEyZjMzZjFlN2I3MmUxY2NlZGJjNTlmOTNlNzdiYWIxMyZjdD1n/okLCopqw6ElCDnIhuS/giphy.gif"
            alt=""
            className="max-h-70"
          />
        </div>

        <h2 className="mb-8 max-w-sm text-center text-2xl font-bold text-acai-dark-purple-3">
          Thank you for taking the time to complete the survey.
        </h2>
        <p className="max-w-88 mb-5 text-center text-sm text-neutral-n3">
          Please note that after you submit your responses, you will not be able
          to make any changes.
        </p>
        <p className="mb-10 text-center text-base font-bold text-acai-dark-purple-3">
          Thank you for your participation!
        </p>

        <LinkButton
          to={path}
          variant="surveyPrimary"
          className="mb-2"
          onClick={submitSurvey}
        >
          Submit your answers
        </LinkButton>
        <Link to={path} className={styles.saveLink}>
          Save as draft
        </Link>
      </div>
    </div>
  );
};
