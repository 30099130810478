import { ColumnFilterValue } from "@tanstack/react-table";
import { Search, SearchProps } from "components";
import { UseFilters } from "components/table/components/filters/useFilters";
import { ColumnMultiFilterType } from "components/table/types";
import React, { useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { ColumnMultiFilterItemsList } from "../columnMultiFilterItemsList/ColumnMultiFilterItemsList";

const DEBOUNCE_WAIT_IN_MS = 300;

type ColumnMultiFilterDropdownContentProps = {
  checkedValues: ColumnFilterValue;
} & Pick<ColumnMultiFilterType, "columnId" | "items" | "withSearch"> &
  Pick<UseFilters, "handleCheck">;

export const ColumnMultiFilterDropdownContent = ({
  checkedValues,
  handleCheck,
  items,
  columnId,
  withSearch = false,
}: ColumnMultiFilterDropdownContentProps) => {
  const [filteredItems, setFilteredItems] = useState(items);
  const [searchValue, setSearchValue] = useState("");

  const setFiltersDebounce = useDebouncedCallback<SearchProps["handleChange"]>(
    (value) => {
      setFilteredItems(
        items.filter((item) => {
          const label = typeof item === "string" ? item : item.label;
          return label?.toLowerCase()?.includes(value?.toLowerCase());
        })
      );
    },
    DEBOUNCE_WAIT_IN_MS
  );

  return (
    <>
      {withSearch && (
        <Search
          handleChange={(value) => {
            setSearchValue(value);
            setFiltersDebounce(value);
          }}
          value={searchValue}
          inputProps={{
            className: "border-0 border-b rounded-b-none focus:border-grey-3",
          }}
        />
      )}
      {filteredItems.length ? (
        <ColumnMultiFilterItemsList
          checkedValues={checkedValues}
          handleCheck={(item) => handleCheck(columnId, item)}
          items={filteredItems}
          columnId={columnId}
        />
      ) : (
        <div className="p-4 text-center text-sm text-grey-2">
          No results found
        </div>
      )}
    </>
  );
};
