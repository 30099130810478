const styles = {
  filtersCounter: `
    flex 
    rounded-[50%] 
    justify-center
    min-w-5
    text-white 
    text-sm 
    w-auto
    font-semibold 
    bg-purple-2`,
};

export default styles;
