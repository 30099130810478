import moment from "moment";
import React, { FC } from "react";
import { Timesheet } from "types/timesheets";
import {
  formatTotalMinutes,
  transformLogEndTime,
} from "utils/timesheets.utils";
import styles from "./TimesheetInfo.styles";

type Props = {
  timesheet: Timesheet;
};

export const TimesheetInfo: FC<Props> = ({ timesheet }) => {
  const endTime = transformLogEndTime(moment(timesheet.endTime), "add");

  return (
    <>
      <p className={styles.label}>DATE</p>
      <p className={styles.value}>
        {moment(timesheet.startTime).format("dddd, D MMMM YYYY")}
      </p>
      <p className={styles.label}>WORKING TIME</p>
      <p className={styles.value}>
        {moment(timesheet.startTime).format("H:mm")} - {endTime.format("H:mm")}
        <span className="ml-4 font-bold">
          {formatTotalMinutes(timesheet.minutes)}
        </span>
      </p>
      <p className={styles.label}>TASK ID</p>
      <p className={styles.value}>{timesheet.taskId}</p>
      <p className={styles.label}>DETAILS</p>
      <p className={styles.value}>{timesheet.description}</p>
    </>
  );
};
