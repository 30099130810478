import { InputBase, InputLabel } from "@mui/material";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { FieldError } from "components";
import moment from "moment";
import React, { FC, useState } from "react";
import { FieldRenderProps } from "react-final-form";
import styles from "./DateTimePickerControl.styles";

type Variant = "date" | "dateTime" | "time" | "month";

moment.updateLocale("en", {
  week: {
    dow: 1, // monday as first day of the week
    doy: 1,
  },
});

const getVariant = (variant: Variant) => {
  const types = {
    date: {
      Component: DatePicker,
      dateFormat: "YYYY-MM-DD",
      views: ["year", "day"],
      openTo: "day",
    },
    dateTime: {
      Component: DateTimePicker,
      dateFormat: "YYYY-MM-DD HH:mm",
      views: ["year", "day"],
      openTo: "day",
    },
    time: {
      Component: TimePicker,
      dateFormat: "HH:mm",
      views: ["hours", "minutes"],
      openTo: "hours",
    },
    month: {
      Component: DatePicker,
      dateFormat: "YYYY-MM",
      views: ["year", "month"],
      openTo: "month",
    },
  };
  return types[variant];
};

export type DateTimePickerControlProps = FieldRenderProps<
  string,
  HTMLElement
> & {
  label: string;
  dateFormat?: string;
  forcedError?: string;
  inputOnChange?: (value: string | null) => void;
  variant?: Variant;
  valueInitial?: Date | string;
  dataCy?: string;
};

export const DateTimePickerControl: FC<DateTimePickerControlProps> = ({
  input: { name, value, onChange, ...restInput },
  meta: { submitError, dirtySinceLastSubmit, error: metaError, touched },
  label,
  dateFormat,
  forcedError,
  inputOnChange,
  variant = "date",
  valueInitial,
  dataCy,
  ...props
}) => {
  const [isDialogClosed, setIsDialogClosed] = useState(false);

  const error = forcedError || metaError;
  const isDirty = Boolean(touched);
  const hasErrors = Boolean((submitError && !dirtySinceLastSubmit) || error);
  const showError = (isDialogClosed || isDirty) && hasErrors;

  const Variant = getVariant(variant);

  return (
    <div className={styles.inputContainer} data-cy={dataCy}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Variant.Component
          views={Variant.views as any}
          openTo={Variant.openTo as any} // 'views' and 'openTo' aren't fully interchangeable between DatePickerComponent and TimePickerComponent, so we cast them to 'any'
          label={label}
          renderInput={({
            disabled,
            inputProps,
            InputProps,
            inputRef,
            onChange,
            ref,
          }) => {
            return (
              <>
                <InputLabel htmlFor={name} className={styles.label}>
                  {label}
                </InputLabel>
                <InputBase
                  id={name}
                  endAdornment={InputProps?.endAdornment}
                  {...{ disabled, inputProps, inputRef, name, onChange, ref }}
                  classes={{
                    root: styles.root,
                    focused: styles.focused,
                  }}
                  {...restInput}
                />
                {showError && <FieldError>{error || submitError}</FieldError>}
              </>
            );
          }}
          value={value === "" ? null : value} // null is needed for 'clear' button to work
          onChange={(e) => {
            if (e && variant === "time" && valueInitial) {
              // bug: TimePicker changes date to today
              onChange(
                moment(e).set({
                  year: moment(valueInitial).year(),
                  month: moment(valueInitial).month(),
                  date: moment(valueInitial).date(),
                  hours: moment(e).hours(),
                  minutes: moment(e).minutes(),
                  seconds: 0,
                  milliseconds: 0,
                })
              );
            } else {
              onChange(e);
            }
            inputOnChange?.(e);
          }}
          inputFormat={dateFormat ? dateFormat : Variant.dateFormat}
          onClose={() => setIsDialogClosed(true)}
          ampm={false}
          {...restInput}
          {...props}
        />
      </LocalizationProvider>
    </div>
  );
};
