import { QueryHookOptions, gql, useQuery } from "@apollo/client";
import { useAuthContext } from "contexts";
import { EmployeeCareerPath } from "types/career-path";

export interface GetSubcategoryAnswersData {
  subcategoryAnswers: EmployeeCareerPath[];
}

export interface GetSubcategoryAnswersVars {
  employeeId: string;
  isManager?: boolean;
  subcategoryId: string;
}

export const GET_SUBCATEGORY_ANSWERS = gql`
  query getSubcategoryAnswers(
    $employeeId: uuid
    $isManager: Boolean!
    $subcategoryId: uuid!
  ) {
    subcategoryAnswers: employee_careers(
      where: {
        career_path_question: {
          career_path_subcategory: { id: { _eq: $subcategoryId } }
          career_path_version: { current: { _eq: true } }
        }
        employee_id: { _eq: $employeeId }
        submitted_on: { _is_null: true }
      }
      order_by: { career_path_question: { career_path_level: { value: asc } } }
    ) {
      id
      questionId: question_id
      employeeId: employee_id
      answer
      finalAnswer: final_answer
      managerAnswer: manager_answer @include(if: $isManager)
      question: career_path_question {
        id
        text
        subcategoryId: subcategory_id
        career_path_subcategory {
          name
        }
        level: career_path_level {
          id
          value
        }
      }
    }
  }
`;

interface Props {
  employeeId: string;
  subcategoryId: string;
  options?: QueryHookOptions<
    GetSubcategoryAnswersData,
    GetSubcategoryAnswersVars
  >;
}

export const useGetSubcategoryAnswers = ({
  employeeId,
  subcategoryId,
  options,
}: Props) => {
  const { isManager } = useAuthContext();
  return useQuery<GetSubcategoryAnswersData, GetSubcategoryAnswersVars>(
    GET_SUBCATEGORY_ANSWERS,
    {
      variables: { employeeId, isManager: isManager(), subcategoryId },
      ...options,
    }
  );
};
