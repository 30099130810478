import moment from "moment";
import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  GET_EMPLOYEE_CALENDAR,
  GET_EMPLOYEE_TIMESHEETS_AND_ASSIGNMENTS_WEEKLY,
  GET_EMPLOYEE_TIMESHEETS_AND_ASSIGNMENTS_MONTHLY,
  useIsMobile,
} from "hooks";
import { useCallback } from "react";
import { toast } from "react-toastify";
import camelCase from "lodash.camelcase";
import mapKeys from "lodash.mapkeys";
import { TimeRange, Timesheet } from "types/timesheets";

type UpsertWorkLogsInput = {
  id?: string;
  index: number;
  start_time: Timesheet["startTime"];
  end_time: Timesheet["endTime"];
  description: Timesheet["description"];
  task_id: Timesheet["taskId"];
};

type UpsertTimesheetVars = {
  assignmentId: Timesheet["assignmentId"];
  logType: Timesheet["logType"];
  workLogs: UpsertWorkLogsInput[];
};

type UpsertTimesheetData = {
  upsertedTimesheets: { result: Timesheet[] };
};

const UPSERT_TIMESHEET = gql`
  mutation upsertTimesheet(
    $assignmentId: UUID
    $logType: String!
    $workLogs: [TimeLogInputType]!
  ) {
    upsertedTimesheets: submit_time_log(
      assignment_id: $assignmentId
      log_type: $logType
      work_logs: $workLogs
    ) {
      result {
        id
        startTime: start_time
        endTime: end_time
        description
        minutes
        assignmentId: assignment_id
        taskId: task_id
      }
    }
  }
`;

interface Props {
  employeeId: string;
  activeWeek: TimeRange;
  activeMonth: TimeRange;
  activeYear: number;
}

export const useUpsertTimesheet = ({
  employeeId,
  activeWeek,
  activeMonth,
  activeYear,
}: Props) => {
  const { start: weekStart, end: weekEnd } = activeWeek;
  const { start: monthStart, end: monthEnd } = activeMonth;

  const { isMobile } = useIsMobile();
  const startDate = moment([activeYear]).startOf("year").format("YYYY-MM-DD");
  const endDate = moment([activeYear]).endOf("year").format("YYYY-MM-DD");

  const [processUpsertTimesheet, data] = useMutation<
    UpsertTimesheetData,
    UpsertTimesheetVars
  >(UPSERT_TIMESHEET, {
    // refetch needed instead of writing to cache (possibly due to returned type)
    refetchQueries: [
      {
        query: GET_EMPLOYEE_TIMESHEETS_AND_ASSIGNMENTS_WEEKLY,
        variables: {
          startDate: weekStart,
          endDate: weekEnd,
          contractStartDate: weekStart,
          contractEndDate: weekStart,
          exceptionsStartDate: weekStart,
          exceptionsEndDate: weekEnd,
          employeeId,
          isManager: false,
        },
      },
      {
        query: GET_EMPLOYEE_CALENDAR,
        variables: { employeeId, startDate, endDate },
      },
      ...(isMobile
        ? []
        : [
            {
              query: GET_EMPLOYEE_TIMESHEETS_AND_ASSIGNMENTS_MONTHLY,
              variables: {
                startDate: monthStart,
                endDate: monthEnd,
                contractStartDate: monthStart,
                contractEndDate: monthStart,
                exceptionsStartDate: monthStart,
                exceptionsEndDate: monthEnd,
                employeeId,
                isManager: false,
              },
            },
          ]),
    ],
  });

  const upsertTimesheet = useCallback(
    async (vars: UpsertTimesheetVars) => {
      try {
        await processUpsertTimesheet({
          variables: vars,
        });
      } catch (ex) {
        let errors = undefined;
        const message = (ex as ApolloError).message.replaceAll(`'`, `"`);

        try {
          errors = JSON.parse(message);
          if (Array.isArray(errors)) {
            errors = errors.map((e) => mapKeys(e, (_, key) => camelCase(key)));
          } else {
            toast.error(message);
          }
        } catch (ex) {
          toast.error(message);
          errors = message;
        }
        return { errors };
      }
    },
    [processUpsertTimesheet]
  );

  return { upsertTimesheet, ...data };
};
