const styles = {
  dateContainer: `
    w-full
    relative
    flex
    items-center`,
  dateInput: `
    cursor-pointer
    w-full
    rounded
    border 
    border-grey-3
    focus:border-purple-2
    focus:outline-none
    py-2.5
    px-4
    placeholder:text-grey-1
    placeholder:font-medium
    placeholder:text-sm
    text-grey-1
    font-medium 
    text-sm`,
  icon: `
    text-grey-2 
    absolute
    right-4
    w-4
    h-4`,
};

export default styles;
