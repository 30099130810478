import { Button } from "components";
import { getQuestionDetails } from "pages/managerCareerPathForm/components/utils";
import React, { FC, Fragment } from "react";
import { TableHead } from "../../../tableHead/TableHead";
import { FilteredQuestionsTableProps } from "../../FilteredQuestionsTable";
import { styles } from "../../filteredQuestionsTable.styles";

type DesktopFilteredQuestionsTableProps = {
  subcategoriesToResolve?: Record<string, string>;
} & FilteredQuestionsTableProps;

export const DesktopFilteredQuestionsTable: FC<
  DesktopFilteredQuestionsTableProps
> = ({
  subcategories,
  answersGroupedByQuestionId,
  answersGroupedByQuestionIdState,
  handleManagerAnswer,
  subcategoriesToResolve,
}) => {
  return (
    <>
      {Object.keys(subcategoriesToResolve || {})?.length ? (
        <>
          {subcategories?.map(
            ({
              id,
              questions,
              name: subCategoryName,
              category: { name: categoryName },
            }) => {
              const showQuestions = !!subcategoriesToResolve?.[subCategoryName];
              return (
                <Fragment key={id}>
                  {showQuestions && (
                    <table className={styles.table}>
                      <TableHead
                        categoryName={categoryName}
                        subCategoryName={subCategoryName}
                      />
                      <tbody>
                        {questions?.map(({ id, text }) => {
                          const {
                            isEmployeeYesAnswer,
                            isManagerYesAnswer,
                            giveAnswerArgs,
                            isFinalAnswer,
                            managerAnswer,
                          } = getQuestionDetails({
                            answersGroupedByQuestionId,
                            id,
                            answersGroupedByQuestionIdState,
                          });
                          return (
                            <Fragment key={id}>
                              {!isFinalAnswer && (
                                <tr className={styles.tableRow}>
                                  <td className="w-full py-4 pl-12 pr-3 text-sm font-medium">
                                    {text}
                                  </td>
                                  <td className="px-3 py-4 text-sm font-medium">
                                    {isEmployeeYesAnswer ? "Yes" : "No"}
                                  </td>
                                  <td className="px-3 py-4 text-sm font-medium">
                                    {isManagerYesAnswer ? "Yes" : "No"}
                                  </td>
                                  <td className="py-4 pl-3 pr-12">
                                    <div className="flex gap-6">
                                      <Button
                                        variant="tertiary"
                                        className={styles.actionButton}
                                        onClick={() => {
                                          handleManagerAnswer({
                                            id,
                                            managerAnswer,
                                            shouldNotGiveAnswer: false,
                                            giveAnswerArgs: {
                                              ...giveAnswerArgs,
                                              finalAnswer: true,
                                            },
                                          });
                                        }}
                                      >
                                        Yes
                                      </Button>
                                      <Button
                                        variant="tertiary"
                                        className={styles.actionButton}
                                        onClick={() => {
                                          handleManagerAnswer({
                                            id,
                                            managerAnswer,
                                            shouldNotGiveAnswer: false,
                                            giveAnswerArgs: {
                                              ...giveAnswerArgs,
                                              finalAnswer: false,
                                            },
                                          });
                                        }}
                                      >
                                        No
                                      </Button>
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </Fragment>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                </Fragment>
              );
            }
          )}
        </>
      ) : (
        <div className="flex items-center justify-center p-8">
          All questions has been successfully resolved
        </div>
      )}
    </>
  );
};
