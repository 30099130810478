import { ModalRefType } from "components";
import { useAuthContext } from "contexts";
import { FORM_ERROR } from "final-form";
import {
  useDeleteTimesheet,
  useGetAbsencesInfo,
  useGetTimesheetsExceptionDays,
  useUpsertDayOff,
} from "hooks";
import moment, { Moment } from "moment";
import { useCallback, useRef, useState } from "react";
import { DayOffFormType } from "types/timesheets";
import { canAddDayOff, transformLogEndTime } from "utils/timesheets.utils";
import {
  FormCommonPropsType,
  useTimeSheetsContext,
  useTimeSheetsWeekContext,
} from "../../../context";

type UseDayOffFormProps = FormCommonPropsType;

export enum SUBMIT_FUNC_TYPE {
  SAVE = "SAVE",
  DELETE = "DELETE",
}

export const useDayOffForm = ({
  formData,
  handleModalClose,
}: UseDayOffFormProps) => {
  const [submitFuncType, setSubmitFuncType] = useState<SUBMIT_FUNC_TYPE>(
    SUBMIT_FUNC_TYPE.SAVE
  );

  const { user, isManager } = useAuthContext();
  const { activeWeek, employeeId, activeMonth, activeYear } =
    useTimeSheetsWeekContext();

  const { data: exceptionDaysData } = useGetTimesheetsExceptionDays({
    employeeId: isManager() ? employeeId : user?.userId,
  });

  const modalRef = useRef<ModalRefType>(null);
  const timesheetDayOff = formData?.timesheets?.[0];
  const isEditForm = !!timesheetDayOff;
  const { configData, timesheetsConfig } = useTimeSheetsContext();

  const borderDate = timesheetsConfig.borderDate;
  const MAX_MINUTES = timesheetsConfig.maxMinutes;
  const timesheetsLogsTypes =
    configData?.timesheetsLogsTypes.filter(
      (t) => t.value !== "standard_hours"
    ) ?? [];

  const initialValues = {
    logType: timesheetDayOff?.logType,
    startTime: timesheetDayOff?.startTime || undefined,
    endTime: timesheetDayOff?.endTime
      ? transformLogEndTime(moment(timesheetDayOff.endTime), "add")
      : undefined,
    description: timesheetDayOff?.description || "",
  };

  const { deleteTimesheet, loading: isDeleteTimeSheetLoading } =
    useDeleteTimesheet({ employeeId });
  const { upsertDayOff, loading: isUpsertDayOffLoading } = useUpsertDayOff({
    employeeId,
    activeWeek,
    activeMonth,
    activeYear,
  });

  const handleSubmit = useCallback(
    async ({ startTime, endTime, logType, description }: DayOffFormType) => {
      const minutes = +MAX_MINUTES;
      let workLogs = [];

      if (isEditForm) {
        workLogs = [
          {
            id: timesheetDayOff.id,
            index: 0,
            start_time: timesheetDayOff.startTime,
            end_time: transformLogEndTime(
              moment(timesheetDayOff.startTime).add({ minutes }),
              "subtract"
            ).toISOString(),
            description: description ?? "",
            minutes,
          },
        ];
      } else {
        const dayOffDates: Moment[] = []; // list of days: start, end and days between them
        for (
          let i = 0;
          i <= moment(endTime).diff(moment(startTime), "days");
          i++
        ) {
          dayOffDates.push(moment(startTime).add(i, "days"));
        }

        workLogs = dayOffDates.map((date, index) => {
          const dayStart = moment(date).set({ hour: 8, minute: 0 });
          const dayEnd = transformLogEndTime(
            moment(dayStart).add({ minutes }),
            "subtract"
          );

          return {
            index,
            start_time: dayStart.toISOString(),
            end_time: dayEnd.toISOString(),
            description: description ?? "",
            minutes,
          };
        });
      }

      const data = await upsertDayOff({ logType, workLogs, employeeId });
      if (data?.errors.length > 0) {
        const formError = data?.errors
          .map(({ index: _, ...rest }: { index: number }) =>
            Object.values(rest).join(", ")
          )
          .join(", ");
        return { [FORM_ERROR]: formError, ...data?.errors };
      } else {
        handleModalClose();
      }
    },
    [
      MAX_MINUTES,
      handleModalClose,
      isEditForm,
      timesheetDayOff,
      upsertDayOff,
      employeeId,
    ]
  );

  const handleDeleteTimesheet = useCallback(async () => {
    if (timesheetDayOff) {
      await deleteTimesheet(timesheetDayOff.id);
      handleModalClose();
    }
  }, [deleteTimesheet, handleModalClose, timesheetDayOff]);

  const shouldDisableDate = useCallback(
    (day: string) =>
      !canAddDayOff(day, exceptionDaysData?.exceptionDays ?? [], borderDate),
    [borderDate, exceptionDaysData]
  );

  const { data } = useGetAbsencesInfo();

  const remainingDays = data?.employee_absence_limits;

  return {
    borderDate,
    handleDeleteTimesheet,
    shouldDisableDate,
    handleSubmit,
    submitFuncType,
    setSubmitFuncType,
    modalRef,
    initialValues,
    isDeleteTimeSheetLoading,
    isUpsertDayOffLoading,
    isEditForm,
    timesheetsLogsTypes,
    exceptionDaysData: exceptionDaysData?.exceptionDays ?? [],
    remainingDays,
  };
};
