import { Table } from "@tanstack/react-table";
import React from "react";
import { PageChange, PageRowsChange } from "./components";

export type PaginationProps<D extends object> = {
  table: Table<D>;
};

export function Pagination<D extends object>(props: PaginationProps<D>) {
  return (
    <div className="flex items-center justify-center p-5 md:justify-between md:p-0 md:pt-4">
      <PageRowsChange<D> {...props} />
      <PageChange<D> {...props} />
    </div>
  );
}
