import { StyledEngineProvider } from "@mui/material";
import { Toast } from "components";
import { AuthContextProvider, ConfigContextProvider } from "contexts";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import "react-toastify/dist/ReactToastify.min.css";
import { ApolloProvider } from "services/graphql";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <Router>
        <AuthContextProvider>
          <ApolloProvider>
            <ConfigContextProvider>
              <App />
              <Toast />
            </ConfigContextProvider>
          </ApolloProvider>
        </AuthContextProvider>
      </Router>
    </StyledEngineProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
