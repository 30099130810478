import { ButtonProps } from "./Button";

const styles: Record<"default" | Required<ButtonProps>["variant"], string> = {
  default: `
    flex
    items-center
    justify-center
    gap-2
    text-sm
    font-medium    
    rounded
    outline-none 
    focus:outline-none 
    p-3`,
  primary: `
    bg-purple-2
    text-white
    disabled:bg-grey-6
    disabled:text-grey-2
    hover:bg-purple-3
    active:bg-purple-3`,
  secondary: `
    bg-grey-6
    text-purple-1
    hover:bg-grey-4
    disabled:text-grey-2
    disabled:bg-grey-6`,
  tertiary: `
    bg-white
    text-purple-1
    border-grey-2
    border
    hover:bg-purple-2
    hover:border-purple-2
    hover:text-white
    disabled:bg-white
    disabled:text-grey-2
    disabled:border-grey-2`,
  duration: `
    border
    rounded-full
    p-2`,
  surveyPrimary: `
    hover:text-white 
    hover:bg-acai-dark-purple-3 
    disabled:bg-neutral-n5
    disabled:border-neutral-n5
    bg-acai-dark-purple-4   
    text-base  
    text-white  
    font-bold 
    py-2.5 
    px-5
  `,
  surveySecondary: `
    text-acai-dark-purple-4
    disabled:text-neutral-n5
    disabled:bg-transparent
    disabled:border-neutral-n5
    border
    border-acai-dark-purple-4
    hover:bg-acai-light-purple
    font-bold
    text-base
    xs:text-sm
    px-4 
    py-2 
    xs:py-1
  `,
  danger: `
    bg-red-600
    text-white
    focus:outline-black
  `,
};
export default styles;
