import { Badge, IconButton } from "@mui/material";
import React, { FC, useState } from "react";
import { NotificationIcon } from "resources/icons";
import { NotificationsSidebar } from "../notificationsSidebar/NotificationsSidebar";
import { useNotificationsInfo } from "./useNotificationsInfo";

export const NotificationsInfo: FC = () => {
  const [isSidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const {
    counterUnread,
    changePage,
    paginationAll,
    paginationUnread,
    notificationsAll,
    notificationsUnread,
    loading,
    error,
  } = useNotificationsInfo();

  return (
    <>
      <IconButton
        aria-label="show notifications"
        className="text-purple-1"
        onClick={() => setSidebarOpen(true)}
      >
        <Badge
          badgeContent={counterUnread}
          classes={{ badge: "bg-purple-3 text-white" }}
          overlap="circular"
        >
          <NotificationIcon />
        </Badge>
      </IconButton>

      {isSidebarOpen && (
        <NotificationsSidebar
          handleClose={() => setSidebarOpen(false)}
          notificationsAll={notificationsAll}
          notificationsUnread={notificationsUnread}
          changePage={changePage}
          paginationAll={paginationAll}
          paginationUnread={paginationUnread}
          loading={loading}
          error={error}
        />
      )}
    </>
  );
};
