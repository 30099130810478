import { Dropdown } from "components";
import React, { useState } from "react";
import { Arrow2Icon } from "resources/icons";
import { PaginationProps } from "../../Pagination";
import { ROWS_PER_PAGE_OPTIONS } from "../../constants";
import { styles } from "./PageRowsChange.styles";

type PageRowsChangeProps<D extends object> = Pick<PaginationProps<D>, "table">;

export const PageRowsChange = <D extends object>({
  table,
}: PageRowsChangeProps<D>) => {
  const [isSelectorOpen, setSelectorOpen] = useState(false);
  const total = table.getFilteredRowModel().rows.length;
  const {
    pagination: { pageSize: rowsPerPage, pageIndex: currentPage },
  } = table.getState();
  const changeRowsPerPage = (rows: number) => {
    setSelectorOpen(false);
    table.setPageSize(rows);
  };
  const itemsStart = rowsPerPage * currentPage + 1;
  const itemsEnd =
    rowsPerPage * (currentPage + 1) > total
      ? total
      : rowsPerPage * (currentPage + 1);

  return (
    <div className="hidden items-center md:flex">
      <label id="pageSizeLabel" className="mr-3 text-sm text-purple-1">
        Rows per page
      </label>
      <Dropdown
        rootProps={{
          open: isSelectorOpen,
          onOpenChange: setSelectorOpen,
        }}
        //disable scroll to input when rows by page changes
        contentProps={{ onCloseAutoFocus: (e) => e.preventDefault() }}
        triggerContent={
          <div
            role="button"
            aria-labelledby="pageSizeLabel"
            aria-expanded={isSelectorOpen}
            className={`${styles.selectButton} ${
              isSelectorOpen ? "border-grey-1" : "border-grey-4"
            }`}
            onClick={() => setSelectorOpen((prev) => !prev)}
          >
            {rowsPerPage}
            <span className="h-3.5 -rotate-90">
              <Arrow2Icon />
            </span>
          </div>
        }
        contentContent={
          <ul role="listbox" className={styles.selectDropdown}>
            {ROWS_PER_PAGE_OPTIONS.map((rows) => (
              <li
                key={rows}
                role="option"
                aria-selected={rowsPerPage === rows}
                className={`${styles.selectDropdownOption} ${
                  rowsPerPage === rows ? "bg-purple-9 font-medium" : ""
                }`}
                onClick={() => changeRowsPerPage(rows)}
              >
                {rows}
              </li>
            ))}
          </ul>
        }
      />
      <span className="ml-8 text-sm text-purple-1">
        Showing {itemsStart}-{itemsEnd} of {total}
      </span>
    </div>
  );
};
