import { SelectedValueType } from "components/breadcrumbsSelector/useBreadcrumbsSelectorInner";
import { UserAvatar } from "components";
import React, { forwardRef } from "react";
import { Arrow2Icon } from "resources/icons";
import styles from "./BreadcrumbsSelectorBaseButton.styles";

interface BreadcrumbsSelectorBaseButtonProps {
  handleOpenSelector: () => void;
  selectedValue?: SelectedValueType;
  isClickable: boolean;
}

export const BreadcrumbsSelectorBaseButton = forwardRef<
  HTMLDivElement,
  BreadcrumbsSelectorBaseButtonProps
>(({ handleOpenSelector, selectedValue, isClickable, ...props }, ref) => {
  return (
    <div
      {...props}
      ref={ref}
      role={isClickable ? "button" : undefined}
      className={styles.dropdownButton}
      onClick={isClickable ? handleOpenSelector : undefined}
    >
      <span className={styles.dropdownButtonLabel}>{selectedValue?.type}</span>
      {/* TODO icon (getProjectIcon) for project */}
      {selectedValue?.iconProps && (
        <UserAvatar
          styles={styles.dropdownAvatar}
          {...selectedValue?.iconProps}
        />
      )}
      <span className={styles.dropdownButtonItem}>{selectedValue?.value}</span>
      {isClickable && (
        <span className={styles.dropdownArrow}>
          <Arrow2Icon />
        </span>
      )}
    </div>
  );
});

BreadcrumbsSelectorBaseButton.displayName = "BreadcrumbsSelectorBaseButton";
