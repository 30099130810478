import React, { Dispatch, FC, SetStateAction } from "react";
import { UserIcon } from "resources/icons";
import { SELECTOR_TABS } from "../../useBreadcrumbsSelectorContent";
import styles from "./BreadcrumbsSelectorTabs.styles";

export type Tab = {
  index: number;
  label: SELECTOR_TABS;
};

interface BreadcrumbsSelectorTabsProps {
  activeTab: SELECTOR_TABS;
  setActiveTab: Dispatch<SetStateAction<SELECTOR_TABS>>;
}

export const employeeSelectorTabs: Tab[] = [
  { index: 0, label: SELECTOR_TABS.EMPLOYEE },
  { index: 1, label: SELECTOR_TABS.MY_EMPLOYEE },
];

export const BreadcrumbsSelectorTabs: FC<BreadcrumbsSelectorTabsProps> = ({
  activeTab,
  setActiveTab,
}) => {
  return (
    <div className={styles.tabs} role="tablist">
      {employeeSelectorTabs.map(({ index, label }) => {
        const isActive = activeTab === label;
        return (
          <button
            key={index}
            role="tab"
            type="button"
            aria-controls={`tabpanel-${label}`}
            aria-selected={isActive}
            id={`tab-${label}`}
            tabIndex={index}
            className={`${styles.tab} ${
              isActive ? "border-b-purple-1" : "border-b-grey-6"
            }`}
            onClick={() => setActiveTab(label)}
          >
            <UserIcon />
            <p
              className={`${styles.tabLabel} ${
                isActive ? "font-semibold" : ""
              }`}
            >
              {label}
            </p>
          </button>
        );
      })}
    </div>
  );
};
