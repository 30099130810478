import { VIEWS } from "config/views";
import { useAuthContext } from "contexts";
import { useIsMobile } from "hooks";
import React, { PropsWithChildren, useMemo, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { Navbar } from "./navbar/Navbar";
import { Sidebar } from "./sidebar/Sidebar";

export const Layout = ({ children }: PropsWithChildren) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [mobileSidebarOpen, setMobileSidebarOpen] = useState(false);
  const { isMobile } = useIsMobile();
  const { isManager } = useAuthContext();
  const { pathname } = useLocation();

  const displayWithNavigation = useMemo(() => {
    const paths = isManager() ? VIEWS.manager : VIEWS.employee;
    return !Object.values(paths)?.find(({ path }) =>
      matchPath(pathname, {
        path: path,
        exact: true,
        strict: true,
      })
    )?.hideNavigation;
  }, [isManager, pathname]);

  return (
    <div className={isMobile && mobileSidebarOpen ? "backdrop-blur" : ""}>
      {displayWithNavigation ? (
        <>
          <Navbar
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
            mobileSidebarOpen={mobileSidebarOpen}
            setMobileSidebarOpen={setMobileSidebarOpen}
          />
          <Sidebar
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
            mobileSidebarOpen={mobileSidebarOpen}
            setMobileSidebarOpen={setMobileSidebarOpen}
          />
          <main
            className={`flex min-h-screen flex-col pb-4 pt-24 xs:pb-0 md:px-10 ${
              sidebarOpen && !isMobile && displayWithNavigation
                ? "md:ml-57.5"
                : ""
            } transition`}
          >
            {children}
          </main>
        </>
      ) : (
        <>{children}</>
      )}
    </div>
  );
};
