import { twMerge } from "tailwind-merge";
import React, { FC } from "react";

type HeaderProps = {
  label: string;
  styles?: string;
};

export const Header: FC<HeaderProps> = ({ label, styles }) => {
  return (
    <h1
      className={twMerge(
        "mb-7 px-5 text-lg font-semibold md:mb-10 md:px-0 md:text-2xl",
        styles
      )}
    >
      {label}
    </h1>
  );
};
