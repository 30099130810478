import { Button } from "components";
import React, { FC } from "react";
import { getQuestionDetails } from "../../../../../utils";
import { QuestionsTableProps } from "../../QuestionsTable";
import { getAnswerButtonStyle } from "../../questionsTable.styles";

type MobileQuestionsTableProps = QuestionsTableProps;

export const MobileQuestionsTable: FC<MobileQuestionsTableProps> = ({
  answersGroupedByQuestionId,
  currentSubCategory,
  answersGroupedByQuestionIdState,
  questionsVisibility,
  handleManagerAnswer,
}) => {
  return (
    <table className="w-full">
      <tbody>
        {currentSubCategory?.questions?.map(({ id, text }) => {
          const {
            isEmployeeNoAnswer,
            isEmployeeYesAnswer,
            isManagerNoAnswer,
            isManagerYesAnswer,
            giveAnswerArgs,
          } = getQuestionDetails({
            answersGroupedByQuestionId,
            id,
            answersGroupedByQuestionIdState,
          });
          const isAnswerVisible = questionsVisibility?.[id];
          return (
            <tr
              className="odd:bg-purple-7 flex flex-col gap-3 px-5 py-8 even:bg-white"
              key={id}
            >
              <td className="flex flex-col text-sm font-medium">
                {text}
                <div className="mt-6 flex gap-4">
                  <Button
                    variant={isManagerYesAnswer ? "primary" : "tertiary"}
                    className={getAnswerButtonStyle(
                      isAnswerVisible,
                      isEmployeeYesAnswer,
                      isManagerYesAnswer
                    )}
                    onClick={() =>
                      handleManagerAnswer({
                        id,
                        managerAnswer: true,
                        giveAnswerArgs: {
                          ...giveAnswerArgs,
                          finalAnswer: isEmployeeYesAnswer
                            ? true
                            : isEmployeeNoAnswer
                            ? null
                            : giveAnswerArgs?.finalAnswer,
                        },
                        shouldNotGiveAnswer: isManagerYesAnswer,
                      })
                    }
                  >
                    Yes
                  </Button>
                  <Button
                    variant={isManagerNoAnswer ? "primary" : "tertiary"}
                    className={getAnswerButtonStyle(
                      isAnswerVisible,
                      isEmployeeNoAnswer,
                      isManagerNoAnswer
                    )}
                    onClick={() =>
                      handleManagerAnswer({
                        id,
                        managerAnswer: false,
                        giveAnswerArgs: {
                          ...giveAnswerArgs,
                          finalAnswer: isEmployeeNoAnswer
                            ? false
                            : isEmployeeYesAnswer
                            ? null
                            : giveAnswerArgs?.finalAnswer,
                        },
                        shouldNotGiveAnswer: isManagerNoAnswer,
                      })
                    }
                  >
                    No
                  </Button>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
