import { useIsMobile } from "hooks";
import {
  AnswersGroupedByQuestionIdType,
  HandleManagerAnswerType,
} from "pages/managerCareerPathForm/hooks";
import React, { FC, useMemo } from "react";
import { Subcategory } from "types/career-path";
import {
  DesktopFilteredQuestionsTable,
  MobileFilteredQuestionsTable,
} from "./components";

export type FilteredQuestionsTableProps = {
  subcategories?: Subcategory[];
  answersGroupedByQuestionId: AnswersGroupedByQuestionIdType;
  answersGroupedByQuestionIdState: AnswersGroupedByQuestionIdType;
  handleManagerAnswer: HandleManagerAnswerType;
};

export const FilteredQuestionsTable: FC<FilteredQuestionsTableProps> = ({
  answersGroupedByQuestionId,
  answersGroupedByQuestionIdState,
  subcategories,
  ...rest
}) => {
  const subcategoriesToResolve = useMemo<Record<string, string>>(
    () =>
      subcategories?.reduce<Record<string, string>>(
        (acc, { questions, name }) => ({
          ...acc,
          ...(questions?.some(({ id }) => {
            const pathAnswer = answersGroupedByQuestionId?.[id];
            return (
              answersGroupedByQuestionIdState?.[id]?.finalAnswer === null ||
              pathAnswer?.finalAnswer === null
            );
          }) && { [name]: name }),
        }),
        {}
      ) || {},
    [answersGroupedByQuestionId, answersGroupedByQuestionIdState, subcategories]
  );
  const { isMobile } = useIsMobile();
  return (
    <>
      {isMobile ? (
        <MobileFilteredQuestionsTable
          answersGroupedByQuestionId={answersGroupedByQuestionId}
          answersGroupedByQuestionIdState={answersGroupedByQuestionIdState}
          subcategories={subcategories}
          subcategoriesToResolve={subcategoriesToResolve}
          {...rest}
        />
      ) : (
        <DesktopFilteredQuestionsTable
          answersGroupedByQuestionId={answersGroupedByQuestionId}
          answersGroupedByQuestionIdState={answersGroupedByQuestionIdState}
          subcategories={subcategories}
          subcategoriesToResolve={subcategoriesToResolve}
          {...rest}
        />
      )}
    </>
  );
};
