import { FormControlLabel, FormControlLabelProps, Switch } from "@mui/material";
import React, { FC } from "react";

type ToggleSurveyViewProps = Pick<FormControlLabelProps, "onChange" | "value">;

export const ToggleSurveyView: FC<ToggleSurveyViewProps> = (props) => {
  return (
    <FormControlLabel
      {...props}
      control={
        <Switch
          //needed to add sx cause overwriting it by tailwind classes doesn't work
          sx={{
            "& .Mui-checked": {
              "&:hover": {
                backgroundColor: "transparent",
              },
              "pl": "2px",
              "& + .MuiSwitch-track": {
                backgroundColor: "#250056 !important",
                opacity: `1 !important`,
              },
              "& .MuiSwitch-thumb": {
                backgroundColor: "white",
              },
            },
          }}
          disableRipple
          disableTouchRipple
          disableFocusRipple
          classes={{
            root: "flex items-center p-0 w-[42px] ml-2",
            track: "bg-purple-9 opacity-100 h-6 rounded-3xl",
            thumb: "bg-grey-3 shadow-none w-4 h-4",
            //it has to be in px to be properly aligned
            switchBase: "px-0 pt-[11px] pl-1 hover:bg-transparent",
          }}
        />
      }
      label="Full survey view"
      labelPlacement="start"
    />
  );
};
