import { QueryHookOptions, gql, useQuery } from "@apollo/client";
import { USER_CATEGORY_ROLES_ENUM } from "config/constants";
import ROUTES from "config/routes";
import { AllQuestionsByRole, useURLSearchParams } from "hooks";
import moment from "moment";
import {
  LocationState,
  SURVEY_TYPE,
} from "pages/managerCareerPathForm/ManagerCareerPathForm.page";
import { useMemo } from "react";
import { NavLinkProps, generatePath } from "react-router-dom";
import { Project } from "types/assignments";
import { Employee } from "types/employee";
import {
  Category,
  EmployeeCareerPath,
  Question,
  Subcategory,
} from "types/career-path";
import { calculateSubcategoryLevel } from "utils/form.utils";
import {
  CURRENT_SURVEY,
  QuestionsByRoleCount,
  generateQuestions,
  getSurveyStatus,
} from "./utils";

export type Talk2GrowEmployeeType = {
  role: string;
  department: string;
  email: string;
  firstName: string;
  lastName: string;
  directSupervisorName?: string;
  picture?: string;
  currentSurvey: CURRENT_SURVEY;
  okrCount: string; // TODO: adjust type
  projects: Pick<Project, "id" | "name">[];
  id: string;
  link: Partial<NavLinkProps<LocationState>["location"]>;
};

type CareerPathEmployees = {
  surveysInProgress: Talk2GrowEmployeeType[];
  surveysDone: Talk2GrowEmployeeType[];
};

export type AggregateType = {
  aggregate: {
    count: number;
  };
};

type FinalAnswerExtendType = Pick<
  EmployeeCareerPath,
  "finalAnswer" | "managerAnswer" | "question" | "id" | "employeeId"
>;

type AnswerType<D = object> = Pick<EmployeeCareerPath, "questionId"> & D;

type SubCategoryRoleType = {
  role: string;
};

export type CareerPathEmployee = Pick<
  Employee,
  | "id"
  | "firstName"
  | "lastName"
  | "email"
  | "picture"
  | "directSupervisor"
  | "department"
> & {
  projects: { project: Pick<Project, "id" | "name"> }[];
  role?: {
    erpRole: string;
    categoryRole: string;
  };
  allAnswers: AggregateType;
  submittedAnswers: AnswerType[];
  managerAnswers: AnswerType[];
  finalAnswers: AnswerType<FinalAnswerExtendType>[];
};

export type CareerPathSubcategory = Pick<
  Subcategory,
  "id" | "name" | "category"
> & {
  questions: Pick<Question, "id">[];
  subcategoryRoles: SubCategoryRoleType[];
};
type CareerPathCategory = Category & { subcategories: CareerPathSubcategory[] };

export interface GetEmployeesCareerPathData {
  employees: CareerPathEmployee[];
  categories: Array<Category & { subcategories: CareerPathSubcategory[] }>;
}

export interface GetEmployeesCareerPathVars {
  assignmentsDate: string;
  version: Record<"id", { _eq: string }> | Record<"current", { _eq: boolean }>;
}

export const GET_EMPLOYEES_CAREER_PATH = gql`
  query (
    $assignmentsDate: timestamptz!
    $version: career_path_versions_bool_exp
  ) {
    categories: career_path_categories(order_by: { order: asc }) {
      id
      name
      subcategories: career_path_subcategories(order_by: { order: asc }) {
        questions: career_path_questions(
          distinct_on: id
          where: { career_path_version: $version }
        ) {
          id
        }
        id
        name
        subcategoryRoles: subcategory_roles {
          role
        }
      }
    }
    employees(
      order_by: { last_name: asc }
      where: { status: { _is_null: false, _neq: ARCHIVED } }
    ) {
      id
      email
      firstName: first_name
      lastName: last_name
      department {
        name
      }
      picture
      projects: project_employee_assignments(
        distinct_on: project_id
        where: {
          start_date: { _lte: $assignmentsDate }
          end_date: { _gte: $assignmentsDate }
        }
      ) {
        project {
          id
          name
        }
      }
      role {
        erpRole: erp_employee_role
        categoryRole: category_role
      }
      directSupervisor: direct_supervisor {
        firstName: first_name
        lastName: last_name
      }
      submittedAnswers: employee_careers(
        distinct_on: question_id
        where: {
          career_path_question: { career_path_version: $version }
          submitted_on: { _is_null: false }
        }
      ) {
        questionId: question_id
      }
      managerAnswers: employee_careers(
        distinct_on: question_id
        where: {
          career_path_question: { career_path_version: $version }
          submitted_on: { _is_null: true }
          manager_answer: { _is_null: false }
        }
      ) {
        questionId: question_id
      }
      finalAnswers: employee_careers(
        where: {
          career_path_question: { career_path_version: $version }
          submitted_on: { _is_null: true }
          final_answer: { _is_null: false }
        }
        order_by: {
          career_path_question: { career_path_level: { value: asc } }
        }
      ) {
        questionId: question_id
        question: career_path_question {
          subcategoryId: subcategory_id
          level: career_path_level {
            value
          }
        }
        finalAnswer: final_answer
        managerAnswer: manager_answer
      }
      allAnswers: employee_careers_aggregate(
        where: { career_path_question: { career_path_version: $version } }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`;

export const useEmployeesCareerPath = (
  options: QueryHookOptions<
    GetEmployeesCareerPathData,
    GetEmployeesCareerPathVars
  >
) => {
  const todayISOString = moment().endOf("day").toISOString();
  const { searchParams } = useURLSearchParams();
  const versionIdParam = searchParams.get("versionId");

  const { data, loading, error, refetch } = useQuery<
    GetEmployeesCareerPathData,
    GetEmployeesCareerPathVars
  >(GET_EMPLOYEES_CAREER_PATH, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    refetchWritePolicy: "overwrite",
    variables: {
      assignmentsDate: todayISOString,
      version: versionIdParam
        ? { id: { _eq: versionIdParam } }
        : { current: { _eq: true } },
    },
    ...options,
  });

  const subcategories = useMemo(
    () =>
      data?.categories.reduce(
        (acc, { subcategories }) => [...acc, ...subcategories],
        [] as CareerPathSubcategory[]
      ) || [],
    [data?.categories]
  );

  const { allQuestionsByRole, questionsByRoleCount } = useMemo<{
    allQuestionsByRole: AllQuestionsByRole;
    questionsByRoleCount: QuestionsByRoleCount;
  }>(() => {
    const allQuestionsByRole = generateQuestions(subcategories);
    return {
      allQuestionsByRole,
      questionsByRoleCount: Object.entries(allQuestionsByRole || {}).reduce(
        (acc, [key, questionIds]) => ({
          ...acc,
          [key]: Object.keys(questionIds || {})?.length || 0,
        }),
        {}
      ),
    };
  }, [subcategories]);

  const subcategoriesByRole = useMemo(
    () =>
      (subcategories ?? []).reduce<Record<string, Record<string, boolean>>>(
        (acc, { name, subcategoryRoles }) => {
          if (subcategoryRoles.length) {
            subcategoryRoles.forEach(({ role }) => {
              acc[role] = { ...acc[role], [name]: true };
            });
          } else {
            acc[USER_CATEGORY_ROLES_ENUM.GENERAL] = {
              ...acc[USER_CATEGORY_ROLES_ENUM.GENERAL],
              [name]: true,
            };
          }
          return acc;
        },
        {}
      ),
    [subcategories]
  );

  const preparedData = useMemo<{
    employees: CareerPathEmployees;
    categories: CareerPathCategory[];
  }>(
    () => ({
      employees: (data?.employees ?? []).reduce<CareerPathEmployees>(
        (
          acc,
          {
            role,
            department,
            managerAnswers,
            allAnswers,
            finalAnswers,
            submittedAnswers,
            projects,
            ...employee
          }
        ) => {
          const {
            isEmployeeSurveyCompleted,
            isManagerSurveyCompleted,
            isEvaluationSurveyCompleted,
            currentSurvey,
          } = getSurveyStatus({
            allQuestionsByRole,
            questionsByRoleCount,
            allAnswersCount: allAnswers?.aggregate?.count,
            submittedAnswers,
            managerAnswers,
            finalAnswers,
            role,
          });

          const subcategoriesLevels: Record<string, number | undefined> =
            subcategories.reduce((result, { id, name }) => {
              const hasSubcategoriesForRole =
                subcategoriesByRole[role?.categoryRole ?? ""]?.[name] ||
                subcategoriesByRole[USER_CATEGORY_ROLES_ENUM.GENERAL]?.[name];
              return {
                ...result,
                [name]:
                  isEvaluationSurveyCompleted && hasSubcategoriesForRole
                    ? calculateSubcategoryLevel(id, finalAnswers, true)
                    : undefined,
              };
            }, {});

          const employeeResult = {
            ...employee,
            role: role?.erpRole || "",
            currentSurvey,
            department: department?.name || "",
            okrCount: "-", // mock for now
            projects: projects.map(({ project }) => project),
            directSupervisorName: employee.directSupervisor
              ? `${employee.directSupervisor.lastName} ${employee.directSupervisor.firstName}`
              : undefined,
            ...subcategoriesLevels,
            link: isEmployeeSurveyCompleted
              ? {
                  pathname: generatePath(
                    isEvaluationSurveyCompleted
                      ? ROUTES.manager.careerPathDetails
                      : ROUTES.manager.careerPathForm,
                    { employeeId: employee.id }
                  ),
                  state: {
                    surveyType: isManagerSurveyCompleted
                      ? SURVEY_TYPE.EVALUATION_SURVEY
                      : SURVEY_TYPE.MANAGER_SURVEY,
                  },
                }
              : undefined,
          };

          if (isEvaluationSurveyCompleted) {
            acc.surveysDone.push(employeeResult);
          } else {
            acc.surveysInProgress.push(employeeResult);
          }
          return acc;
        },
        {
          surveysInProgress: [],
          surveysDone: [],
        }
      ),
      categories: data?.categories ?? [],
    }),
    [
      data?.employees,
      data?.categories,
      allQuestionsByRole,
      questionsByRoleCount,
      subcategories,
      subcategoriesByRole,
    ]
  );
  return {
    data: preparedData,
    loading,
    error,
    refetch,
  };
};
