import { Table, Row } from "@tanstack/react-table";
import { TABLE_ENUM } from "config/table-const";
import { downloadBlob } from "utils/general.utils";

const generateEmployeeTableCsvContent = (table: Table<any>) => {
  const rows :  Row<any>[] = table.getSortedRowModel().rows;

  const headerGroups = table.getHeaderGroups();
  const csvHeaders = headerGroups[0].headers.map(el => el.column.columnDef.header).join(',')
  const csvRows = rows.map(row =>
    row.getVisibleCells().map(cell => {
      let value = cell.getValue();
      if (cell.column.id === 'lastName')
        value = `${value} ${row.original.firstName}`
      if (cell.column.id === 'projects' && Array.isArray(value))
        value = value.map(el => el.name).join(',');
      return value ? `"${value}"` : "";
    }).join(',')
  ).join('\n');
  return csvHeaders + '\n' + csvRows
};

export const useGetTableCsvContent = (name: TABLE_ENUM, table: Table<any>) => {
  const canExportTable = [
    TABLE_ENUM.EMPLOYEES,
    TABLE_ENUM.ARCHIVED_EMPLOYEES,
  ].includes(name);

  const getFile = () => {
    if (!canExportTable) return;

    let csvContent: string | undefined = undefined;
    if ([TABLE_ENUM.EMPLOYEES, TABLE_ENUM.ARCHIVED_EMPLOYEES].includes(name)) {
      csvContent = generateEmployeeTableCsvContent(table);
    }
    if (csvContent) {
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      downloadBlob(blob, `${name}.csv`);
    }
  };

  return { canExportTable, getFile };
};
