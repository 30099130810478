import {
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getGroupedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { TableData } from "components/table/types";
import { TableProps } from "../../Table";
import { customFilterFns } from "../../components/filters/customFilters";
import { customSortingFns } from "../../components/sorting/customSorting";

type UseTableProps<D extends TableData> = Pick<
  TableProps<D>,
  "data" | "columns" | "state" | "initialState"
>;

export const useTable = <D extends TableData>(
  tableOptions: UseTableProps<D>
) => {
  const extraModels = {
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  };
  const table = useReactTable<D>({
    filterFns: customFilterFns,
    sortingFns: customSortingFns,
    getCoreRowModel: getCoreRowModel(),
    autoResetPageIndex: true,
    autoResetExpanded: false,
    ...(tableOptions.data.length > 0 ? extraModels : {}),
    ...tableOptions,
  });

  return { table };
};
