import { GiveAnswerArgs } from "hooks";
import React, { FC, Fragment } from "react";
import {
  EmployeeCareerPath,
  FormQuestionsState,
  Subcategory,
} from "types/career-path";
import { CareerPathFormQuestion } from "./formQuestion/FormQuestion";

interface Props {
  subcategory: Subcategory;
  formQuestionsState?: FormQuestionsState;
  subcategoryAnswers: EmployeeCareerPath[];
  giveAnswer: (args: GiveAnswerArgs) => void;
}

export const CareerPathFormContent: FC<Props> = ({
  subcategory,
  formQuestionsState,
  subcategoryAnswers,
  giveAnswer,
}) => {
  const questions = subcategory.questions ?? [];

  return (
    <>
      {questions.map((question, index) => {
        const pathAnswer = subcategoryAnswers.find(
          (a) => a.questionId === question.id
        );
        const isActiveQuestion =
          formQuestionsState?.activeQuestionId === question.id;
        const isFocusedQuestion =
          formQuestionsState?.focusedQuestionId === question.id;

        return (
          <Fragment key={question.id}>
            {index !== 0 && (
              <hr className="border-t-1 mb-8 w-full border-solid border-neutral-n5" />
            )}
            <CareerPathFormQuestion
              question={question}
              isActive={isActiveQuestion}
              isFocused={isFocusedQuestion}
              pathAnswer={pathAnswer}
              giveAnswer={giveAnswer}
            />
          </Fragment>
        );
      })}
    </>
  );
};
