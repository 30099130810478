import { Button } from "components";
import React, { FC } from "react";
import styles from "./ColumnMultiFilterButtons.styles";

type ColumnMultiFilterButtonsProps = {
  onCancel: () => void;
  onApply: () => void;
};

export const ColumnMultiFilterButtons: FC<ColumnMultiFilterButtonsProps> = ({
  onCancel,
  onApply,
}) => {
  return (
    <div className="flex gap-2 md:gap-0">
      <Button
        variant="secondary"
        className={`${styles.button} w-1/4 rounded-bl rounded-br-none`}
        onClick={onCancel}
      >
        Cancel
      </Button>
      <Button
        variant="primary"
        className={`${styles.button} w-3/4 rounded-bl-none rounded-br sm:font-semibold`}
        onClick={onApply}
      >
        Apply
      </Button>
    </div>
  );
};
