const styles = {
  topbar: `
    fixed
    z-20
    top-0
    bg-white
    shadow-lg
    w-full`,
  openSidebarButton: `
    inline-flex 
    p-2 
    mr-4
    text-purple-1
    rounded-lg 
    hover:bg-purple-7`,
  userDropdownMenu: `
    z-50
    block
    mt-3
    text-base 
    text-purple-1
    list-none 
    bg-white 
    rounded 
    shadow`,
  userMenuItem: `
    w-full
    rounded-none
    font-normal
    block 
    px-4 
    py-2 
    text-purple-1
    hover:bg-purple-7`,
};

export default styles;
