import React, { FC, PropsWithChildren } from "react";
import { useAuthContext } from "contexts";

interface HasAccessProps extends PropsWithChildren {
  roles?: Array<string> | string;
}

export const HasAccess: FC<HasAccessProps> = ({ children, roles }) => {
  const { user } = useAuthContext();

  if (!user) {
    return null;
  }

  const checkAuth = () => {
    return user.role === roles || roles?.includes(user.role as string);
  };

  if (checkAuth()) {
    return <>{children}</>;
  }

  return (
    <div className="text-center text-rose-600">
      <h2 className="font-bold">Access Denied</h2>
      <h2>You have no permission to see this view</h2>
    </div>
  );
};
