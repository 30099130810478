import { ApolloError, Reference, gql, useMutation } from "@apollo/client";
import { useAuthContext } from "contexts";
import {
  GET_EMPLOYEE_ALL_NOTIFICATIONS,
  GET_MANAGER_ALL_NOTIFICATIONS,
} from "hooks";
import { useCallback } from "react";
import { toast } from "react-toastify";
import {
  NOTIFICATION_STATUS,
  NotificationAssignment,
} from "types/notifications";

interface UpdateAssignedNotificationsData {
  updatedAssignedNotifications: {
    result: Pick<
      NotificationAssignment,
      "id" | "status" | "modifiedAt" | "reviewedAt"
    >[];
  };
}
interface UpdateAssignedNotificationsVars {
  assignedNotifications: Pick<NotificationAssignment, "id">[];
  status: NotificationAssignment["status"];
}

const UPDATE_ASSIGNED_NOTIFICATIONS = gql`
  mutation updateAssignedNotification(
    $assignedNotifications: [UpdateAssignedNotificationsInputType]!
    $status: String!
  ) {
    updatedAssignedNotifications: update_assigned_notifications(
      notification_assignments: $assignedNotifications
      status: $status
    ) {
      result {
        id
        status
        modifiedAt: modified_at
        reviewedAt: reviewed_at
      }
    }
  }
`;

export const useUpdateAssignedNotifications = () => {
  const { user, isManager } = useAuthContext();
  const employeeId = user?.userId;
  const managerId = user?.managerId;

  const [updateNotificationAssignments] = useMutation<
    UpdateAssignedNotificationsData,
    UpdateAssignedNotificationsVars
  >(UPDATE_ASSIGNED_NOTIFICATIONS, {
    update(cache, { data }) {
      const notifications = data?.updatedAssignedNotifications.result ?? [];
      if (notifications.length > 0) {
        notifications.forEach((notification) => {
          if (notification.status === NOTIFICATION_STATUS.DELETED) {
            cache.modify({
              fields: {
                notifications_assignments(existing = [], { readField }) {
                  return existing.filter(
                    (ref: Reference) => notification.id !== readField("id", ref)
                  );
                },
              },
            });
          } else {
            cache.writeFragment({
              id: `notifications_assignments:${notification.id}`,
              fragment: gql`
                fragment Notification on notifications_assignments {
                  status
                  modified_at
                  reviewed_at
                }
              `,
              data: {
                status: notification.status,
                modified_at: notification.modifiedAt,
                reviewed_at: notification.reviewedAt,
              },
            });
            const query = isManager()
              ? GET_MANAGER_ALL_NOTIFICATIONS
              : GET_EMPLOYEE_ALL_NOTIFICATIONS;
            cache.updateQuery(
              {
                query,
                variables: isManager() ? { managerId } : { employeeId },
              },
              (data) => ({ unreadCounter: data?.unreadCounter - 1 })
            );
          }
        });
      }
    },
  });

  const updateAssignedNotifications = useCallback(
    async (variables: UpdateAssignedNotificationsVars) => {
      try {
        await updateNotificationAssignments({ variables });
      } catch (ex) {
        toast.error((ex as ApolloError)?.message);
      }
    },
    [updateNotificationAssignments]
  );

  return { updateAssignedNotifications };
};
