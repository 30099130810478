import React, { FC } from "react";
import { styles } from "./tableHead.styles";

type TableHeadProps = {
  categoryName?: string;
  subCategoryName?: string;
};

export const TableHead: FC<TableHeadProps> = ({
  categoryName,
  subCategoryName,
}) => {
  return (
    <thead className="sticky top-0 bg-white">
      <tr>
        <th className={styles.categoryTableHeadCell}>
          <p className={styles.categoryName}>{categoryName}</p>/
          <p className={styles.subcategoryName}>{subCategoryName}</p>
        </th>
        <th className={`${styles.tableHeadCell} px-3`}>Employee</th>
        <th className={`${styles.tableHeadCell} px-3`}>Manager</th>
        <th className={`${styles.tableHeadCell} pl-3 pr-12`}>
          Final Evaluation
        </th>
      </tr>
    </thead>
  );
};
