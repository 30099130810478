const styles = {
  label: `
    text-purple-1 
    font-sans
    text-sm
    mb-2
    break-words
    whitespace-normal`,
  root: `
    bg-purple-7
    text-sm
    py-1.5
    px-2
    rounded
    border
    border-solid
    border-grey-6
    hover:border-purple-2`,
  focused: `
    border
    border-purple-2`,
};

export default styles;
