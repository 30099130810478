const styles = {
  tableContainer: `
    bg-white
    flex-1
    md:flex-none
    md:rounded
    md:border
    md:border-grey-4
    md:shadow-[0px 5px 30px 0px rgba(30, 7, 58, 0.05)]`,
  table: `
    w-full`,

  headRow: `
    text-purple-1
    bg-purple-7`,
  headCell: `
    uppercase
    text-left
    text-xs
    font-semibold`,
  headCellContent: `
    flex
    items-center
    px-4
    py-3.5`,

  bodyRow: `
    text-sm
    text-grey-1
    odd:bg-white
    even:bg-purple-7`,
  bodyCell: `
    px-4
    py-3.5`,
  sortIcon: `
    ml-2`,
};

export default styles;
