import React, { FC } from "react";

interface LoadingProps {
  fullScreen?: boolean;
}

export const Loading: FC<LoadingProps> = ({ fullScreen = false }) => {
  return (
    <div
      className={`flex items-center justify-center ${
        fullScreen ? "min-h-screen" : ""
      }`}
    >
      <div
        className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
        role="status"
      />
      <span className="ml-3 text-xl">Loading...</span>
    </div>
  );
};
