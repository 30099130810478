import moment from "moment";
import { gql, useQuery } from "@apollo/client";
import { YearCalendarLog } from "pages/timesheets/components/calendar/components/yearCalendar/types/types";

export interface GetEmployeeCalendarData {
  employeeCalendar: YearCalendarLog[];
}

interface GetEmployeeCalendarVars {
  employeeId: string;
  startDate: string;
  endDate: string;
}

export const GET_EMPLOYEE_CALENDAR = gql`
  query employeeCalendar(
    $employeeId: uuid!
    $startDate: String!
    $endDate: String!
  ) {
    employeeCalendar: employee_calendar(
      where: {
        employee_id: { _eq: $employeeId }
        _and: [
          { working_day: { _gte: $startDate } }
          { working_day: { _lte: $endDate } }
        ]
      }
    ) {
      minutes
      type: log_type
      state
      date: working_day
    }
  }
`;

interface UseGetEmployeeCalendarProps {
  employeeId: string;
  year: number;
}

export const useGetEmployeeCalendar = ({
  employeeId,
  year,
}: UseGetEmployeeCalendarProps) => {
  const startDate = moment([year]).startOf("year").format("YYYY-MM-DD");
  const endDate = moment([year]).endOf("year").format("YYYY-MM-DD");

  return useQuery<GetEmployeeCalendarData, GetEmployeeCalendarVars>(
    GET_EMPLOYEE_CALENDAR,
    {
      variables: { employeeId, startDate, endDate },
      skip: !employeeId,
      fetchPolicy: "cache-first",
      pollInterval: 900000, // 15min
    }
  );
};
