import { InputBaseComponentProps } from "@mui/material";
import { Button } from "components";
import React, { FC } from "react";
import { CloseIcon, SearchIcon } from "resources/icons";
import { twMerge } from "tailwind-merge";
import styles from "./Search.styles";

export interface SearchProps {
  value?: string;
  handleChange: (value: string) => void;
  placeholder?: string;
  inputProps?: InputBaseComponentProps;
}

export const Search: FC<SearchProps> = ({
  value,
  handleChange,
  placeholder = "Search",
  inputProps,
}) => {
  return (
    <div className={styles.searchContainer}>
      <SearchIcon className={`${styles.icon} left-3`} />
      <input
        {...inputProps}
        type="text"
        placeholder={placeholder}
        onChange={(e) => handleChange(e.target.value.toLocaleLowerCase())}
        value={value}
        className={twMerge(styles.searchInput, inputProps?.className)}
      />
      {value ? (
        <Button
          onClick={() => handleChange("")}
          aria-label="reset search"
          className="p-0"
        >
          <CloseIcon className={`${styles.icon} right-3`} />
        </Button>
      ) : null}
    </div>
  );
};
