import { Button } from "components";
import React, { FC, ReactElement } from "react";
import { Arrow2Icon, FiltersIcon } from "resources/icons";
import { UseFilters } from "../../useFilters";
import styles from "./FiltersOpenButton.styles";

type FiltersOpenButtonProps = {
  tableFiltersCount: number;
} & Pick<UseFilters, "isFiltersSectionOpen" | "setFiltersSectionOpen">;

export const FiltersOpenButton: FC<FiltersOpenButtonProps> = ({
  tableFiltersCount,
  isFiltersSectionOpen,
  setFiltersSectionOpen,
}): ReactElement => {
  return (
    <Button
      onClick={() => setFiltersSectionOpen((prev) => !prev)}
      className="w-2/5 justify-start gap-0 p-0 font-normal md:w-auto"
    >
      <FiltersIcon className="h-4 w-4" />
      <span className="mx-2 text-sm">Filters</span>
      {!!tableFiltersCount && !isFiltersSectionOpen && (
        <div className={styles.filtersCounter}>{tableFiltersCount}</div>
      )}
      <Arrow2Icon
        className={`hidden h-5 w-5 md:block ${
          isFiltersSectionOpen ? "rotate-90" : "-rotate-90"
        }`}
      />
    </Button>
  );
};
