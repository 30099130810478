import { Button } from "components";
import React, { FC } from "react";
import { ArrowIcon } from "resources/icons";
import { CareerPathFormCloseButton } from "../formCloseButton/FormCloseButton";
import styles from "./FormBar.styles";

interface Props {
  isBackDisabled: boolean;
  isNextDisabled: boolean;
  handleNext: () => void;
  handleBack: () => void;
  withWrapper?: boolean;
}

export const CareerPathFormBar: FC<Props> = ({
  isBackDisabled,
  isNextDisabled,
  handleNext,
  handleBack,
  withWrapper = true,
}) => {
  return (
    <div
      className={
        withWrapper ? styles.barContainer : "flex w-full justify-center"
      }
    >
      <Button
        variant="surveySecondary"
        onClick={() => handleBack()}
        disabled={isBackDisabled}
        className="mr-4"
      >
        <ArrowIcon className={styles.leftArrow} />
        Back
      </Button>
      <Button
        variant="surveyPrimary"
        onClick={() => handleNext()}
        disabled={isNextDisabled}
        className="px-4 py-2"
      >
        Next
        <ArrowIcon className={styles.rightArrow} />
      </Button>
      {withWrapper && (
        <span className="xs:hidden ml-6">
          <CareerPathFormCloseButton />
        </span>
      )}
    </div>
  );
};
