import { ColumnSort, flexRender } from "@tanstack/react-table";
import { Button, Drawer } from "components";
import { TableData } from "components/table/types";
import React, { Fragment, useCallback, useState } from "react";
import { MobileFiltersProps } from "../../MobileFilters";
import { styles } from "./MobileSort.styles";

type MobileSortType = { checkId?: string; isTouched?: boolean } & ColumnSort;

type MobileSortProps<D extends TableData> = Pick<
  MobileFiltersProps<D>,
  "table" | "headers"
>;

export const MobileSort = <D extends TableData>({
  table,
  headers,
}: MobileSortProps<D>) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const sort = table.getState().sorting?.[0];
  const [sortValue, setSortValue] = useState<MobileSortType | undefined>(sort);
  const sortColumnId = sort?.id;
  const header = headers?.[sortColumnId];
  const transformSortByLabel = header?.column?.columnDef?.transformSortByLabel;
  const closeDrawer = useCallback(() => {
    setIsDrawerOpen(false);
    setSortValue((prevState) =>
      prevState ? { ...prevState, isTouched: false } : undefined
    );
  }, []);
  const handleApply = useCallback(() => {
    table.setSorting(() =>
      sortValue?.id ? [{ id: sortValue.id, desc: sortValue.desc }] : []
    );
    closeDrawer();
  }, [closeDrawer, sortValue?.desc, sortValue?.id, table]);

  return (
    <>
      <div
        className="flex justify-end gap-2 sm:w-auto"
        onClick={() => setIsDrawerOpen(true)}
      >
        <p className="text-sm text-grey-1">Sort by:</p>
        <p className="text-sm font-semibold text-purple-1">
          {transformSortByLabel
            ? transformSortByLabel(sort)
            : flexRender(
                header?.column?.columnDef?.header,
                header?.getContext()
              )}
        </p>
      </div>
      <Drawer isOpen={isDrawerOpen} title="Sort by" onClose={closeDrawer}>
        <div className="items-between flex grow flex-col">
          <ul className="flex grow flex-col gap-2">
            {Object.values(headers).map((header) => {
              return (
                <Fragment key={header.id}>
                  {!header?.column?.columnDef?.disableDefaultMobileSort ? (
                    <li className={styles.liElement}>
                      <label className={styles.label}>
                        <input
                          type="radio"
                          name="sort-radio-button"
                          defaultChecked={sortValue?.checkId === header.id}
                          onClick={() =>
                            setSortValue({
                              desc: true,
                              id: header.id,
                              checkId: header.id,
                              isTouched: true,
                            })
                          }
                        />
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </label>
                    </li>
                  ) : (
                    <>
                      {header?.column?.columnDef?.mobileSort?.map(
                        ({ label, desc, id, isDefaultChecked }) => {
                          return (
                            <li key={id + label} className={styles.liElement}>
                              <label className={styles.label}>
                                <input
                                  type="radio"
                                  name="sort-radio-button"
                                  defaultChecked={
                                    sortValue
                                      ? sortValue?.checkId
                                        ? sortValue.checkId === id
                                        : isDefaultChecked
                                      : false
                                  }
                                  onClick={() =>
                                    setSortValue({
                                      desc,
                                      id: header.id,
                                      checkId: id,
                                      isTouched: true,
                                    })
                                  }
                                />
                                {label}
                              </label>
                            </li>
                          );
                        }
                      )}
                    </>
                  )}
                </Fragment>
              );
            })}
          </ul>
          <div className="flex gap-4">
            <Button
              variant="secondary"
              onClick={closeDrawer}
              className={`${styles.button} rounded-bl rounded-br-none xs:w-1/4`}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              disabled={!sortValue?.isTouched}
              onClick={handleApply}
              className={`${styles.button} rounded-bl-none rounded-br xs:w-3/4 sm:font-semibold`}
            >
              Apply
            </Button>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default MobileSort;
