import { gql, QueryHookOptions, useQuery } from "@apollo/client";
import { Employee } from "types/employee";

export interface GetEmployeeData {
  employee: Pick<
    Employee,
    "id" | "email" | "firstName" | "lastName" | "picture"
  > & {
    role: {
      erpRole: string;
      categoryRole: string;
    };
  };
}

export interface GetEmployeeVars {
  employeeId: string;
}

export const GET_EMPLOYEE = gql`
  query getEmployee($employeeId: uuid!) {
    employee: employees_by_pk(id: $employeeId) {
      id
      email
      firstName: first_name
      lastName: last_name
      externalId: external_id
      picture
      role {
        id
        erpRole: erp_employee_role
        categoryRole: category_role
      }
    }
  }
`;

export const useEmployee = (
  employeeId: string,
  options?: QueryHookOptions<GetEmployeeData, GetEmployeeVars>
) => {
  return useQuery<GetEmployeeData, GetEmployeeVars>(GET_EMPLOYEE, {
    variables: { employeeId },
    ...options,
  });
};
