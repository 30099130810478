import moment from "moment";
import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  GET_EMPLOYEE_CALENDAR,
  GET_EMPLOYEE_TIMESHEETS_AND_ASSIGNMENTS_MONTHLY,
  GET_EMPLOYEE_TIMESHEETS_AND_ASSIGNMENTS_WEEKLY,
  useIsMobile,
} from "hooks";
import { useCallback } from "react";
import { toast } from "react-toastify";
import { TimeRange, Timesheet, TimesheetStateType } from "types/timesheets";

type ApproveRejectTimesheetVars = {
  workLogs: {
    id: Timesheet["id"];
    state: Timesheet["state"];
    state_info: Timesheet["stateInfo"];
    billable_minutes: Timesheet["billableMinutes"];
  }[];
};

type ApproveRejectTimesheetData = {
  timesheets: { result: Timesheet[] };
};

const APPROVE_OR_REJECT_TIMESHEET = gql`
  mutation approveRejectTimesheet($workLogs: [AcknowledgeTimeLogInputType]!) {
    timesheets: acknowledge_time_log(work_logs: $workLogs) {
      result {
        id
        state
        stateInfo: state_info
        billableMinutes: billable_minutes
      }
    }
  }
`;

export type ApproveTimesheet = (
  timesheet: Array<
    Pick<Timesheet, "billableMinutes"> & Required<Pick<Timesheet, "id">>
  >
) => Promise<void>;

export type RejectTimesheet = (
  timesheet: Required<Pick<Timesheet, "id" | "stateInfo">>[]
) => Promise<void>;

interface Props {
  employeeId: string;
  activeWeek: TimeRange;
  activeMonth: TimeRange;
  activeYear: number;
}

export const useApproveRejectTimesheet = ({
  employeeId,
  activeWeek,
  activeMonth,
  activeYear,
}: Props) => {
  const { isMobile } = useIsMobile();
  const { start: weekStart, end: weekEnd } = activeWeek;
  const { start: monthStart, end: monthEnd } = activeMonth;
  const startDate = moment([activeYear]).startOf("year").format("YYYY-MM-DD");
  const endDate = moment([activeYear]).endOf("year").format("YYYY-MM-DD");

  const [processApproveRejectTimesheet, data] = useMutation<
    ApproveRejectTimesheetData,
    ApproveRejectTimesheetVars
  >(APPROVE_OR_REJECT_TIMESHEET, {
    refetchQueries: [
      {
        query: GET_EMPLOYEE_TIMESHEETS_AND_ASSIGNMENTS_WEEKLY,
        variables: {
          startDate: weekStart,
          endDate: weekEnd,
          contractStartDate: weekStart,
          contractEndDate: weekStart,
          exceptionsStartDate: weekStart,
          exceptionsEndDate: weekEnd,
          employeeId,
          isManager: true,
        },
      },
      // on mobile we do not need to refetch calendar data cause it's displayed only on desktop views
      ...(isMobile
        ? []
        : [
            {
              query: GET_EMPLOYEE_CALENDAR,
              variables: { employeeId, startDate, endDate },
            },
            {
              query: GET_EMPLOYEE_TIMESHEETS_AND_ASSIGNMENTS_MONTHLY,
              variables: {
                startDate: monthStart,
                endDate: monthEnd,
                contractStartDate: monthStart,
                contractEndDate: monthStart,
                exceptionsStartDate: monthStart,
                exceptionsEndDate: monthEnd,
                employeeId,
                isManager: true,
              },
            },
          ]),
    ],
  });

  const approveTimesheet = useCallback<ApproveTimesheet>(
    async (timesheets) => {
      try {
        await processApproveRejectTimesheet({
          variables: {
            workLogs: timesheets.map(({ id, billableMinutes }) => ({
              id,
              billable_minutes: billableMinutes,
              state: TimesheetStateType.APPROVED,
              state_info: null,
            })),
          },
        });
      } catch (ex) {
        toast.error((ex as ApolloError)?.message);
      }
    },
    [processApproveRejectTimesheet]
  );
  const rejectTimesheet = useCallback<RejectTimesheet>(
    async (timesheets) => {
      try {
        await processApproveRejectTimesheet({
          variables: {
            workLogs: timesheets.map(({ id, stateInfo }) => ({
              id: id,
              state_info: stateInfo,
              state: TimesheetStateType.DECLINED,
              billable_minutes: null,
            })),
          },
        });
      } catch (ex) {
        toast.error((ex as ApolloError)?.message);
      }
    },
    [processApproveRejectTimesheet]
  );

  return { approveTimesheet, rejectTimesheet, ...data };
};
