import { useCallback, useState } from "react";
import { ManagerSurveyProps } from "../../components";

export type QuestionsVisibilityType = Record<string, boolean>;
export type ToggleQuestionsAnswersVisibilityType = (questionId: string) => void;

type useQuestionsAnswersVisibilityProps = Pick<ManagerSurveyProps, "answers">;

export const useQuestionsAnswersVisibility = ({
  answers,
}: useQuestionsAnswersVisibilityProps) => {
  const [questionsVisibility, setQuestionsVisibility] =
    useState<QuestionsVisibilityType>({
      ...answers?.reduce<QuestionsVisibilityType>(
        (acc, { questionId }) => ({
          ...acc,
          [questionId]: false,
        }),
        {}
      ),
      showAll: false,
    });

  const toggleAllQuestionAnswerVisibility = useCallback(() => {
    setQuestionsVisibility((prevState) => ({
      ...Object.keys(prevState || {}).reduce(
        (acc, key) => ({ ...acc, [key]: !prevState?.showAll }),
        {}
      ),
      showAll: !prevState?.showAll,
    }));
  }, []);

  const toggleQuestionsAnswersVisibility =
    useCallback<ToggleQuestionsAnswersVisibilityType>((questionId) => {
      setQuestionsVisibility((prevState) => ({
        ...prevState,
        [questionId]: !prevState?.[questionId],
      }));
    }, []);

  return {
    toggleQuestionsAnswersVisibility,
    questionsVisibility,
    toggleAllQuestionAnswerVisibility,
  };
};
