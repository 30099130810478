import moment from "moment";
import { ApolloError, Reference, gql, useMutation } from "@apollo/client";
import { GET_ABSENCES_INFO, GET_EMPLOYEE_CALENDAR } from "hooks";
import { useCallback } from "react";
import { toast } from "react-toastify";
import { useTimeSheetsWeekContext } from "pages/timesheets/context";

type DeleteTimesheetData = {
  delete_time_logs: null;
};

type DeleteTimesheetVars = {
  id: string;
};

const DELETE_TIMESHEET = gql`
  mutation deleteTimesheet($id: String!) {
    deletedTimesheet: delete_time_log(id: $id) {
      delete_time_logs
    }
  }
`;

interface Props {
  employeeId: string;
}

export const useDeleteTimesheet = ({ employeeId }: Props) => {
  const { activeYear } = useTimeSheetsWeekContext();
  const startDate = moment([activeYear]).startOf("year").format("YYYY-MM-DD");
  const endDate = moment([activeYear]).endOf("year").format("YYYY-MM-DD");

  const [processDeleteTimesheet, data] = useMutation<
    DeleteTimesheetData,
    DeleteTimesheetVars
  >(DELETE_TIMESHEET, {
    refetchQueries: [
      {
        query: GET_EMPLOYEE_CALENDAR,
        variables: { employeeId, startDate, endDate },
      },
      {
        query: GET_ABSENCES_INFO,
      },
    ],
    update(cache, _, { variables }) {
      const deletedId = variables?.id;
      if (deletedId) {
        cache.modify({
          fields: {
            timesheets_work_logs(existing = [], { readField }) {
              return existing.filter(
                (ref: Reference) => deletedId !== readField("id", ref)
              );
            },
          },
        });
      }
    },
  });

  const deleteTimesheet = useCallback(
    async (timesheetId: string) => {
      try {
        await processDeleteTimesheet({
          variables: {
            id: timesheetId,
          },
        });
      } catch (ex) {
        toast.error((ex as ApolloError)?.message);
      }
    },
    [processDeleteTimesheet]
  );
  return { deleteTimesheet, ...data };
};
