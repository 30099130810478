import { Button } from "components";
import React, { FC } from "react";
import { CloseButtonProps } from "react-toastify";
import { CloseIcon } from "resources/icons";

export const ToastCloseButton: FC<CloseButtonProps> = ({ closeToast }) => {
  return (
    <Button onClick={closeToast} className="h-min p-0">
      <CloseIcon className="w-5.5 h-5.5" />
    </Button>
  );
};
