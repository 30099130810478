import { Error, Loading } from "components";
import ROUTES from "config/routes";
import {
  GetCareerPathConfigData,
  GetEmployeeData,
  useAllManagerAnswersCheck,
  useEmployee,
  useGetAnswers,
  useGetCareerPathConfig,
  useURLSearchParams,
} from "hooks";
import React, { ComponentType, FC, useMemo } from "react";
import {
  Redirect,
  generatePath,
  useLocation,
  useParams,
} from "react-router-dom";
import { EmployeeCareerPath, QueryParams } from "types/career-path";
import { TALK_2_GROW_DETAILS_TAB } from "types/tabs";
import { EvaluationSurvey, ManagerSurvey } from "./components";

export enum SURVEY_TYPE {
  MANAGER_SURVEY = "MANAGER_SURVEY",
  EVALUATION_SURVEY = "EVALUATION_SURVEY",
}

export type LocationState = {
  surveyType: SURVEY_TYPE;
};

export type SurveyComponentProps = {
  answers?: EmployeeCareerPath[];
  careerPathConfig?: GetCareerPathConfigData;
  employeeData?: GetEmployeeData;
};

const SURVEY_COMPONENT: Record<
  SURVEY_TYPE,
  ComponentType<SurveyComponentProps>
> = {
  [SURVEY_TYPE.MANAGER_SURVEY]: ManagerSurvey,
  [SURVEY_TYPE.EVALUATION_SURVEY]: EvaluationSurvey,
};

const ManagerCareerPathFormPage: FC = () => {
  const location = useLocation<LocationState>();
  const { employeeId } = useParams<QueryParams>();
  const { searchParams } = useURLSearchParams();
  const isSurveyPreview =
    searchParams.get("tab") ===
    TALK_2_GROW_DETAILS_TAB.EVALUATION_SURVEY_PREVIEW;

  const {
    data: employeeData,
    loading: isEmployeeDataLoading,
    error: isEmployeeDataError,
  } = useEmployee(employeeId);

  const categoryRole = employeeData?.employee?.role?.categoryRole;
  const {
    data: careerPathConfig,
    loading: isCareerPathLoading,
    error: isCareerPathError,
  } = useGetCareerPathConfig({
    erpRole: categoryRole,
    options: { skip: !categoryRole },
  });

  const {
    data: answersData,
    loading: isAnswersLoading,
    error: isAnswersError,
  } = useGetAnswers({ employeeId });

  const {
    isAllManagerAnswers,
    loading: isAllManagerAnswersLoading,
    error: isAllManagerAnswersError,
  } = useAllManagerAnswersCheck(employeeId);

  const surveyType = useMemo<SURVEY_TYPE>(() => {
    return !location?.state?.surveyType && !isAllManagerAnswers
      ? SURVEY_TYPE.MANAGER_SURVEY
      : SURVEY_TYPE.EVALUATION_SURVEY;
  }, [isAllManagerAnswers, location?.state?.surveyType]);
  const SurveyPreviewComponent =
    isSurveyPreview && SURVEY_COMPONENT.EVALUATION_SURVEY;
  const SurveyComponent =
    SurveyPreviewComponent ||
    SURVEY_COMPONENT[location?.state?.surveyType || surveyType];

  const isError =
    isAnswersError ||
    isCareerPathError ||
    isEmployeeDataError ||
    isAllManagerAnswersError;
  const loading =
    isAnswersLoading ||
    isCareerPathLoading ||
    isEmployeeDataLoading ||
    isAllManagerAnswersLoading;

  return (
    <>
      {isError && <Error />}
      {/* If there is no answer we should redirect manager to main view */}
      {!answersData?.answers?.length && !isAnswersLoading && (
        <Redirect to={generatePath(ROUTES.manager.careerPath)} />
      )}
      {!isError && loading ? (
        <Loading />
      ) : (
        <SurveyComponent
          answers={answersData?.answers ?? []}
          careerPathConfig={careerPathConfig}
          employeeData={employeeData}
        />
      )}
    </>
  );
};

export default ManagerCareerPathFormPage;
