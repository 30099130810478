import { Drawer } from "@mui/material";
import { useIsMobile } from "hooks";
import React, { FC } from "react";
import styles from "./Sidebar.styles";
import { SideBarContent } from "./components/sideBarContent/SideBarContent";

type SidebarProps = {
  sidebarOpen: boolean;
  setSidebarOpen: (value: boolean) => void;
  mobileSidebarOpen: boolean;
  setMobileSidebarOpen: (value: boolean) => void;
};

export const Sidebar: FC<SidebarProps> = ({
  sidebarOpen,
  setSidebarOpen,
  mobileSidebarOpen,
  setMobileSidebarOpen,
}) => {
  const { isMobile } = useIsMobile();
  return (
    <nav aria-label="main">
      <Drawer
        open={isMobile ? mobileSidebarOpen : sidebarOpen}
        variant={isMobile ? "temporary" : "persistent"}
        PaperProps={{
          classes: {
            root: `overflow-visible ${isMobile ? "w-full" : ""}`,
          },
        }}
      >
        <div className={`${isMobile ? styles.asideMobile : styles.aside}`}>
          <SideBarContent
            isMobile={isMobile}
            setSidebarOpen={isMobile ? setMobileSidebarOpen : setSidebarOpen}
          />
        </div>
      </Drawer>
    </nav>
  );
};
