const styles = {
  container: `
    flex 
    flex-wrap
    items-center
    gap-2`,
  dropdown: `
    flex 
    flex-col 
    bg-white 
    border 
    border-grey-3 
    rounded`,
};
export default styles;
