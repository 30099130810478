import { useCallback } from "react";
import moment from "moment";
import { ApolloError, gql, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { GET_PROJECT_ASSIGNMENTS } from "hooks/useProjectAssignments/useProjectAssignments";
import { GET_PROJECT } from "hooks/useProject/useProject";
import { GET_ALL_PROJECT_EMPLOYEES } from "hooks/useProjectEmployees/useProjectEmployees";
import { GET_PROJECTS } from "hooks/useProjects/useProjects";
import { GET_ASSIGNMENTS } from "hooks/useAssignments/useAssignments";
import { GET_EMPLOYEE_ASSIGNMENTS } from "hooks/useEmployeeAssignments/useEmployeeAssignments";

type ProjectAssignmentData = {
  response: { id: string };
};

// graphql syntax is not fully compatible between hasura and graphene so we have to use CreateUpdateAssignmentInputType here
type ProjectAssignmentVars = {
  assignment: {
    id: string;
    employee_id: string;
    project_id: string;
    role: string;
    start_date: string;
    end_date: string;
    fte: number;
    billable_status: boolean;
    category_of_non_billable?: string;
    reason_why_non_billable?: string;
  };
};

const UPSERT_PROJECT_ASSIGNMENT = gql`
  mutation UpsertProjectAssignment(
    $assignment: CreateUpdateAssignmentInputType
  ) {
    response: create_update_assignment(assignment: $assignment) {
      id
    }
  }
`;

export const useUpsertProjectAssignment = () => {
  const [upsertProjectAssignmentMutation, data] = useMutation<
    ProjectAssignmentData,
    ProjectAssignmentVars
  >(UPSERT_PROJECT_ASSIGNMENT);

  const upsertProjectAssignment = useCallback(
    async (values: ProjectAssignmentVars) => {
      try {
        await upsertProjectAssignmentMutation({
          variables: values,
          refetchQueries: [
            {
              query: GET_ALL_PROJECT_EMPLOYEES,
              variables: {
                projectId: values.assignment.project_id,
              },
            },
            {
              query: GET_PROJECT_ASSIGNMENTS,
              variables: {
                projectId: values.assignment.project_id,
              },
            },
            {
              query: GET_EMPLOYEE_ASSIGNMENTS,
              variables: {
                employeeId: values.assignment.employee_id,
              },
            },
            {
              query: GET_ASSIGNMENTS,
              variables: {
                now: moment().startOf("day").toISOString(),
              },
            },
            {
              query: GET_PROJECT,
              variables: {
                projectId: values.assignment.project_id,
              },
            },
            {
              query: GET_PROJECTS,
              variables: {
                startDate: moment().startOf("month").toISOString(),
                endDate: moment().endOf("month").toISOString(),
              },
            },
          ],
        });
      } catch (ex) {
        toast.error((ex as ApolloError)?.message);
      }
    },
    [upsertProjectAssignmentMutation]
  );

  return { upsertProjectAssignment, ...data };
};
