import { Button } from "components";
import React, { FC } from "react";
import { EyeCloseIcon, EyeOpenIcon } from "resources/icons";

type ShowAllButtonProps = {
  showAll: boolean;
  toggleAllQuestionAnswerVisibility: () => void;
};
export const ShowAllButton: FC<ShowAllButtonProps> = ({
  showAll,
  toggleAllQuestionAnswerVisibility,
}) => {
  return (
    <div className="flex items-center gap-2">
      <Button
        className="p-0 text-base font-normal"
        onClick={toggleAllQuestionAnswerVisibility}
      >
        <div>{showAll ? <EyeCloseIcon /> : <EyeOpenIcon />}</div>
        <p>{showAll ? "Hide" : "Show"} all employee answers</p>
      </Button>
    </div>
  );
};
