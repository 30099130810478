import { Skeleton } from "@mui/material";
import React, { FC } from "react";
import { BreadcrumbsSelectorInner } from "./BreadcrumbsSelectorInner";
import { useBreadcrumbsSelector } from "./useBreadcrumbsSelector";

export const BreadcrumbsSelector: FC = () => {
  const { initialSelectedValue, loading } = useBreadcrumbsSelector();
  return (
    <>
      {loading && <Skeleton variant="rectangular" className="h-10 rounded" />}
      {!loading && (
        <BreadcrumbsSelectorInner initialSelectedValue={initialSelectedValue} />
      )}
    </>
  );
};
