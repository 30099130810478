import { Button } from "components";
import { getQuestionDetails } from "pages/managerCareerPathForm/components/utils";
import React, { FC, Fragment } from "react";
import { FilteredQuestionsTableProps } from "../../FilteredQuestionsTable";
import { styles } from "../../filteredQuestionsTable.styles";

type MobileFilteredQuestionsTableProps = {
  subcategoriesToResolve?: Record<string, string>;
} & FilteredQuestionsTableProps;

export const MobileFilteredQuestionsTable: FC<
  MobileFilteredQuestionsTableProps
> = ({
  answersGroupedByQuestionId,
  answersGroupedByQuestionIdState,
  subcategories,
  handleManagerAnswer,
  subcategoriesToResolve,
}) => {
  return (
    <>
      {Object.keys(subcategoriesToResolve || {})?.length ? (
        <>
          {subcategories?.map(
            ({
              id,
              questions,
              name: subCategoryName,
              category: { name: categoryName },
            }) => {
              const showQuestions = !!subcategoriesToResolve?.[subCategoryName];
              return (
                <Fragment key={id}>
                  {showQuestions && (
                    <table className="w-full table-fixed">
                      <thead className="sticky top-0 bg-white ">
                        <tr>
                          <th className="flex items-center justify-center gap-2 border-b p-0 py-8">
                            <p className="text-lg font-semibold">
                              {categoryName}
                            </p>
                            <p>/</p>
                            <p className="text-sm font-medium">
                              {subCategoryName}
                            </p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {questions?.map(({ id, text }) => {
                          const {
                            isEmployeeYesAnswer,
                            isManagerYesAnswer,
                            giveAnswerArgs,
                            isFinalAnswer,
                            managerAnswer,
                          } = getQuestionDetails({
                            answersGroupedByQuestionId,
                            id,
                            answersGroupedByQuestionIdState,
                          });
                          return (
                            <Fragment key={id}>
                              {!isFinalAnswer && (
                                <tr className="flex flex-col gap-3 px-5 py-8 odd:bg-purple-7 even:bg-white">
                                  <td className="flex flex-col text-sm font-medium">
                                    <div className="flex flex-col gap-2">
                                      <p>{text}</p>
                                      <div className="mt-1 flex items-center justify-between">
                                        <p className="text-xs font-semibold uppercase">
                                          Employee
                                        </p>
                                        <p className="font-medium">
                                          {isEmployeeYesAnswer ? "Yes" : "No"}
                                        </p>
                                      </div>
                                      <div className="mb-3 flex items-center justify-between">
                                        <p className="text-xs font-semibold uppercase">
                                          Manager
                                        </p>
                                        <p className="font-medium">
                                          {isManagerYesAnswer ? "Yes" : "No"}
                                        </p>
                                      </div>
                                      <div className="flex gap-6">
                                        <Button
                                          variant="tertiary"
                                          className={styles.actionButton}
                                          onClick={() => {
                                            handleManagerAnswer({
                                              id,
                                              managerAnswer,
                                              shouldNotGiveAnswer: false,
                                              giveAnswerArgs: {
                                                ...giveAnswerArgs,
                                                finalAnswer: true,
                                              },
                                            });
                                          }}
                                        >
                                          Yes
                                        </Button>
                                        <Button
                                          variant="tertiary"
                                          className={styles.actionButton}
                                          onClick={() => {
                                            handleManagerAnswer({
                                              id,
                                              managerAnswer,
                                              shouldNotGiveAnswer: false,
                                              giveAnswerArgs: {
                                                ...giveAnswerArgs,
                                                finalAnswer: false,
                                              },
                                            });
                                          }}
                                        >
                                          No
                                        </Button>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </Fragment>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                </Fragment>
              );
            }
          )}
        </>
      ) : (
        <div className="flex items-center justify-center p-8 text-center">
          All questions has been successfully resolved
        </div>
      )}
    </>
  );
};
