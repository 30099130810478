import { gql, useQuery } from "@apollo/client";

interface Role {
  name: string;
}
export interface ErpEmployeeSkill {
  name: string;
  level: number;
}

export interface ErpEmployee {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  role: Role;
  skills: ErpEmployeeSkill[];
}

interface GetErpEmployeesData {
  employees: ErpEmployee[];
}

const GET_ERP_EMPLOYEES = gql`
  query {
    employees: get_erp_employees {
      id
      firstName
      lastName
      companyEmail
      role {
        name
      }
    }
  }
`;

const GET_ERP_EMPLOYEE = gql`
  query ($search: String) {
    employees: get_erp_employees(search: $search) {
      id
      firstName
      lastName
      companyEmail
      role {
        name
      }
      skills {
        name
        level
      }
    }
  }
`;

export const useErpEmployees = () =>
  useQuery<GetErpEmployeesData>(GET_ERP_EMPLOYEES, {
    variables: {},
  });

export const useErpEmployee = (firstName: string, lastName: string) =>
  useQuery<GetErpEmployeesData>(GET_ERP_EMPLOYEE, {
    variables: { search: `${firstName} ${lastName}` },
  });
