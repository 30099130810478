import { useEffect, useState } from "react";

// 768 - same as tailwind 'md' breakpoint
const getIsMobile = () => window.innerWidth < 768;

export function useIsMobile() {
  const [isMobile, setIsMobile] = useState(getIsMobile);

  useEffect(() => {
    const onResize = () => {
      setIsMobile(getIsMobile());
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return { isMobile };
}
