import { useMemo } from "react";
import moment from "moment";
import { gql, useQuery } from "@apollo/client";
import { Contract } from "types/employee";

type GetEmployeeContractsData = {
  contracts: Omit<Contract, "isIndefiniteDate">[];
};

type GetEmployeeContractsVars = {
  employeeId: string;
};

export const GET_EMPLOYEE_CONTRACTS = gql`
  query GetEmployeeContracts($employeeId: uuid!) {
    contracts(where: { employee_id: { _eq: $employeeId } }) {
      id
      employeeId: employee_id
      type
      workTime: work_time
      startDate: start_date
      endDate: end_date
      paidDaysOff: holidays_b2b
      noticePeriod: notice_period
      note
    }
  }
`;

export const useEmployeeContracts = (employeeId: string) => {
  const { data, loading, error } = useQuery<
    GetEmployeeContractsData,
    GetEmployeeContractsVars
  >(GET_EMPLOYEE_CONTRACTS, { variables: { employeeId } });

  const contracts = useMemo<Contract[]>(() => {
    return (
      data?.contracts.map((contract) => {
        const isIndefiniteDate =
          moment(contract.endDate).year() === 2099 || !contract.endDate;
        return { ...contract, isIndefiniteDate };
      }) ?? []
    );
  }, [data]);

  return { data: contracts, loading, error };
};
