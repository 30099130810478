import { gql, useQuery } from "@apollo/client";

interface Count {
  count: number;
}

interface Aggregated {
  aggregate: Count;
}

export interface GetAllManagerAnswersData {
  allAnswersCount: Aggregated;
  managerAnswersCount: Aggregated;
}

export const GET_MANAGER_ANSWERS_COUNT = gql`
  query ($employeeId: uuid) {
    allAnswersCount: employee_careers_aggregate(
      where: {
        career_path_question: {
          career_path_version: { current: { _eq: true } }
        }
        employee_id: { _eq: $employeeId }
        submitted_on: { _is_null: true }
      }
    ) {
      aggregate {
        count
      }
    }
    managerAnswersCount: employee_careers_aggregate(
      where: {
        career_path_question: {
          career_path_version: { current: { _eq: true } }
        }
        employee_id: { _eq: $employeeId }
        submitted_on: { _is_null: true }
        manager_answer: { _is_null: false }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const useAllManagerAnswersCheck = (employeeId: string) => {
  const { data, loading, error, refetch } = useQuery<
    GetAllManagerAnswersData | undefined
  >(GET_MANAGER_ANSWERS_COUNT, {
    variables: { employeeId },
    fetchPolicy: "no-cache",
  });
  const allAnswersCount = data?.allAnswersCount?.aggregate?.count;
  const managerAnswersCount = data?.managerAnswersCount?.aggregate?.count;
  return {
    isAllManagerAnswers: !loading && allAnswersCount === managerAnswersCount,
    loading,
    error,
    refetch,
  };
};
