const styles = {
  dropdownRoot: `
    md:border 
    md:border-grey-4 
    w-full
    md:w-auto
    py-2
    pl-4
    pr-3
    rounded 
    flex
    items-center
    justify-between
    outline-none
    text-purple-1 
    text-sm 
    bg-white`,
  filtersCounter: `
    flex 
    rounded-[50%] 
    w-full 
    justify-center
    min-w-5
    text-white 
    text-sm 
    w-auto
    font-semibold 
    bg-purple-2`,
};

export default styles;
