import { validate } from "validate.js";
import { TimesheetApprovalFormType } from "types/timesheets";

export const validator = (values: TimesheetApprovalFormType) => {
  const constraints = {
    billableTime: {
      datetime: { notValid: "^Billable hours are invalid" },
      presence: {
        allowEmpty: false,
        message: "^Billable hours can't be blank",
      },
    },
    nonBillableTime: {
      datetime: { notValid: "^Non-Billable hours are invalid" },
    },
  };

  const errors: Record<string, string>[] = [];
  values.timesheets.forEach((t) => {
    const timesheetErrors = validate(t, constraints);
    if (timesheetErrors) {
      errors.push(
        Object.keys(timesheetErrors).reduce((obj, currentItem) => {
          return { ...obj, [currentItem]: timesheetErrors[currentItem]?.[0] };
        }, {})
      );
    }
  });

  return { timesheets: errors };
};
