import React, { FC } from "react";
import { ExternalLinkIcon } from "resources/icons";

interface ExternalLinkProps {
  url: string;
  title: string;
  isIcon?: boolean;
  className?: string;
}

export const ExternalLink: FC<ExternalLinkProps> = ({
  url,
  title,
  isIcon = true,
  className,
}) => {
  return (
    <a
      href={url}
      rel="noreferrer"
      target="_blank"
      className={`${
        isIcon ? "flex items-center justify-center font-semibold" : ""
      } text-purple-3 hover:text-purple-4 hover:underline xs:text-sm ${
        className || ""
      }`}
    >
      {isIcon && <ExternalLinkIcon className="mr-2" />}
      <span>{title}</span>
    </a>
  );
};
