const styles = {
  tooltipContent: `
    flex
    bg-white
    text-purple-1
    p-2 
    text-xs 
    text-center
    rounded
    z-50 
    overflow-hidden 
    shadow-md 
    animate-in 
    fade-in-0 
    zoom-in-95 
    data-[state=closed]:animate-out 
    data-[state=closed]:fade-out-0 
    data-[state=closed]:zoom-out-95 
    data-[side=bottom]:slide-in-from-top-2 
    data-[side=left]:slide-in-from-right-4 
    data-[side=right]:slide-in-from-left-2 
    data-[side=top]:slide-in-from-bottom-2`,
};

export default styles;
