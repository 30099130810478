import { gql, useQuery } from "@apollo/client";
import { TimesheetType } from "types/timesheets";

export interface GetConfigData {
  config: Config;
  timesheetsLogsTypes: TimesheetType[];
}

export type Config = {
  TIMESHEETS_MAX_MINUTES: number;
  TIMESHEETS_MIN_MINUTES: number;
  TIMESHEETS_BORDER_TIME: string;
  TIMESHEETS_MAX_MINUTES_WITHOUT_EXCEPTION_DAY: number;
};

export const GET_CONFIG_QUERY = gql`
  query config {
    config {
      TIMESHEETS_MAX_MINUTES
      TIMESHEETS_MIN_MINUTES
      TIMESHEETS_BORDER_TIME
      TIMESHEETS_MAX_MINUTES_WITHOUT_EXCEPTION_DAY
    }
    timesheetsLogsTypes: timesheets_logs_type(
      where: {
        _or: [
          { value: { _eq: "standard_hours" } }
          { value: { _eq: "child_care_leave_hours" } }
          { value: { _eq: "child_care_leave_days" } }
          { value: { _eq: "holiday_leave" } }
          { value: { _eq: "holiday_leave_juvenile" } }
          { value: { _eq: "extended_leave_disability" } }
          { value: { _eq: "special_leave" } }
          { value: { _eq: "e_absence" } }
          { value: { _eq: "other_reason" } }
        ]
      }
    ) {
      value
      label
    }
  }
`;

export const useGetConfig = () => useQuery<GetConfigData>(GET_CONFIG_QUERY);
