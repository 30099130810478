const styles = {
  dropdownButton: `
    p-2 
    rounded 
    border 
    border-grey-1
    flex 
    gap-2
    flex-1
    overflow-hidden
    items-center`,
  dropdownArrow: `
    -rotate-90 
    [&>svg]:w-5 
    [&>svg]:h-5`,
  dropdownButtonLabel: `
    hidden 
    md:block 
    capitalize 
    text-xs 
    text-grey-1`,
  dropdownButtonItem: `
    font-semibold 
    overflow-hidden 
    text-ellipsis 
    whitespace-nowrap
    text-sm 
    text-grey-1`,
  dropdownAvatar: `
    w-6
    h-6
    text-xs`,
};
export default styles;
