import {
  Button,
  DateTimePickerControl,
  DateTimePickerControlProps,
  Drawer,
  Field,
  FieldError,
  Modal,
  TextControl,
} from "components";
import moment from "moment";
import React, { FC } from "react";
import { Form } from "react-final-form";
import { LoadingIcon } from "resources/icons";
import { FormCommonPropsType } from "../../../context";
import { TimesheetProjectInfo } from "../components";
import { WorkingHoursDuration } from "./components";
import { useTimesheetForm } from "./useTimesheetForm";
import { getCustomValidationErrors, validator } from "./validators/validators";
import { useGetContracts } from "hooks/useGetMyContracts/useGetMyContracts";

export const TimesheetForm: FC<FormCommonPropsType> = ({
  handleModalClose,
  formData,
}) => {
  const {
    handleDeleteTimesheet,
    handleSubmit,
    initialValues,
    MAX_MINUTES,
    modalRef,
    activeWeekTimesheets,
    timesheet,
    date,
    isUpsertTimesheetLoading,
    isDeleteTimesheetLoading,
    assignment,
  } = useTimesheetForm({ formData, handleModalClose });

  const { contracts } = useGetContracts();
  return (
    <Drawer
      isOpen
      onClose={handleModalClose}
      title={`${timesheet ? "Editing" : "Logging"} working hours`}
    >
      <div className="sm:h-full">
        <Form
          initialValues={initialValues}
          onSubmit={handleSubmit}
          keepDirtyOnReinitialize
          validate={validator}
        >
          {({
            handleSubmit,
            submitting,
            form,
            pristine,
            hasValidationErrors,
            values,
          }) => {
            const customErrors = getCustomValidationErrors({
              timesheet,
              date,
              timesheetValues: values,
              maxMinutes: MAX_MINUTES,
              activeWeekTimesheets,
              contracts: contracts
            });

            const showError = Boolean(
              customErrors.overlapping || (!pristine && customErrors.maxHours)
            );
            return (
              <form
                onSubmit={handleSubmit}
                className="flex h-full flex-col justify-between"
              >
                <div>
                  {!!assignment && (
                    <TimesheetProjectInfo name={assignment.project.name} />
                  )}
                  <p className="mb-5 text-sm font-medium">
                    {moment(date)?.format("dddd, D MMMM YYYY")}
                  </p>
                  <Field
                    name="taskId"
                    label="Task ID *"
                    component={TextControl}
                    data-cy="ts-task-id"
                  />
                  <Field
                    name="description"
                    label="Describe what you were working on *"
                    multiline
                    rows={5}
                    component={TextControl}
                    data-cy="ts-description"
                  />

                  {showError && (
                    <>
                      {customErrors.overlapping && (
                        <FieldError>{customErrors.overlapping}</FieldError>
                      )}
                      {!pristine && customErrors.maxHours && (
                        <FieldError>{customErrors.maxHours}</FieldError>
                      )}
                    </>
                  )}

                  <Field
                    name="startTime"
                    label="From *"
                    margin="dense"
                    component={DateTimePickerControl}
                    variant="time"
                    mask="__:__"
                    valueInitial={timesheet?.startTime}
                    dataCy="ts-from-date"
                  />
                  <WorkingHoursDuration />
                  <Field
                    name="endTime"
                    label="To *"
                    margin="dense"
                    variant="time"
                    mask="__:__"
                    valueInitial={timesheet?.endTime}
                    dataCy="ts-to-date"
                  >
                    {(props: DateTimePickerControlProps) => (
                      <DateTimePickerControl
                        {...props}
                        input={{
                          ...props?.input,
                          onChange: (e) => {
                            // on endTime change we need to reset duration to uncheck duration buttons
                            form.change("duration", undefined);
                            props?.input?.onChange(e);
                          },
                        }}
                      />
                    )}
                  </Field>
                </div>

                <div
                  className={`flex gap-2 ${
                    timesheet ? "justify-between" : "justify-end"
                  }`}
                >
                  {timesheet && (
                    <Button
                      variant="secondary"
                      aria-label="delete work log"
                      className="flex min-w-18 items-center justify-center"
                      onClick={() => {
                        modalRef.current?.openModal();
                      }}
                      disabled={isDeleteTimesheetLoading || submitting}
                      data-cy="ts-delete-button"
                    >
                      {isDeleteTimesheetLoading ? (
                        <LoadingIcon className="animate-spin" />
                      ) : (
                        "Delete"
                      )}
                    </Button>
                  )}

                  <div className="flex gap-2">
                    <Button
                      variant="secondary"
                      data-cy="ts-cancel-button"
                      onClick={handleModalClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="primary"
                      className="flex min-w-18 items-center justify-center"
                      type="submit"
                      disabled={
                        submitting ||
                        pristine ||
                        hasValidationErrors ||
                        // custom errors
                        !!customErrors.overlapping ||
                        !!customErrors.maxHours
                      }
                      data-cy="ts-submit-button"
                    >
                      {isUpsertTimesheetLoading ? (
                        <LoadingIcon className="animate-spin" />
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </div>
                </div>
                <Modal
                  ref={modalRef}
                  onSubmit={() => {
                    modalRef.current?.closeModal();
                    handleDeleteTimesheet();
                  }}
                  contentMessage="Delete your logged hours."
                />
              </form>
            );
          }}
        </Form>
      </div>
    </Drawer>
  );
};
