import { Button } from "components";
import React from "react";
import { Arrow2Icon } from "resources/icons";
import { styles } from "./PageChangeBase.styles";
import { transformPageOptions } from "./utils/utils";

type PageChangeBaseProps = {
  currentPage: number;
  pageOptions: number[];
  isNextDisabled: boolean;
  isPrevDisabled: boolean;
  goToPage: (index: number) => void;
  goToNextPage: () => void;
  goToPrevPage: () => void;
};

export const PageChangeBase = ({
  currentPage,
  pageOptions,
  isNextDisabled,
  isPrevDisabled,
  goToPage,
  goToNextPage,
  goToPrevPage,
}: PageChangeBaseProps) => {
  const preparedPageOptions = transformPageOptions(pageOptions, currentPage);
  return (
    <div className="flex gap-2">
      <Button
        className={styles.arrowButton}
        aria-label="go to previous page"
        onClick={goToPrevPage}
        disabled={isPrevDisabled}
      >
        <span>
          <Arrow2Icon />
        </span>
      </Button>

      <div className="flex items-center gap-2">
        {preparedPageOptions.map((option, index) => {
          const isPage = typeof option === "number";
          return isPage ? (
            <Button
              key={option}
              aria-label={`go to page ${option}`}
              onClick={() => goToPage(option)}
              className={`${styles.pageNumber} ${
                currentPage === option && "bg-grey-5 font-semibold"
              }`}
            >
              {option + 1}
            </Button>
          ) : (
            <div key={option + index}>{option}</div>
          );
        })}
      </div>
      <Button
        className={styles.arrowButton}
        aria-label="go to next page"
        onClick={goToNextPage}
        disabled={isNextDisabled}
      >
        <span className="[&>svg]:rotate-180">
          <Arrow2Icon />
        </span>
      </Button>
    </div>
  );
};
