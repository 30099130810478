import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormControlProps,
  FormGroup,
  FormHelperText,
} from "@mui/material";
import { FieldError } from "components";
import React, { FC } from "react";
import { FieldRenderProps } from "react-final-form";

type CheckboxControlProps = FieldRenderProps<string, HTMLElement> & {
  label?: string;
  FormLabelProps?: FormControlLabelProps;
  FormControlProps?: FormControlProps;
  helperText?: string;
  dataCy?: string;
};

export const CheckboxControl: FC<CheckboxControlProps> = ({
  input: { checked, name, onChange, ...restInput },
  meta: { submitError, dirtySinceLastSubmit, error: metaError, touched },
  label = "",
  FormLabelProps,
  FormControlProps,
  helperText,
  dataCy,
  ...restCheckbox
}) => {
  const error = metaError;
  const showError = Boolean(
    touched && ((submitError && !dirtySinceLastSubmit) || error)
  );

  return (
    <div data-cy={dataCy}>
      <FormControl error={showError} {...FormControlProps}>
        <FormGroup>
          <FormControlLabel
            htmlFor={name}
            label={label}
            {...FormLabelProps}
            classes={{ label: "text-purple-1 font-sans text-sm" }}
            control={
              <Checkbox
                id={name}
                name={name}
                inputProps={restInput}
                onChange={onChange}
                checked={checked}
                {...restCheckbox}
                classes={{
                  root: "hover:bg-purple-4/5",
                  checked: "!text-purple-4",
                }}
              />
            }
          />
        </FormGroup>
        {showError && <FieldError>{error || submitError}</FieldError>}
      </FormControl>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </div>
  );
};
