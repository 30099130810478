import React, { FC } from "react";
import { getProjectIcon } from "utils/timesheets.utils";

type Props = {
  name: string;
};

export const TimesheetProjectInfo: FC<Props> = ({ name }) => {
  const Icon = getProjectIcon();

  return (
    <div className="mb-6 flex items-center">
      <div className="mr-4 h-12 w-12">
        <Icon />
      </div>
      <div className="flex flex-col justify-center">
        <p className="mb-1 text-xs text-purple-1">PROJECT</p>
        <p className="text-sm font-semibold text-purple-1">{name}</p>
      </div>
    </div>
  );
};
