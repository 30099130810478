import { QueryHookOptions, gql, useQuery } from "@apollo/client";
import { CareerPathVersion } from "types/career-path";

export interface GetCareerPathVersionsData {
  versions: CareerPathVersion[];
}

export const GET_CAREER_PATH_VERSIONS = gql`
  query careerPathVersions {
    versions: career_path_versions {
      id
      current
      version
    }
  }
`;

export const useGetCareerPathVersions = (
  options?: QueryHookOptions<GetCareerPathVersionsData>
) => useQuery<GetCareerPathVersionsData>(GET_CAREER_PATH_VERSIONS, options);
