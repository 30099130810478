const styles = {
  titleContainer: `
    w-full
    text-center
  `,
  mobileArrow: `
    w-full
    pl-5
  `,
  mobileTitleContainer: `
    w-full
    pb-6
  `,
  title: `
    text-white
    font-bold
    text-center
  `,
  arrowButton: `
    h-5
    w-5
    -mr-1.5
    rounded-full
    p-0 
    text-center
    [&>svg>path]:stroke-white
    [&>svg>path]:stroke-3`,
  container: `
    pt-10
    h-auto 
    pb-4 
    overflow-y-auto 
    bg-purple-2`,
};

export default styles;
