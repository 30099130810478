import React, { FC, PropsWithChildren } from "react";
import { DropzoneOptions, useDropzone } from "react-dropzone";

export type DragAndDropInputProps = {
  dropzoneOptions?: DropzoneOptions;
};
export const DragAndDropInput: FC<PropsWithChildren<DragAndDropInputProps>> = ({
  dropzoneOptions,
  children,
}) => {
  const { getRootProps, getInputProps, isDragActive } =
    useDropzone(dropzoneOptions);

  return (
    <>
      <div
        {...getRootProps()}
        className="flex min-h-60 items-center justify-center rounded border-2 border-dashed bg-grey-7 p-4 text-sm"
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the file here ...</p>
        ) : (
          <p>Drag &apos;n&apos; drop a file here, or click to select a file</p>
        )}
      </div>
      {children}
    </>
  );
};
