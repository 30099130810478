import { useUpsertTimesheetExceptionDay } from "hooks";
import moment from "moment";
import {
  FormCommonPropsType,
  formInitialData,
  useTimeSheetsWeekContext,
} from "pages/timesheets/context";
import { useCallback } from "react";
import { ExceptionDayFormType } from "types/timesheets";

type UseExceptionDayFormProps = FormCommonPropsType;

export const useExceptionDayForm = ({
  formData = formInitialData,
  handleModalClose,
}: UseExceptionDayFormProps) => {
  const { exceptionDay, date } = formData;
  const isExceptionDayEnabled = Boolean(exceptionDay?.enabled);

  const initialValues = {
    reason: exceptionDay?.reason ?? "",
  };

  const { activeWeek, employeeId, activeMonth } = useTimeSheetsWeekContext();
  const { upsertExceptionDay } = useUpsertTimesheetExceptionDay({
    activeWeek,
    employeeId,
    activeMonth,
  });

  const handleSubmit = useCallback(
    async ({ reason }: ExceptionDayFormType) => {
      upsertExceptionDay({
        day: moment(date).format("YYYY-MM-DD"),
        employeeId,
        reason,
        ...(exceptionDay
          ? {
              id: exceptionDay.id,
              enabled: !exceptionDay.enabled,
            }
          : { enabled: true }),
      });
      handleModalClose();
    },
    [date, exceptionDay, employeeId, handleModalClose, upsertExceptionDay]
  );

  return {
    handleSubmit,
    initialValues,
    isExceptionDayEnabled,
  };
};
