import { useGetAllRoleQuestions, useUpsertManagerAnswer } from "hooks";
import { ManagerSurveyProps } from "pages/managerCareerPathForm/components/managerSurvey/ManagerSurvey";
import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { EmployeeCareerPath, QueryParams } from "types/career-path";

export type GiveManagerAnswerArgs = {
  questionId: string;
  managerAnswer: boolean;
  finalAnswer?: boolean | null;
  id?: string;
};

export type AnswersGroupedByQuestionIdType = Record<
  string, // questionId
  Pick<
    EmployeeCareerPath,
    "id" | "questionId" | "finalAnswer" | "answer" | "managerAnswer"
  >
>;

export type HandleSetAnswersGroupedByQuestionIdStateType = (
  props: Pick<EmployeeCareerPath, "id" | "managerAnswer" | "finalAnswer">
) => void;

export type HandleManagerAnswerType = (props: {
  giveAnswerArgs: Omit<
    GiveManagerAnswerArgs,
    "managerAnswer" | "currentManagerAnswer"
  >;
  id: string;
  shouldNotGiveAnswer: boolean;
  managerAnswer?: boolean;
  finalAnswer?: boolean | null;
}) => void;

export type GiveAnswerType = (props: GiveManagerAnswerArgs) => Promise<void>;

export enum ANSWER_TYPE {
  FINAL_ANSWER = "finalAnswer",
  MANAGER_ANSWER = "managerAnswer",
}

type UseQuestionsActionsProps = {
  answerType: ANSWER_TYPE;
} & Pick<ManagerSurveyProps, "answers" | "employeeData">;

export const useQuestionsActions = ({
  answers,
  employeeData,
  answerType,
}: UseQuestionsActionsProps) => {
  const { employeeId } = useParams<QueryParams>();
  const { data } = useGetAllRoleQuestions();
  const { upsertManagerAnswer } = useUpsertManagerAnswer();

  const answersGroupedByQuestionId = useMemo<AnswersGroupedByQuestionIdType>(
    () =>
      answers?.reduce<AnswersGroupedByQuestionIdType>(
        (acc, { questionId, managerAnswer, id, finalAnswer, answer }) => ({
          ...acc,
          [questionId]: { managerAnswer, id, finalAnswer, answer, questionId },
        }),
        {}
      ) || {},
    [answers]
  );
  const [answersGroupedByQuestionIdState, setAnswersGroupedByQuestionIdState] =
    useState<AnswersGroupedByQuestionIdType>(answersGroupedByQuestionId);

  const isFormCompleted = useMemo(
    () =>
      Object.keys({
        ...data?.GENERAL,
        ...(employeeData?.employee?.role?.categoryRole && {
          ...data?.[employeeData?.employee?.role?.categoryRole],
        }),
      })?.every(
        (key) => answersGroupedByQuestionId?.[key]?.[answerType] !== null
      ) || false,
    [
      answerType,
      answersGroupedByQuestionId,
      data,
      employeeData?.employee?.role?.categoryRole,
    ]
  );

  const giveAnswer = useCallback<GiveAnswerType>(
    async ({ questionId, id, managerAnswer, finalAnswer }) => {
      await upsertManagerAnswer({
        id,
        employeeId,
        questionId,
        managerAnswer,
        finalAnswer,
      });
    },
    [upsertManagerAnswer, employeeId]
  );

  const handleSetAnswersGroupedByQuestionIdState =
    useCallback<HandleSetAnswersGroupedByQuestionIdStateType>(
      ({ id, managerAnswer, finalAnswer }) =>
        setAnswersGroupedByQuestionIdState((prev) => ({
          ...prev,
          ...(id &&
            prev?.[id] && {
              [id]: {
                ...prev[id],
                finalAnswer,
                managerAnswer,
              },
            }),
        })),
      []
    );

  const handleManagerAnswer = useCallback<HandleManagerAnswerType>(
    ({
      shouldNotGiveAnswer,
      id,
      giveAnswerArgs,
      managerAnswer,
      finalAnswer,
    }) => {
      handleSetAnswersGroupedByQuestionIdState({
        id,
        managerAnswer,
        finalAnswer,
      });
      !shouldNotGiveAnswer &&
        giveAnswer({
          ...giveAnswerArgs,
          managerAnswer: managerAnswer!,
        });
    },
    [giveAnswer, handleSetAnswersGroupedByQuestionIdState]
  );

  return {
    answersGroupedByQuestionIdState,
    answersGroupedByQuestionId,
    isFormCompleted,
    handleManagerAnswer,
  };
};
