import { TableState } from "@tanstack/react-table";
import { FILTERS_NAME, FILTERS_OPENED } from "config/constants";
import { TABLE_ENUM } from "config/table-const";
import { useLocalStorage } from "hooks";
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from "react";

type Filters = Partial<Record<TABLE_ENUM, Partial<TableState>>>;
type FiltersOpened = Partial<Record<TABLE_ENUM, boolean>>;

interface IConfigContext {
  filters: Filters;
  changeFilters: (state: TableState, name: TABLE_ENUM) => void;
  filtersOpened: FiltersOpened;
  changeFiltersOpened: (isOpen: boolean, name: TABLE_ENUM) => void;
}

const ConfigContext = createContext<IConfigContext>({} as IConfigContext);

const ConfigContextProvider = ({ children }: PropsWithChildren) => {
  const [filters, setFilters] = useLocalStorage<Filters>(FILTERS_NAME, {});
  const [filtersOpened, setFiltersOpened] = useLocalStorage<FiltersOpened>(
    FILTERS_OPENED,
    {}
  );

  const changeFilters = useCallback(
    (state: TableState, name: TABLE_ENUM) => {
      // eslint-disable-next-line
      const { columnVisibility, columnSizingInfo, columnSizing, ...restState } =
        state;
      filters[name] = restState;
      setFilters(filters);
    },
    [filters, setFilters]
  );

  const changeFiltersOpened = useCallback(
    (isOpen: boolean, name: TABLE_ENUM) => {
      filtersOpened[name] = isOpen;
      setFiltersOpened(filtersOpened);
    },
    [filtersOpened, setFiltersOpened]
  );

  const context = useMemo(
    () => ({
      filters,
      filtersOpened,
      changeFiltersOpened,
      changeFilters,
    }),
    [filters, changeFilters, changeFiltersOpened, filtersOpened]
  );

  return (
    <ConfigContext.Provider value={context}>{children}</ConfigContext.Provider>
  );
};

const useConfigContext = () => {
  const context = useContext(ConfigContext);
  if (context === undefined) {
    throw new Error(
      "useConfigContext must be used within a ConfigContextProvider"
    );
  }
  return context;
};

export { ConfigContextProvider, useConfigContext };
