import React, { FC } from "react";
import { NoObjectivesIcon } from "resources/icons";

export const EmptyState: FC = () => {
  return (
    <span className="p-5 flex flex-col gap-10 items-center text-center sm:my-14">
      <span className="flex justify-center items-center">
        <NoObjectivesIcon />
      </span>
      <span className="xs:max-w-75 flex flex-col gap-3 text-sm text-purple-2">
        <p className="font-semibold">No results found</p>
        <p>
          Check your spelling and filter options, or search for a different
          keyword.
        </p>
      </span>
    </span>
  );
};
