import { gql, useQuery } from "@apollo/client";
import { useAuthContext } from "contexts";
import { EmployeeCareerPath } from "types/career-path";

export interface GetAnswersData {
  answers: EmployeeCareerPath[];
}

export interface GetAnswersVars {
  employeeId: string;
  isManager?: boolean;
}

export const GET_ANSWERS = gql`
  query getAnswers($employeeId: uuid, $isManager: Boolean!) {
    answers: employee_careers(
      where: {
        career_path_question: {
          career_path_version: { current: { _eq: true } }
        }
        employee_id: { _eq: $employeeId }
        submitted_on: { _is_null: true }
      }
      order_by: { career_path_question: { career_path_level: { value: asc } } }
    ) {
      id
      questionId: question_id
      employeeId: employee_id
      answer
      finalAnswer: final_answer
      managerAnswer: manager_answer @include(if: $isManager)
      question: career_path_question {
        id
        text
        subcategoryId: subcategory_id
        career_path_subcategory {
          name
        }
        level: career_path_level {
          id
          value
        }
      }
    }
  }
`;

interface Props {
  employeeId: string;
}

export const useGetAnswers = ({ employeeId }: Props) => {
  const { isManager } = useAuthContext();
  return useQuery<GetAnswersData, GetAnswersVars>(GET_ANSWERS, {
    variables: { employeeId, isManager: isManager() },
    fetchPolicy: isManager() ? "cache-first" : "no-cache",
  });
};
