import jwtDecode from "jwt-decode";
import {
  AUTH0_CALIMS_NAME,
  AUTH_TOKEN_NAME,
  USER_ROLES_ENUM,
} from "../config/constants";
import { IDecodedToken } from "../contexts/auth/Auth.context";

export const hexToRgb = (hex: any, opacity: number) => {
  const rgbList = hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m: number, r: number, g: number, b: number) =>
        "#" + r + r + g + g + b + b
    )
    .substring(1)
    .match(/.{2}/g)
    .map((x: string) => parseInt(x, 16));
  return `rgba(${rgbList[0]}, ${rgbList[1]}, ${rgbList[2]}, ${opacity})`;
};

export const tokenIsManager = () => {
  const token = localStorage.getItem(AUTH_TOKEN_NAME) || null;
  let isManager = false;
  if (token) {
    const decodedToken: IDecodedToken = jwtDecode(token);
    if (decodedToken) {
      const hasuraClaims = decodedToken[AUTH0_CALIMS_NAME] || {};
      const role = hasuraClaims["x-hasura-default-role"];
      if (role === USER_ROLES_ENUM.MANAGER) {
        isManager = true;
      }
    }
  }
  return isManager;
};

export const downloadBlob = (blob: Blob | null, filename: string) => {
  if (!blob) {
    return;
  }
  const element = document.createElement("a");
  const url = window.URL.createObjectURL(blob);
  element.setAttribute("href", url);
  element.setAttribute("download", filename);
  element.setAttribute("hidden", "hidden");
  element.click();
  window.URL.revokeObjectURL(url);
};
