import { PageChangeBase } from "components";
import React from "react";
import { PaginationProps } from "../../Pagination";

type PageChangeProps<D extends object> = Pick<PaginationProps<D>, "table">;

export const PageChange = <D extends object>({ table }: PageChangeProps<D>) => {
  const currentPage = table.getState().pagination.pageIndex;
  return (
    <PageChangeBase
      currentPage={currentPage}
      isNextDisabled={!table.getCanNextPage()}
      isPrevDisabled={!table.getCanPreviousPage()}
      goToNextPage={() => table.nextPage()}
      goToPage={(page) => table.setPageIndex(page)}
      goToPrevPage={() => table.previousPage()}
      pageOptions={table.getPageOptions()}
    />
  );
};
