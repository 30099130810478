import { Button, Drawer, UserAvatar } from "components";
import React, { FC } from "react";
import { TimesheetStateType } from "types/timesheets";
import { FormCommonPropsType } from "../../../context";
import { DayOffInfo, TimesheetInfo, TimesheetProjectInfo } from "../components";

export const TimesheetPreview: FC<FormCommonPropsType> = ({
  handleModalClose,
  formData,
}) => {
  const timesheet = formData?.timesheets?.[0];
  const isDayOff = timesheet?.logType !== "standard_hours";
  const isDeclined = timesheet?.state === TimesheetStateType.DECLINED;

  return (
    <Drawer
      isOpen
      onClose={handleModalClose}
      title={isDayOff ? "Absence" : "Working hours"}
    >
      <div className="flex h-full flex-col justify-between">
        <div>
          {isDeclined ? (
            <div className="mb-6 rounded bg-red-3 p-4">
              <span className="mb-2 flex items-center">
                <UserAvatar
                  picture={timesheet?.manager?.picture}
                  firstName={timesheet?.manager?.firstName || ""}
                  lastName={timesheet?.manager?.lastName || ""}
                  styles="w-6 h-6 rounded-full text-sm"
                />

                <p className="ml-2 text-sm font-medium">
                  {(timesheet?.manager?.lastName &&
                    timesheet?.manager?.firstName) ||
                    timesheet?.manager?.email}
                </p>
              </span>
              <p className="text-sm">{timesheet?.stateInfo}</p>
            </div>
          ) : null}

          {!!timesheet?.assignment && (
            <TimesheetProjectInfo name={timesheet.assignment.project.name} />
          )}
          {!!timesheet && (
            <>
              {isDayOff ? (
                <DayOffInfo dayOff={timesheet} />
              ) : (
                <TimesheetInfo timesheet={timesheet} />
              )}
            </>
          )}
        </div>
        <Button
          variant="primary"
          onClick={handleModalClose}
          data-cy="ts-submit-button"
        >
          Ok
        </Button>
      </div>
    </Drawer>
  );
};
