import { FORM_INTERLUDE_SCREEN_TYPES } from "config/general"
import React, { FC } from "react"
import styles from "./FormInterlude.styles"
import { CareerPathFormBar } from "../formBar/FormBar"

interface Props {
  screenType: number
  handleNext: () => void
  handleBack: () => void
}

export const CareerPathFormInterlude: FC<Props> = ({ screenType, handleNext, handleBack }) => {
  const screen = FORM_INTERLUDE_SCREEN_TYPES[screenType]
  return (
    <div className={styles.background}>
      <div className={styles.container}>
        <p className="uppercase text-xs font-bold text-acai-dark-purple-3 mb-8">Did you know...</p>
        <div className="mb-8 mx-auto border-2 rounded max-w-sm border-acai-dark-purple-4">
          <img src={screen.img} alt="" />
        </div>

        <p className="text-base font-bold text-acai-dark-purple-3 mb-6">{screen.title}</p>
        <p className="text-sm text-neutral-n3 mb-10">{screen.subtitle}</p>

        <CareerPathFormBar
          {...{ handleNext, handleBack }}
          isBackDisabled={false}
          isNextDisabled={false}
          withWrapper={false}
        />
      </div>
    </div>
  )
}
