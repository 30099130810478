import { Button } from "components";
import React, { FC } from "react";
import { EyeCloseIcon, EyeOpenIcon } from "resources/icons";
import { getQuestionDetails } from "../../../../../utils";
import { QuestionsTableProps } from "../../QuestionsTable";
import { getAnswerButtonStyle } from "../../questionsTable.styles";

type DesktopQuestionsTableProps = QuestionsTableProps;

export const DesktopQuestionsTable: FC<DesktopQuestionsTableProps> = ({
  answersGroupedByQuestionId,
  currentSubCategory,
  answersGroupedByQuestionIdState,
  questionsVisibility,
  toggleQuestionsAnswersVisibility,
  handleManagerAnswer,
}) => {
  return (
    <table className="table-auto">
      <tbody>
        {currentSubCategory?.questions?.map(({ id, text }) => {
          const {
            isEmployeeNoAnswer,
            isEmployeeYesAnswer,
            isManagerNoAnswer,
            isManagerYesAnswer,
            giveAnswerArgs,
          } = getQuestionDetails({
            answersGroupedByQuestionId,
            id,
            answersGroupedByQuestionIdState,
          });
          const isAnswerVisible = questionsVisibility?.[id];
          return (
            <tr className="odd:bg-purple-7 mb-6 h-20  min-w-full" key={id}>
              <td className="w-auto py-4 pl-12 pr-3 text-sm font-medium">
                {text}
              </td>
              <td className="px-3 py-4">
                <Button
                  className="p-0"
                  onClick={() => toggleQuestionsAnswersVisibility(id)}
                  aria-label={
                    isAnswerVisible
                      ? "hide employee's answer"
                      : "show employee's answer"
                  }
                >
                  {isAnswerVisible ? <EyeCloseIcon /> : <EyeOpenIcon />}
                </Button>
              </td>
              <td className="px-3 py-4">
                <Button
                  variant={isManagerYesAnswer ? "primary" : "tertiary"}
                  className={getAnswerButtonStyle(
                    isAnswerVisible,
                    isEmployeeYesAnswer,
                    isManagerYesAnswer
                  )}
                  onClick={() =>
                    handleManagerAnswer({
                      id,
                      managerAnswer: true,
                      giveAnswerArgs: {
                        ...giveAnswerArgs,
                        finalAnswer: isEmployeeYesAnswer
                          ? true
                          : isEmployeeNoAnswer
                          ? null
                          : giveAnswerArgs?.finalAnswer,
                      },
                      shouldNotGiveAnswer: isManagerYesAnswer,
                    })
                  }
                >
                  Yes
                </Button>
              </td>
              <td className="py-4 pl-3 pr-12">
                <Button
                  variant={isManagerNoAnswer ? "primary" : "tertiary"}
                  className={getAnswerButtonStyle(
                    isAnswerVisible,
                    isEmployeeNoAnswer,
                    isManagerNoAnswer
                  )}
                  onClick={() =>
                    handleManagerAnswer({
                      id,
                      managerAnswer: false,
                      giveAnswerArgs: {
                        ...giveAnswerArgs,
                        finalAnswer: isEmployeeNoAnswer
                          ? false
                          : isEmployeeYesAnswer
                          ? null
                          : giveAnswerArgs?.finalAnswer,
                      },
                      shouldNotGiveAnswer: isManagerNoAnswer,
                    })
                  }
                >
                  No
                </Button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
