const styles = {
  list: `
    sm:max-h-60 
    xs:flex-1 
    xs:basis-0
    overflow-auto
    py-2`,
  listOption: `
    text-sm 
    flex
    items-center
    text-ellipsis
    overflow-auto
    px-4 
    py-2 
    hover:cursor-pointer
    hover:bg-purple-7`,
};

export default styles;
