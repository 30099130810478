import { gql, useQuery } from "@apollo/client";

export type GetAbsencesInfoData = {
  employee_absence_limits: Array<{
    limit: number;
    logType: string;
    unaccountedAbsences: number;
    year: number;
  }>;
};

export const GET_ABSENCES_INFO = gql`
  query getMyAbsencesInfo {
    employee_absence_limits {
     limit
     logType: log_type
     unaccountedAbsences: unaccounted_absences
     year
    }
  }
`;

export const useGetAbsencesInfo = () =>
  useQuery<GetAbsencesInfoData>(
    GET_ABSENCES_INFO
  );
