import {
  AnswersGroupedByQuestionIdType,
  GiveManagerAnswerArgs,
} from "pages/managerCareerPathForm/hooks";

export const getQuestionDetails = ({
  answersGroupedByQuestionId,
  answersGroupedByQuestionIdState,
  id,
}: {
  answersGroupedByQuestionId: AnswersGroupedByQuestionIdType;
  answersGroupedByQuestionIdState: AnswersGroupedByQuestionIdType;
  id: string;
}): {
  giveAnswerArgs: Omit<
    GiveManagerAnswerArgs,
    "managerAnswer" | "currentManagerAnswer"
  >;
} & Record<
  | "isEmployeeNoAnswer"
  | "isEmployeeYesAnswer"
  | "isManagerYesAnswer"
  | "isManagerNoAnswer"
  | "isFinalAnswer"
  | "isFinalYesAnswer"
  | "isFinalNoAnswer",
  boolean
> & {
    managerAnswer?: boolean;
    finalAnswer?: boolean | null;
  } => {
  const pathAnswer = answersGroupedByQuestionId?.[id];
  const pathAnswerState = answersGroupedByQuestionIdState?.[id];
  const managerAnswer =
    pathAnswerState?.managerAnswer || pathAnswer?.managerAnswer;
  const employeeAnswer = pathAnswerState?.answer || pathAnswer?.answer;
  const finalAnswer = pathAnswerState?.finalAnswer || pathAnswer?.finalAnswer;

  const isFinalYesAnswer = finalAnswer === true;
  const isFinalNoAnswer = finalAnswer === false;
  const isManagerYesAnswer = managerAnswer === true;
  const isManagerNoAnswer = managerAnswer === false;
  const isEmployeeYesAnswer = employeeAnswer === true;
  const isEmployeeNoAnswer = employeeAnswer === false;

  const giveAnswerArgs: Omit<
    GiveManagerAnswerArgs,
    "managerAnswer" | "currentManagerAnswer"
  > = {
    questionId: id,
    id: pathAnswer?.id || pathAnswerState?.id,
    finalAnswer: finalAnswer,
  };
  const isFinalAnswer = finalAnswer !== null ? true : false;
  return {
    giveAnswerArgs,
    isEmployeeNoAnswer,
    isEmployeeYesAnswer,
    isManagerYesAnswer,
    isManagerNoAnswer,
    isFinalAnswer,
    isFinalYesAnswer,
    isFinalNoAnswer,
    managerAnswer,
    finalAnswer,
  };
};
