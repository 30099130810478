import {
  ApolloError,
  gql,
  MutationHookOptions,
  useMutation,
} from "@apollo/client";
import { GET_TICKETS } from "hooks/useTickets/useTickets";
import { useCallback } from "react";
import { toast } from "react-toastify";

type AssignTicketData = {
  response: { id: string };
};

type AssignTicketVars = {
  ticketId: string;
  managerId: string;
};

const ASSIGN_TICKET = gql`
  mutation AssignTicket($ticketId: UUID!, $managerId: UUID!) {
    response: assign_manager_task(id: $ticketId, assigned_to: $managerId) {
      id
    }
  }
`;

export const useAssignTicket = (
  options?: MutationHookOptions<AssignTicketData, AssignTicketVars>
) => {
  const [assignTicketMutation, data] = useMutation<
    AssignTicketData,
    AssignTicketVars
  >(ASSIGN_TICKET, { refetchQueries: [{ query: GET_TICKETS }], ...options });

  const assignTicket = useCallback(
    async (values: AssignTicketVars) => {
      try {
        await assignTicketMutation({ variables: values });
      } catch (ex) {
        toast.error((ex as ApolloError)?.message);
        throw ex;
      }
    },
    [assignTicketMutation]
  );

  return { assignTicket, ...data };
};
