import { Button } from "components";
import moment from "moment";
import React, { FC, useCallback } from "react";
import { useForm, useFormState } from "react-final-form";
import { TimesheetFormType } from "types/timesheets";

type DurationTime = {
  minutes: number;
  hours: number;
};

type Item = {
  label: string;
  value: number;
};

type WorkingHoursDurationProps = {
  hoursItems?: Item[];
  minutesItems?: Item[];
};

export const WorkingHoursDuration: FC<WorkingHoursDurationProps> = ({
  hoursItems = [
    { label: "0 h", value: 0 },
    { label: "1 h", value: 1 },
    { label: "2 h", value: 2 },
    { label: "3 h", value: 3 },
    { label: "4 h", value: 4 },
  ],
  minutesItems = [
    { label: "0 min", value: 0 },
    { label: "30 min", value: 30 },
  ],
}) => {
  const { change } = useForm<TimesheetFormType>();
  const { values } = useFormState<TimesheetFormType>();

  const handleClick = useCallback(
    (durationTime: Partial<DurationTime>) => {
      change("duration", { ...values?.duration, ...durationTime });
      change(
        "endTime",
        moment(values?.startTime)
          .add(durationTime?.hours ?? values?.duration?.hours, "h")
          .add(durationTime?.minutes ?? values?.duration?.minutes, "m")
          .toISOString()
      );
    },
    [change, values?.duration, values?.startTime]
  );

  return (
    <>
      <p className="mb-2 font-sans text-sm text-purple-1">Duration:</p>
      <div className="flex justify-between gap-6">
        <div className="flex flex-1 flex-col gap-4">
          {hoursItems.map(({ label, value }) => {
            return (
              <Button
                variant="duration"
                className={
                  values?.duration?.hours === value
                    ? "bg-purple-4 text-white"
                    : "hover:bg-grey-6"
                }
                key={label + value}
                onClick={() => handleClick({ hours: value })}
              >
                {label}
              </Button>
            );
          })}
        </div>
        <div className="min-h-full rounded-full border bg-black" />
        <div className="flex flex-1 flex-col gap-4">
          {minutesItems.map(({ label, value }) => {
            return (
              <Button
                variant="duration"
                className={
                  values?.duration?.minutes === value
                    ? "bg-purple-4 text-white"
                    : "hover:bg-grey-6"
                }
                key={label + value}
                onClick={() => handleClick({ minutes: value })}
              >
                {label}
              </Button>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default WorkingHoursDuration;
