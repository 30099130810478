const styles = {
  aside: `
    z-40 
    w-57.5
    `,
  asideMobile: `
    backdrop-blur
    z-40 
    w-full  
    `,
};

export default styles;
