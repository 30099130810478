export const styles: Record<
  "selectButton" | "selectDropdown" | "selectDropdownOption",
  string
> = {
  selectButton: `
    border 
    py-1 
    px-2
    rounded 
    flex
    items-center
    justify-between
    outline-none
    text-purple-1 
    text-sm 
    min-w-18
    bg-white`,
  selectDropdown: `
    min-w-18
    rounded
    border
    border-grey-3
    shadow-[0px 5px 30px 0px rgba(30, 7, 58, 0.05)]
    bg-white
    w-full 
    py-2
    mb-2
    text-purple-1`,
  selectDropdownOption: `
    text-sm 
    px-4 
    py-2 
    hover:cursor-pointer
    hover:bg-purple-7`,
};
