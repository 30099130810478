export const getAnswerButtonStyle = (
  isAnswerVisible: boolean,
  isEmployeeAnswer: boolean,
  isManagerAnswer: boolean
): string =>
  `p-2 border xs:grow outline outline-4 min-w-18
    ${
      isAnswerVisible && isEmployeeAnswer
        ? "outline-green-2 border-green-2"
        : "outline-transparent border-grey-2"
    } ${isManagerAnswer && "border-purple-1 hover:border-purple-3"}`;
