import { useIsMobile } from "hooks";
import React, { FC, useMemo } from "react";
import { Category, Subcategory } from "types/career-path";
import { groupSubcategories } from "utils/form.utils";
import { FormProgressItem } from "./components/ProgressItem";
import { FormProgressItemCategory } from "./components/ProgressItemCategory";

export interface Props {
  activeSubcategory: Subcategory | undefined;
  isFormCompleted: boolean;
  categories: Category[];
  subcategories: Subcategory[];
  unresolvedQuestionsBySubcategory?: Record<string, number>;
  disableProgress?: boolean;
}

export const CareerPathFormProgress: FC<Props> = ({
  activeSubcategory,
  isFormCompleted,
  categories,
  subcategories,
  unresolvedQuestionsBySubcategory,
  disableProgress = false,
}) => {
  const filteredCategories = useMemo(
    () =>
      categories.filter(({ id }) =>
        subcategories.some(({ category }) => id === category.id)
      ),
    [categories, subcategories]
  );
  const categoriesWithSubcategories = useMemo(
    () => groupSubcategories(filteredCategories, subcategories),
    [filteredCategories, subcategories]
  );
  const activeSubcategoryIndex = useMemo(
    () =>
      subcategories.findIndex(({ id }) => id === activeSubcategory?.id) +
      (isFormCompleted ? 1 : 0),
    [activeSubcategory?.id, isFormCompleted, subcategories]
  );
  const { isMobile } = useIsMobile();

  return isMobile ? null : (
    <div className="flex flex-col items-center justify-center">
      <div className="my-8 flex w-200 flex-row justify-center text-left">
        {categoriesWithSubcategories.map((category) => (
          <FormProgressItem
            disableProgress={disableProgress}
            key={category.id}
            activeSubcategory={activeSubcategory}
            activeSubcategoryIndex={activeSubcategoryIndex}
            subcategories={subcategories}
            category={category}
            unresolvedQuestionsBySubcategory={unresolvedQuestionsBySubcategory}
          />
        ))}
        <div className="flex items-center">
          <FormProgressItemCategory
            name="Complete"
            completed={!disableProgress && isFormCompleted}
            isActive={!disableProgress && isFormCompleted}
          />
        </div>
      </div>
    </div>
  );
};
