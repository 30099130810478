import { useGetNotifications } from "hooks";
import { useCallback, useMemo, useState } from "react";
import { NOTIFICATIONS_TAB } from "types/tabs";

const LIMIT = 10;

export type NotificationsPaginationTabs = {
  pageCount: number;
  currentPage: number;
  offset: number;
};

export const useNotificationsInfo = () => {
  const [offsetAll, setOffsetAll] = useState(0);
  const [offsetUnread, setOffsetUnread] = useState(0);

  const {
    counterAll,
    counterUnread,
    notificationsAll,
    notificationsUnread,
    loading,
    error,
  } = useGetNotifications({
    variables: { offsetAll, offsetUnread, limit: LIMIT },
  });

  const changePage = useCallback((tab: NOTIFICATIONS_TAB, page: number) => {
    if (tab === NOTIFICATIONS_TAB.ALL) {
      setOffsetAll(page * LIMIT);
    } else {
      setOffsetUnread(page * LIMIT);
    }
  }, []);

  const { paginationAll, paginationUnread } = useMemo(
    () => ({
      paginationUnread: {
        pageCount: Math.ceil(counterUnread / LIMIT),
        currentPage: offsetUnread / LIMIT,
        offset: offsetUnread,
      },
      paginationAll: {
        pageCount: Math.ceil(counterAll / LIMIT),
        currentPage: offsetAll / LIMIT,
        offset: offsetAll,
      },
    }),
    [counterAll, counterUnread, offsetAll, offsetUnread]
  );

  return {
    counterUnread,
    notificationsAll,
    notificationsUnread,
    loading,
    error,
    changePage,
    paginationAll,
    paginationUnread,
  };
};
