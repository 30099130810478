import { gql, useQuery } from "@apollo/client";
import { useMemo } from "react";
import { Project } from "types/assignments";
import { Timesheet } from "types/timesheets";
import { getWeekState } from "utils/timesheets.utils";

interface GetProjectsData {
  projects: Project[];
}
interface GetProjectsVars {
  startDate: string;
  endDate: string;
}

const GET_PROJECTS_TIMESHEETS_STATE = gql`
  query projectsTimesheets($startDate: timestamptz!, $endDate: timestamptz!) {
    projects(order_by: { name: asc }) {
      id
      name
      assignments: project_employee_assignments {
        timesheets: work_logs(
          where: {
            start_time: { _gte: $startDate }
            end_time: { _lte: $endDate }
          }
        ) {
          employee_id
          state
        }
      }
    }
  }
`;
interface Props {
  startDate: string;
  endDate: string;
}

export const useProjectsTimesheetsState = ({ startDate, endDate }: Props) => {
  const { data, loading, error } = useQuery<GetProjectsData, GetProjectsVars>(
    GET_PROJECTS_TIMESHEETS_STATE,
    {
      variables: {
        startDate,
        endDate,
      },
    }
  );

  const preparedData = useMemo(
    () =>
      (data?.projects ?? []).map((project) => {
        const timesheets = (project.assignments ?? []).reduce(
          (result, ass) => [...result, ...(ass.timesheets ?? [])],
          [] as Timesheet[]
        );
        const weekState = getWeekState(timesheets);
        return { ...project, weekState };
      }),
    [data?.projects]
  );

  return {
    projectsData: preparedData,
    loading,
    error,
  };
};
