import { gql, useQuery } from "@apollo/client";
import { TimesheetExceptionDay } from "types/timesheets";

interface GetTimesheetsExceptionDaysVars {
  employeeId: string;
}

interface GetTimesheetsExceptionDaysData {
  exceptionDays: Omit<TimesheetExceptionDay, "reason">[];
}

// get employee's all exceptions days
export const GET_TIMESHEETS_EXCEPTION_DAYS = gql`
  query exceptionDays($employeeId: uuid!) {
    exceptionDays: timesheets_exceptions_days(
      order_by: { day: asc }
      where: { employee_id: { _eq: $employeeId } }
    ) {
      id
      day
      employeeId: employee_id
      managerId: manager_id
      enabled
    }
  }
`;

interface UseGetTimesheetsExceptionDaysProps {
  employeeId?: string;
}

export const useGetTimesheetsExceptionDays = ({
  employeeId,
}: UseGetTimesheetsExceptionDaysProps) => {
  return useQuery<
    GetTimesheetsExceptionDaysData,
    GetTimesheetsExceptionDaysVars
  >(GET_TIMESHEETS_EXCEPTION_DAYS, {
    variables: { employeeId: employeeId! },
    skip: !employeeId,
  });
};
