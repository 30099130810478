import { QueryHookOptions, gql, useQuery } from "@apollo/client";
import { useAuthContext } from "contexts";
import { useMemo } from "react";
import {
  NOTIFICATION_STATUS,
  NotificationAssignment,
} from "types/notifications";

const notificationAssignmentFragment = gql`
  fragment NotificationAssignmentData on notifications_assignments {
    id
    status
    notificationId: notification_id
    reviewedAt: reviewed_at
    modifiedAt: modified_at
    message {
      actionParams: action_params
      actionType: action_type
      text
      title
      type
      createdAt: created_at
    }
  }
`;

export const GET_MANAGER_ALL_NOTIFICATIONS = gql`
  query getManagerAllNotifications($managerId: uuid, $limit: Int, $offsetAll: Int, $offsetUnread: Int) {
    notificationsAll: notifications_assignments(
      limit: $limit,
      offset: $offsetAll,
      order_by: { message: { created_at: desc } },
      where: {
        notifications_status: { value: { _neq: ${NOTIFICATION_STATUS.DELETED} } }
        employee_id: { _is_null: true }
        manager_id: { _eq: $managerId }
      }
    ) {
      ...NotificationAssignmentData
      managerId: manager_id
    }
    allCounter: notifications_assignments_aggregate(
      where: {
        notifications_status: { value: { _neq: ${NOTIFICATION_STATUS.DELETED} } }
        employee_id: { _is_null: true }
        manager_id: { _eq: $managerId }
      }
    ) {
      aggregate {
        count
      }
    }
    notificationsUnread: notifications_assignments(
      limit: $limit,
      offset: $offsetUnread,
      order_by: { message: { created_at: desc } },
      where: {
        notifications_status: { value: { _eq: ${NOTIFICATION_STATUS.UNREAD} } }
        employee_id: { _is_null: true }
        manager_id: { _eq: $managerId }
      }
    ) {
      ...NotificationAssignmentData
      managerId: manager_id
    }
    unreadCounter: notifications_assignments_aggregate(
      where: {
        notifications_status: { value: { _eq: ${NOTIFICATION_STATUS.UNREAD} } }
        employee_id: { _is_null: true }
        manager_id: { _eq: $managerId }
      }
    ) {
      aggregate {
        count
      }
    }
  }
  ${notificationAssignmentFragment}
`;

export const GET_EMPLOYEE_ALL_NOTIFICATIONS = gql`
  query getEmployeeAllNotifications($employeeId: uuid, $limit: Int, $offsetAll: Int, $offsetUnread: Int) {
    notificationsAll: notifications_assignments(
      limit: $limit,
      offset: $offsetAll,
      order_by: { message: { created_at: desc } },
      where: {
        notifications_status: { value: { _neq: ${NOTIFICATION_STATUS.DELETED} } }
        manager_id: { _is_null: true }
        employee_id: { _eq: $employeeId }
      }
    ) {
      ...NotificationAssignmentData
      employeeId: employee_id
    }
    allCounter: notifications_assignments_aggregate(
      where: {
        notifications_status: { value: { _neq: ${NOTIFICATION_STATUS.DELETED} } }
        manager_id: { _is_null: true }
        employee_id: { _eq: $employeeId }
      }
    ) {
      aggregate {
        count
      }
    }
    notificationsUnread: notifications_assignments(
      limit: $limit,
      offset: $offsetUnread,
      order_by: { message: { created_at: desc } },
      where: {
        notifications_status: { value: { _eq: ${NOTIFICATION_STATUS.UNREAD} } }
        manager_id: { _is_null: true }
        employee_id: { _eq: $employeeId }
      }
    ) {
      ...NotificationAssignmentData
      employeeId: employee_id
    }
    unreadCounter: notifications_assignments_aggregate(
      where: {
        notifications_status: { value: { _eq: ${NOTIFICATION_STATUS.UNREAD} } }
        manager_id: { _is_null: true }
        employee_id: { _eq: $employeeId }
      }
    ) {
      aggregate {
        count
      }
    }
  }
  ${notificationAssignmentFragment}
`;

export interface GetNotificationsData {
  notificationsAll: Array<NotificationAssignment>;
  notificationsUnread: Array<NotificationAssignment>;
  allCounter: { aggregate: { count: number } };
  unreadCounter: { aggregate: { count: number } };
}

type Only<T, U> = {
  [P in keyof T]: T[P];
} & {
  [P in keyof U]?: never;
};

type Either<T, U> = Only<T, U> | Only<U, T>;

interface GetManagerNotificationVars {
  managerId: string | undefined;
}
interface GetEmployeeNotificationsVars {
  employeeId: string | undefined;
}
type NotificationsVars = {
  limit: number;
  offsetAll: number;
  offsetUnread: number;
};
export type GetNotificationsVars = NotificationsVars &
  Either<GetManagerNotificationVars, GetEmployeeNotificationsVars>;

const useGetNotifications = (
  options: QueryHookOptions<GetNotificationsData, NotificationsVars>
) => {
  const { user, isManager } = useAuthContext();
  const employeeId = user?.userId;
  const managerId = user?.managerId;

  const { data, loading, error } = useQuery<
    GetNotificationsData,
    GetManagerNotificationVars | GetEmployeeNotificationsVars
  >(
    isManager()
      ? GET_MANAGER_ALL_NOTIFICATIONS
      : GET_EMPLOYEE_ALL_NOTIFICATIONS,
    {
      variables: isManager()
        ? { ...options.variables, managerId: managerId! }
        : { ...options.variables, employeeId: employeeId! },
      skip: isManager() ? !managerId : !employeeId,
      fetchPolicy: "network-only",
      pollInterval: 7000, // 7 sec
    }
  );

  const preparedData = useMemo(
    () => ({
      notificationsAll: data?.notificationsAll || [],
      notificationsUnread: data?.notificationsUnread || [],
      counterAll: data?.allCounter?.aggregate?.count || 0,
      counterUnread: data?.unreadCounter?.aggregate?.count || 0,
    }),
    [data]
  );

  return {
    ...preparedData,
    loading,
    error,
  };
};

export { useGetNotifications };
