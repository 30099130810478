import {
  useGetSubcategoryAnswers,
  useUpsertEmployeeAnswer,
  useURLSearchParams,
} from "hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Category,
  EmployeeCareerPath,
  FormQuestionsState,
  QueryParams,
  Question,
  Subcategory,
} from "types/career-path";
import { getActiveSubcategory, getQuestionsState } from "utils/form.utils";

// - user sees all questions within current subcategory
// - user have to answer all visible questions
// - unanswered questions are disabled
// - user moves to next subcategory when he answers all questions from current subcategory

export interface GiveAnswerArgs {
  questionId: string;
  answer: boolean;
  id?: string;
  hasAnswer: boolean;
}

interface UseCareerPathFormProps {
  categories: Category[];
  subcategories: Subcategory[];
  allAnswers: EmployeeCareerPath[];
}

export const useCareerPathForm = ({
  categories,
  subcategories,
  allAnswers,
}: UseCareerPathFormProps) => {
  const history = useHistory();
  const { employeeId } = useParams<QueryParams>();
  const { searchParams } = useURLSearchParams();
  const subcategoryIdParam = searchParams.get("subcategoryId") || "";

  const { upsertEmployeeAnswer } = useUpsertEmployeeAnswer();

  const [interludeScreen, setInterludeScreen] = useState<number | undefined>(
    undefined
  );

  const [isEndScreenVisible, setIsEndScreenVisible] = useState<boolean>(false);
  const hideEndScreen = () => setIsEndScreenVisible(false);

  const [activeSubcategory, setActiveSubcategory] = useState<
    Subcategory | undefined
  >(
    subcategoryIdParam
      ? () => subcategories.find(({ id }) => id === subcategoryIdParam)
      : getActiveSubcategory(subcategories, allAnswers)
  );

  // answers only for active subcategory
  const { data: answersData } = useGetSubcategoryAnswers({
    employeeId,
    subcategoryId: activeSubcategory!.id,
    options: { skip: !activeSubcategory },
  });
  const activeSubcategoryAnswers = useMemo(
    () => answersData?.subcategoryAnswers ?? [],
    [answersData?.subcategoryAnswers]
  );

  const [formQuestionsState, setFormQuestionsState] =
    useState<FormQuestionsState>({
      activeQuestionId: undefined,
      focusedQuestionId: undefined,
    });

  const goToSubcategory = useCallback(
    (subcategory: Subcategory) => {
      setInterludeScreen(undefined);
      setActiveSubcategory(subcategory);

      searchParams.set("subcategoryId", subcategory.id);
      history.push({ search: `?${searchParams}` });
    },
    [history, searchParams]
  );

  const handleNext = useCallback(() => {
    const activeSubcategoryIndex = subcategories.findIndex(
      ({ id }) => id === activeSubcategory?.id
    );
    const nextSubcategory: Subcategory | undefined =
      subcategories[activeSubcategoryIndex + 1];
    const isInterludeScreenVisible = interludeScreen !== undefined;
    const isNextCategorySame =
      activeSubcategory?.category.id === nextSubcategory?.category.id;

    const showEndScreen = () => {
      setIsEndScreenVisible(true);
    };

    const showInterludeScreen = () => {
      // show interlude screen between main categories
      const categoryIndex = categories.findIndex(
        ({ id }) => id === activeSubcategory?.category.id
      );
      setInterludeScreen(categoryIndex);
    };

    if (!nextSubcategory) {
      showEndScreen();
      return;
    }

    if (isInterludeScreenVisible) {
      setInterludeScreen(undefined);
    } else {
      goToSubcategory(nextSubcategory);
      if (!isNextCategorySame) {
        showInterludeScreen();
      }
    }
  }, [
    subcategories,
    interludeScreen,
    activeSubcategory?.category.id,
    activeSubcategory?.id,
    categories,
    goToSubcategory,
  ]);

  const handleBack = useCallback(() => {
    const activeSubcategoryIndex = subcategories.findIndex(
      ({ id }) => id === activeSubcategory?.id
    );
    const previousSubcategory: Subcategory | undefined =
      subcategories[activeSubcategoryIndex - 1];
    const isInterludeScreenVisible = interludeScreen !== undefined;
    const isPreviousCategorySame =
      activeSubcategory?.category.id === previousSubcategory?.category.id;

    const showInterludeScreen = () => {
      // show interlude screen between main categories
      const categoryIndex = categories.findIndex(
        ({ id }) => id === activeSubcategory?.category.id
      );
      setInterludeScreen(categoryIndex - 1);
    };

    if (previousSubcategory) {
      if (isPreviousCategorySame || isInterludeScreenVisible) {
        goToSubcategory(previousSubcategory);
        return;
      }
      if (!isInterludeScreenVisible) {
        showInterludeScreen();
      }
    }
  }, [
    activeSubcategory?.category.id,
    activeSubcategory?.id,
    categories,
    goToSubcategory,
    interludeScreen,
    subcategories,
  ]);

  useEffect(() => {
    if (activeSubcategory) {
      // set active & focused questions when activeSubcategory or its answers changes
      const questionsState = getQuestionsState(
        activeSubcategory,
        activeSubcategoryAnswers
      );
      setFormQuestionsState(questionsState);
    }
  }, [activeSubcategory, activeSubcategoryAnswers, subcategoryIdParam]);

  useEffect(() => {
    // subcategoryIdParam doesn't exist only once - at the beginning when form is opened
    if (activeSubcategory && !subcategoryIdParam) {
      searchParams.set("subcategoryId", activeSubcategory.id);
      history.replace({ search: `?${searchParams}` });
    }
  }, [activeSubcategory, subcategoryIdParam, searchParams, history]);

  const giveAnswer = useCallback(
    async ({ questionId, answer, id, hasAnswer }: GiveAnswerArgs) => {
      await upsertEmployeeAnswer({
        id,
        answer,
        questionId,
      });

      const subcategoryQuestions = activeSubcategory?.questions ?? [];
      const questionIndex = subcategoryQuestions.findIndex(
        ({ id }) => id === questionId
      );
      // nextQuestion is empty if current question was the last question of the subcategory
      const nextQuestion: Question | undefined =
        subcategoryQuestions[questionIndex + 1];

      if (nextQuestion) {
        setFormQuestionsState((prev) => ({
          // set new active question to next one within subcategory only after giving answer to unanswered question
          activeQuestionId: hasAnswer ? prev.activeQuestionId : nextQuestion.id,
          focusedQuestionId: nextQuestion.id,
        }));
      }
    },
    [activeSubcategory?.questions, upsertEmployeeAnswer]
  );

  return {
    formQuestionsState,
    activeSubcategory,
    activeSubcategoryAnswers,
    giveAnswer,
    handleNext,
    handleBack,
    hideEndScreen,
    isEndScreenVisible,
    interludeScreen,
  };
};
