const styles = {
  warning: `
    bg-orange-3 
    p-1 
    rounded 
    flex 
    items-center 
    [&>svg]:text-orange-1`,
};
export default styles;
