import { Dropdown } from "components";
import moment from "moment";
import React, { FC, useState } from "react";
import { DateRange, DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { Calendar2Icon } from "resources/icons";
import styles from "./DateRangePicker.styles";

const DATE_FORMAT = "YYYY-MM-DD";
const DATE_RANGE_FORMAT = "YYYY/MM/DD";

export interface DateRangePickerProps {
  inputOnChange?: (value: DateRange | undefined) => void;
  defaultRange?: DateRange;
}

export const DateRangePicker: FC<DateRangePickerProps> = ({
  inputOnChange,
  defaultRange,
}) => {
  const [range, setRange] = useState<DateRange | undefined>({
    from:
      defaultRange?.from ||
      new Date(moment().startOf("month").format(DATE_FORMAT)),
    to:
      defaultRange?.to || new Date(moment().endOf("month").format(DATE_FORMAT)),
  });

  return (
    <Dropdown
      triggerContent={
        <div className={styles.dateContainer}>
          <input
            className={styles.dateInput}
            readOnly
            value={`${
              range?.from
                ? moment(range?.from).format(DATE_RANGE_FORMAT)
                : DATE_RANGE_FORMAT.toLowerCase()
            } - ${
              range?.to
                ? moment(range?.to).format(DATE_RANGE_FORMAT)
                : DATE_RANGE_FORMAT.toLowerCase()
            }`}
          />
          <Calendar2Icon className={styles.icon} />
        </div>
      }
      contentContent={
        <div>
          <DayPicker
            ISOWeek
            mode="range"
            selected={range}
            onSelect={(event) => {
              setRange(event);
              inputOnChange?.(event);
            }}
            modifiersClassNames={{
              selected: "bg-purple-2 text-white hover:text-purple-2",
              today: "font-bold",
            }}
            showOutsideDays
          />
        </div>
      }
      contentProps={{
        className: "bg-white p-4 m-0 rounded shadow-md text-xs",
        align: "end",
      }}
      triggerProps={{ className: "w-full lg:w-64" }}
    />
  );
};
