import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from "@mui/material";
import { Button } from "components";
import React, {
  ComponentType,
  Dispatch,
  ReactNode,
  SetStateAction,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";

export type ModalState = {
  setOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;
};
type ModalProps = {
  onSubmit?: () => void;
  title?: string;
  contentMessage?: string | ReactNode;
  DialogActionsComponent?: ComponentType<ModalState>;
  DialogContentComponent?: ComponentType<ModalState>;
  DialogTitleComponent?: ComponentType<ModalState>;
  dialogProps?: Partial<DialogProps>;
};

export type ModalRefType = {
  openModal: () => void;
  closeModal: () => void;
};

export const Modal = forwardRef<ModalRefType, ModalProps>(
  (
    {
      onSubmit,
      title = "Click accept to confirm",
      contentMessage,
      DialogActionsComponent,
      DialogContentComponent,
      DialogTitleComponent,
      dialogProps,
    },
    ref
  ) => {
    const [open, setOpen] = useState(false);
    const closeModal = useCallback(() => {
      setOpen(false);
    }, []);

    useImperativeHandle(ref, () => ({
      openModal: () => setOpen(true),
      closeModal,
    }));

    return (
      <Dialog
        open={open}
        disableScrollLock
        onClose={closeModal}
        classes={{ paper: "xs:px-8 px-16 py-8 m-0" }}
        {...dialogProps}
      >
        {DialogTitleComponent ? (
          <DialogTitleComponent open={open} setOpen={setOpen} />
        ) : (
          <DialogTitle className="text-semibold px-0 pb-4 pt-0 text-center font-sans text-lg text-purple-1 sm:text-xl">
            {title}
          </DialogTitle>
        )}
        {DialogContentComponent ? (
          <DialogContentComponent open={open} setOpen={setOpen} />
        ) : (
          <>
            {contentMessage && (
              <DialogContent className="flex justify-center text-center">
                <div className="text-center">{contentMessage}</div>
              </DialogContent>
            )}
          </>
        )}
        {DialogActionsComponent ? (
          <DialogActionsComponent open={open} setOpen={setOpen} />
        ) : (
          <DialogActions
            className="flex justify-center gap-2 p-0"
            disableSpacing
          >
            <Button
              variant="secondary"
              onClick={(e) => {
                e.stopPropagation();
                closeModal();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={(e) => {
                e.stopPropagation();
                onSubmit?.();
              }}
            >
              Accept
            </Button>
          </DialogActions>
        )}
      </Dialog>
    );
  }
);

Modal.displayName = "Modal";
