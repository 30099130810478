export const styles: Record<
  | "tableHeadCell"
  | "categoryName"
  | "subcategoryName"
  | "categoryTableHeadCell",
  string
> = {
  tableHeadCell: `
    py-4 
    uppercase
    text-xs
    text-left 
    font-semibold`,
  categoryName: `
    text-lg 
    font-semibold 
    text-purple-1`,
  subcategoryName: `
    text-sm 
    font-medium`,
  categoryTableHeadCell: `
    py-4 
    pl-12 
    pr-3 
    flex 
    items-center 
    gap-2`,
};
