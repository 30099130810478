import { useMemo } from "react";
import { gql, useQuery } from "@apollo/client";
import { ProjectEmployeeAssignment } from "types/assignments";

type GetEmployeeAssignmentsData = {
  assignments: (Pick<
    ProjectEmployeeAssignment,
    | "id"
    | "startDate"
    | "endDate"
    | "billableStatus"
    | "fte"
    | "categoryOfNonBillable"
    | "reasonWhyNonBillable"
    | "projectId"
    | "project"
  > & {
    roleInProject?: { value: string; label: string };
  })[];
};

type GetEmployeeAssignmentsVars = {
  employeeId: string;
};

export const GET_EMPLOYEE_ASSIGNMENTS = gql`
  query GetEmployeeAssignments($employeeId: uuid!) {
    assignments: project_employee_assignments(
      where: { employee_id: { _eq: $employeeId } }
    ) {
      id
      startDate: start_date
      endDate: end_date
      billableStatus: billable_status
      fte
      roleInProject: project_employee_role {
        value
        label
      }
      categoryOfNonBillable: category_of_non_billable
      reasonWhyNonBillable: reason_why_non_billable
      projectId: project_id
      project {
        id
        name
      }
    }
  }
`;

export const useEmployeeAssignments = (employeeId: string) => {
  const { data, loading, error } = useQuery<
    GetEmployeeAssignmentsData,
    GetEmployeeAssignmentsVars
  >(GET_EMPLOYEE_ASSIGNMENTS, {
    variables: { employeeId },
  });

  const parsedData = useMemo(
    () =>
      data?.assignments.map(({ roleInProject, ...assignment }) => ({
        ...assignment,
        roleInProject: roleInProject?.value || "",
        roleInProjectLabel: roleInProject?.label || "",
      })),
    [data]
  );

  return { data: parsedData ?? [], loading, error };
};
