import { Avatar } from "@mui/material";
import React, { FC } from "react";

export interface UserAvatarProps {
  picture?: string;
  firstName: string;
  lastName: string;
  styles?: string;
}

export const UserAvatar: FC<UserAvatarProps> = ({
  picture,
  firstName,
  lastName,
  styles,
}) => {
  const initials = `${firstName[0]}${lastName[0]}`;

  return (
    <Avatar
      alt="user image"
      title="user image"
      src={picture}
      className={`bg-purple-3 ${styles}`}
    >
      {initials}
    </Avatar>
  );
};
