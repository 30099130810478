import React, {
  ButtonHTMLAttributes,
  PropsWithChildren,
  forwardRef,
} from "react";
import { twMerge } from "tailwind-merge";
import styles from "./Button.styles";

export type ButtonProps = {
  variant?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "duration"
    | "surveyPrimary"
    | "surveySecondary"
    | "danger";
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const Button = forwardRef<
  HTMLButtonElement,
  PropsWithChildren<ButtonProps>
>(({ variant, children, className, ...restButtonProps }, ref) => {
  return (
    <button
      type="button"
      {...restButtonProps}
      className={twMerge(
        styles.default,
        variant && styles?.[variant],
        className
      )}
      ref={ref}
    >
      {children}
    </button>
  );
});

Button.displayName = "Button";
