import { useCallback } from "react";
import moment from "moment";
import { ApolloError, gql, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { GET_ASSIGNMENTS } from "hooks/useAssignments/useAssignments";
import { GET_PROJECTS } from "hooks/useProjects/useProjects";
import { GET_PROJECT_ASSIGNMENTS } from "hooks/useProjectAssignments/useProjectAssignments";
import { GET_ALL_PROJECT_EMPLOYEES } from "hooks/useProjectEmployees/useProjectEmployees";
import { GET_EMPLOYEE_ASSIGNMENTS } from "hooks/useEmployeeAssignments/useEmployeeAssignments";

type DeleteProjectAssignmentData = {
  response: { id: string };
};

type DeleteProjectAssignmentVars = {
  id: string;
};

const DELETE_PROJECT_ASSIGNMENT = gql`
  mutation DeleteProjectAssignment($id: uuid!) {
    delete_project_employee_assignments_by_pk(id: $id) {
      id
    }
  }
`;

export const useDeleteProjectAssignment = (
  projectId?: string,
  employeeId?: string
) => {
  const [deleteProjectAssignmentMutation, data] = useMutation<
    DeleteProjectAssignmentData,
    DeleteProjectAssignmentVars
  >(DELETE_PROJECT_ASSIGNMENT);

  const deleteProjectAssignment = useCallback(
    async (values: DeleteProjectAssignmentVars) => {
      try {
        await deleteProjectAssignmentMutation({
          variables: values,
          refetchQueries: [
            {
              query: GET_ASSIGNMENTS,
              variables: {
                now: moment().startOf("day").toISOString(),
              },
            },
            {
              query: GET_EMPLOYEE_ASSIGNMENTS,
              variables: {
                employeeId,
              },
            },
            {
              query: GET_PROJECTS,
              variables: {
                startDate: moment().startOf("month").toISOString(),
                endDate: moment().endOf("month").toISOString(),
              },
            },
            {
              query: GET_PROJECT_ASSIGNMENTS,
              variables: {
                projectId,
              },
            },
            {
              query: GET_ALL_PROJECT_EMPLOYEES,
              variables: {
                projectId,
              },
            },
          ],
        });
      } catch (ex) {
        toast.error((ex as ApolloError)?.message);
      }
    },
    [deleteProjectAssignmentMutation, projectId, employeeId]
  );

  return { deleteProjectAssignment, ...data };
};
