import axios, { AxiosError } from "axios";
import { GOOGLE_CALENDAR_URL } from "config/constants";
import { useEffect, useState } from "react";
import { HolidayEvent } from "types/timesheets";

type GetHolidaysData = {
  items: HolidayEvent[];
};

interface UseGetHolidaysProps {
  startDate: string;
  endDate: string;
}

//TODO wrap into useSwr
export const useGetHolidays = ({ startDate, endDate }: UseGetHolidaysProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<AxiosError | undefined>(undefined);
  const [data, setData] = useState<HolidayEvent[]>([]);

  const getHolidays = async () => {
    setLoading(true);
    setError(undefined);
    setData([]);
    try {
      const res = await axios.get<GetHolidaysData>(GOOGLE_CALENDAR_URL, {
        headers: {
          Authorization: undefined,
        },
        params: {
          timeMin: startDate,
          timeMax: endDate,
        },
      });
      if (res.data.items) {
        const holidays = res.data.items
          .map(({ id, description, summary, start, end }) => ({
            id,
            description,
            summary,
            start,
            end,
          }))
          // filter out non-official holidays like Mother's Day etc
          .filter((i) => i.description === "Public holiday");
        setData(holidays);
      }
    } catch (err) {
      setError(err as AxiosError);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getHolidays();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  return { data, loading, error };
};
