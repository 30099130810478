import React, { FC } from "react";

interface OkrStatusChipProps {
  status: string;
}

const statusesColorMap: Record<string, string> = {
  //TODO add all possible statuses from OKR
  "In Progress": "bg-in-progress-status",
  "Done": "bg-completed-status",
  "Cancelled": "bg-red-3",
  "unknown": "bg-in-progress-status",
};

export const OkrStatusChip: FC<OkrStatusChipProps> = ({ status }) => {
  return (
    <div
      className={`${
        statusesColorMap?.[status] || statusesColorMap?.unknown
      } rounded px-4 py-2 text-center text-sm font-medium capitalize`}
    >
      {status}
    </div>
  );
};
