import {
  MAX_PAGES_WITHOUT_DOTS,
  PAGES_AT_START_OR_END,
  PAGE_INDEX_OFFSET,
} from "./constants";

export const transformPageOptions = (
  pages: number[],
  currentPage: number
): (string | number)[] => {
  const totalCount = pages.length;
  const lastPage = pages[pages.length - 1];
  const firstPage = 0;

  if (totalCount <= MAX_PAGES_WITHOUT_DOTS) {
    return pages;
  }
  // with dots at the end
  if (currentPage < PAGE_INDEX_OFFSET) {
    return [...pages.slice(0, PAGES_AT_START_OR_END), "...", lastPage];
  }
  // with dots at the start
  if (currentPage + PAGE_INDEX_OFFSET >= totalCount) {
    return [
      firstPage,
      "...",
      ...pages.slice(totalCount - PAGES_AT_START_OR_END, totalCount),
    ];
  }
  // with double dots
  if (
    currentPage >= PAGE_INDEX_OFFSET &&
    totalCount - PAGE_INDEX_OFFSET > currentPage
  ) {
    const prevPage = currentPage - 1;
    const nextPage = currentPage + 2;
    return [
      firstPage,
      "...",
      ...pages.slice(prevPage, nextPage),
      "...",
      lastPage,
    ];
  }

  return pages;
};
