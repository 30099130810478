import React, { FC } from "react";
import { LabelIcon } from "resources/icons";
import { Category, Subcategory } from "types/career-path";
import getStyles from "./ProgressItem.styles";
import { FormProgressItemCategory } from "./ProgressItemCategory";
interface Props {
  activeSubcategory: Subcategory | undefined;
  activeSubcategoryIndex: number;
  subcategories: Subcategory[];
  category: Category;
  withProgress?: boolean;
  unresolvedQuestionsBySubcategory?: Record<string, number>;
  disableProgress: boolean;
}

export const FormProgressItem: FC<Props> = ({
  activeSubcategory,
  subcategories,
  activeSubcategoryIndex,
  category,
  withProgress = true,
  unresolvedQuestionsBySubcategory,
  disableProgress,
}) => {
  const indexOfFirstSubcategory = subcategories.findIndex(
    ({ id }) => id === category.subcategories[0]?.id
  );
  const indexOfLastSubcategory = subcategories.findIndex(
    ({ id }) =>
      id === category.subcategories[category.subcategories.length - 1]?.id
  );
  const isActive =
    !disableProgress &&
    !!activeSubcategory &&
    activeSubcategoryIndex >= indexOfFirstSubcategory;

  const completed =
    !disableProgress && activeSubcategoryIndex > indexOfLastSubcategory;
  const styles = getStyles({ isActive, completed });
  return (
    <div className="flex items-center">
      <FormProgressItemCategory
        name={category.name}
        {...{ isActive, completed }}
      />
      {withProgress ? (
        <div className={styles.progressWrapper}>
          <ol className="ml-2 flex min-h-6 w-full items-center">
            {category.subcategories.map(({ id, name }, index: number) => {
              const passed =
                !disableProgress &&
                (completed ||
                  index - 1 + indexOfFirstSubcategory < activeSubcategoryIndex);
              return (
                <li
                  key={`${id}`}
                  className={`
                  ${styles.progressPart}
                  ${
                    passed
                      ? styles.progressPartChecked
                      : styles.progressPartNotChecked
                  } 
                  ${index === 0 && "before:rounded-l"}
                  relative`}
                >
                  {!!unresolvedQuestionsBySubcategory?.[name] && (
                    <div className="absolute bottom-3/4 right-4">
                      <div className="relative">
                        <LabelIcon />
                        <p className="absolute inset-0 flex justify-center pl-[1px] text-sm font-semibold text-white">
                          {unresolvedQuestionsBySubcategory?.[name]}
                        </p>
                      </div>
                    </div>
                  )}
                  {passed ? (
                    <span className={styles.bulletChecked}>
                      <div className={styles.bulletNotChecked}></div>
                    </span>
                  ) : (
                    <span className={`${styles.bulletChecked} bg-transparent`}>
                      <span className={styles.bulletNotChecked}></span>
                    </span>
                  )}
                </li>
              );
            })}

            <li
              className={`${styles.progressPart}${
                completed
                  ? styles.progressPartChecked
                  : styles.progressPartNotChecked
              } before:rounded-r`}
            >
              <span className="purple-7 flex h-2 w-2 shrink-0 items-center justify-center rounded-full"></span>
            </li>
          </ol>
        </div>
      ) : null}
    </div>
  );
};
