import moment from "moment";
import { gql, useQuery } from "@apollo/client";
import { ProjectEmployee } from "pages/project/components/projectSummary/components";
import { useMemo } from "react";
import { Employee } from "types/employee";

type GetProjectEmployeesType = {
  employees: Pick<
    Employee,
    | "email"
    | "firstName"
    | "lastName"
    | "picture"
    | "id"
    | "department"
    | "personal"
    | "status"
  >[];
};

type GetProjectEmployeesVarsType = {
  projectId: string;
  startDate?: string;
  endDate?: string;
};

export const GET_PROJECT_EMPLOYEES = gql`
  query getProjectEmployees(
    $projectId: uuid!
    $startDate: timestamptz!
    $endDate: timestamptz!
  ) {
    employees(
      where: {
        _or: [
          {
            project_employee_assignments: {
              project_id: { _eq: $projectId }
              start_date: { _lte: $endDate }
              end_date: { _gt: $startDate }
            }
          }
          {
            _and: [
              {
                project_employee_assignments: {
                  project_id: { _eq: $projectId }
                  start_date: { _lte: $endDate }
                  end_date: { _gt: $startDate }
                }
              }
              { status: { _eq: ACTIVE } }
            ]
          }
          {
            work_logs: {
              project_employee_assignment: { project_id: { _eq: $projectId } }
              start_time: { _gte: $startDate }
              end_time: { _lte: $endDate }
            }
          }
        ]
      }
      order_by: { last_name: asc }
    ) {
      email
      firstName: first_name
      lastName: last_name
      picture
      id
      status
      department {
        id
        name
      }
      personal: personal_details {
        id
        grade {
          id
          label
        }
      }
    }
  }
`;

export const GET_ALL_PROJECT_EMPLOYEES = gql`
  query getAllProjectEmployees($projectId: uuid!) {
    employees(
      where: {
        _or: [
          { project_employee_assignments: { project_id: { _eq: $projectId } } }
          {
            work_logs: {
              project_employee_assignment: { project_id: { _eq: $projectId } }
            }
          }
        ]
      }
      order_by: { last_name: asc }
    ) {
      email
      firstName: first_name
      lastName: last_name
      picture
      id
      status
      department {
        id
        name
      }
      personal: personal_details {
        id
        grade {
          id
          label
        }
      }
    }
  }
`;

export const useProjectEmployees = (
  projectId: string,
  filterByAssignmentDates = true
) => {
  const query = filterByAssignmentDates
    ? GET_PROJECT_EMPLOYEES
    : GET_ALL_PROJECT_EMPLOYEES;
  const { data, ...rest } = useQuery<
    GetProjectEmployeesType,
    GetProjectEmployeesVarsType
  >(query, {
    variables: {
      projectId,
      startDate: filterByAssignmentDates
        ? moment().startOf("month").toISOString()
        : undefined,
      endDate: filterByAssignmentDates
        ? moment().endOf("month").toISOString()
        : undefined,
    },
  });

  const preparedData = useMemo<{
    employees: Omit<
      ProjectEmployee,
      "assignmentId" | "roleInProject" | "roleInProjectLabel"
    >[];
  }>(
    () => ({
      employees:
        data?.employees?.map(({ department, ...employee }) => {
          return {
            ...employee,
            department: department?.name ?? "",
          };
        }) || [],
    }),
    [data?.employees]
  );

  return {
    data: preparedData,
    ...rest,
  };
};
