import { ApolloError, gql, useMutation } from "@apollo/client";
import { useCallback } from "react";
import { toast } from "react-toastify";
import { EmployeeCareerPath } from "types/career-path";

interface UpsertManagerAnswerData {
  upsertedAnswer: Pick<
    EmployeeCareerPath,
    | "id"
    | "employeeId"
    | "questionId"
    | "answer"
    | "managerAnswer"
    | "finalAnswer"
  >;
}

interface UpsertManagerAnswerVars {
  id?: string;
  employeeId: string;
  questionId: string;
  managerAnswer: boolean;
  finalAnswer?: boolean | null;
}

const UPSERT_MANAGER_ANSWER = gql`
  mutation upsertManagerAnswer(
    $id: String
    $employeeId: String!
    $questionId: String!
    $managerAnswer: Boolean!
    $finalAnswer: Boolean
  ) {
    upsertedAnswer: upsert_manager_answer(
      id: $id
      employee_id: $employeeId
      question_id: $questionId
      manager_answer: $managerAnswer
      final_answer: $finalAnswer
    ) {
      id
      employeeId: employee_id
      questionId: question_id
      answer
      managerAnswer: manager_answer
      finalAnswer: final_answer
    }
  }
`;

export const useUpsertManagerAnswer = () => {
  const [upsertManagerSurveyAnswer] = useMutation<
    UpsertManagerAnswerData,
    UpsertManagerAnswerVars
  >(UPSERT_MANAGER_ANSWER, {
    update(cache, { data }) {
      const upsertedAnswer = data?.upsertedAnswer;
      if (upsertedAnswer) {
        cache.writeFragment({
          id: `employee_careers:${upsertedAnswer.id}`,
          fragment: gql`
            fragment Answer on employee_careers {
              final_answer
              manager_answer
            }
          `,
          data: {
            final_answer: upsertedAnswer.finalAnswer,
            manager_answer: upsertedAnswer.managerAnswer,
          },
        });
      }
    },
  });

  const upsertManagerAnswer = useCallback(
    async ({
      id,
      employeeId,
      questionId,
      managerAnswer,
      finalAnswer,
    }: UpsertManagerAnswerVars) => {
      try {
        await upsertManagerSurveyAnswer({
          variables: {
            id,
            employeeId,
            questionId,
            managerAnswer,
            finalAnswer,
          },
        });
      } catch (ex) {
        toast.error((ex as ApolloError)?.message);
      }
    },
    [upsertManagerSurveyAnswer]
  );

  return { upsertManagerAnswer };
};
