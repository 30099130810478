import { useMemo, useState } from "react";
import { Question, Subcategory } from "types/career-path";
import { ManagerSurveyProps } from "../../components";
import { AnswersGroupedByQuestionIdType } from "../useQuestionsActions/useQuestionsActions";
import { generateKey } from "./utils";

export type CurrentSubcategory =
  | ({
      key: string;
      categoryName: string;
      subCategoryName: string;
      questions?: Question[];
      nextSubCategoryKey: string;
      previousSubCategoryKey: string;
    } & Partial<Subcategory>)
  | undefined;

export type QuestionsByCategoryAndSubcategory = Record<
  string,
  CurrentSubcategory
>;

type UseQuestionsProps = {
  answersGroupedByQuestionIdState: AnswersGroupedByQuestionIdType;
  isEvaluationSurvey?: boolean;
  isEvaluationSurveyPreview?: boolean;
} & Pick<ManagerSurveyProps, "careerPathConfig">;

export const useQuestions = ({
  careerPathConfig,
  answersGroupedByQuestionIdState,
  isEvaluationSurvey,
  isEvaluationSurveyPreview = false,
}: UseQuestionsProps) => {
  const mappedSubcategories = useMemo<QuestionsByCategoryAndSubcategory>(() => {
    return (
      careerPathConfig?.subcategories.reduce<QuestionsByCategoryAndSubcategory>(
        (
          acc,
          {
            category: { name: categoryName, ...restCategoryProps },
            questions,
            name: subCategoryName,
            ...rest
          },
          idx,
          arr
        ) => {
          const nextSubCategory = arr?.[idx + 1];
          const previousSubCategory = arr?.[idx - 1];
          return {
            ...acc,
            [generateKey(categoryName, subCategoryName)]: {
              key: generateKey(categoryName, subCategoryName),
              categoryName,
              subCategoryName,
              questions,
              category: { name: categoryName, ...restCategoryProps },
              ...rest,
              nextSubCategoryKey:
                nextSubCategory &&
                generateKey(
                  nextSubCategory?.category?.name,
                  nextSubCategory?.name
                ),
              previousSubCategoryKey:
                previousSubCategory &&
                generateKey(
                  previousSubCategory?.category?.name,
                  previousSubCategory?.name
                ),
            },
          };
        },
        {}
      ) || {}
    );
  }, [careerPathConfig?.subcategories]);

  const categoryKey = useMemo(() => {
    if (isEvaluationSurveyPreview) {
      return generateKey(
        careerPathConfig?.categories?.[0]?.name,
        careerPathConfig?.subcategories?.[0]?.name
      );
    }
    const evaluationCategoryKey =
      isEvaluationSurvey &&
      careerPathConfig?.subcategories?.reduce(
        (acc, { questions, category, name: subCategoryName }) =>
          !acc &&
          questions?.find(
            ({ id }) =>
              answersGroupedByQuestionIdState?.[id]?.finalAnswer === null
          )
            ? generateKey(category.name, subCategoryName)
            : acc,
        ""
      );
    return !evaluationCategoryKey
      ? careerPathConfig?.subcategories?.reduce(
          (acc, { questions, category, name: subCategoryName }) =>
            questions?.some(
              ({ id }) =>
                answersGroupedByQuestionIdState?.[id]?.managerAnswer !== null
            )
              ? generateKey(category.name, subCategoryName)
              : acc,
          ""
        )
      : evaluationCategoryKey;
  }, [
    answersGroupedByQuestionIdState,
    careerPathConfig?.categories,
    careerPathConfig?.subcategories,
    isEvaluationSurvey,
    isEvaluationSurveyPreview,
  ]);

  const [currentSubCategory, setCurrentSubCategory] =
    useState<CurrentSubcategory>(
      categoryKey
        ? mappedSubcategories?.[categoryKey]
        : careerPathConfig?.categories?.[0]?.name &&
          careerPathConfig?.subcategories?.[0]?.name
        ? mappedSubcategories?.[
            generateKey(
              careerPathConfig?.categories?.[0]?.name,
              careerPathConfig?.subcategories?.[0]?.name
            )
          ]
        : undefined
    );

  return {
    currentSubCategory,
    setCurrentSubCategory,
    mappedSubcategories,
    categoryKey,
    isLastSubcategory:
      currentSubCategory?.subCategoryName ===
      careerPathConfig?.subcategories?.at(-1)?.name,
  };
};
