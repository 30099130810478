import { ExtendsProjectsCellType } from "components/tableCells/tableCells";
import { useIsMobile } from "hooks";
import React, { FC } from "react";
import { DesktopAccordion, MobileAccordion } from "./components";

export type ProjectsAccordionProps = {
  projects: ExtendsProjectsCellType["projects"];
};

//TODO probably should be removed when we change all left projects cells
export const ProjectsAccordion: FC<ProjectsAccordionProps> = (props) => {
  const { isMobile } = useIsMobile();
  return (
    <>
      {isMobile ? (
        <MobileAccordion {...props} />
      ) : (
        <DesktopAccordion {...props} />
      )}
    </>
  );
};
