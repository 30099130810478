import { QueryHookOptions, gql, useQuery } from "@apollo/client";
import { CareerPathSubcategory, generateQuestions } from "hooks";
import { useMemo } from "react";

export type AllQuestionsByRole = Record<string, Record<string, boolean>>;

export interface GetAllQuestionsData {
  allQuestions: CareerPathSubcategory[];
}

export const GET_QUESTIONS = gql`
  query {
    allQuestions: career_path_subcategories {
      questions: career_path_questions(
        distinct_on: id
        where: { career_path_version: { current: { _eq: true } } }
      ) {
        id
      }
      subcategoryRoles: subcategory_roles {
        role
      }
    }
  }
`;

export const useGetAllRoleQuestions = (options?: QueryHookOptions) => {
  const { data, loading, error, ...rest } = useQuery<GetAllQuestionsData, QueryHookOptions>(
    GET_QUESTIONS,
    { fetchPolicy: "no-cache", ...options }
  );

  const { allQuestionsByRole } = useMemo<{
    allQuestionsByRole: AllQuestionsByRole;
  }>(() => {
    const allQuestionsByRole = generateQuestions(data?.allQuestions);
    return {
      allQuestionsByRole,
    };
  }, [data?.allQuestions]);

  return {
    data: allQuestionsByRole,
    loading,
    error,
    ...rest,
  };
};
