import {
  ApolloError,
  gql,
  MutationHookOptions,
  useMutation,
} from "@apollo/client";
import { useCallback } from "react";
import { toast } from "react-toastify";
import { GET_TICKETS } from "hooks/useTickets/useTickets";

type UpdateTicketData = {
  response: { id: string };
};

type UpdateTicketVars = {
  id: string;
  assignedToId: string;
  status: string;
  title: string;
  description: string;
};

const UPDATE_TICKET = gql`
  mutation UpdateManagerTask(
    $id: UUID!
    $assignedToId: UUID!
    $status: ManagerTaskStatusEnum!
    $title: String!
    $description: String
  ) {
    response: update_manager_task(
      id: $id
      assigned_to: $assignedToId
      status: $status
      title: $title
      description: $description
    ) {
      id
      status
    }
  }
`;

export const useUpdateTicket = (
  options?: MutationHookOptions<UpdateTicketData, UpdateTicketVars>
) => {
  const [updateTicketMutation, data] = useMutation<
    UpdateTicketData,
    UpdateTicketVars
  >(UPDATE_TICKET, { refetchQueries: [{ query: GET_TICKETS }], ...options });

  const updateTicket = useCallback(
    async (values: UpdateTicketVars) => {
      try {
        await updateTicketMutation({ variables: values });
      } catch (ex) {
        toast.error((ex as ApolloError)?.message);
      }
    },
    [updateTicketMutation]
  );

  return { updateTicket, ...data };
};
