import { useCallback } from "react";
import { ApolloError, gql, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { GET_EMPLOYEE_CONTRACTS } from "hooks/useEmployeeContracts/useEmployeeContracts";

type UpsertContractData = {
  response: { id: string };
};

// graphql syntax is not fully compatible between hasura and graphene so we have to use CreateUpdateContractInputType here
export type UpsertContractVars = {
  contract: {
    id?: string;
    employee_id: string;
    type: string;
    work_time?: string;
    start_date: string;
    end_date: string;
    holidays_b2b?: string;
    notice_period: string;
    note?: string;
  };
};

const UPSERT_CONTRACT = gql`
  mutation UpsertContract($contract: CreateUpdateContractInputType) {
    response: create_update_contract(contract: $contract) {
      id
    }
  }
`;

export const useUpsertContract = () => {
  const [upsertContractMutation, data] = useMutation<
    UpsertContractData,
    UpsertContractVars
  >(UPSERT_CONTRACT);

  const upsertContract = useCallback(
    async (values: UpsertContractVars) => {
      try {
        await upsertContractMutation({
          variables: values,
          refetchQueries: [
            {
              query: GET_EMPLOYEE_CONTRACTS,
              variables: {
                employeeId: values.contract.employee_id,
              },
            },
          ],
        });
      } catch (ex) {
        toast.error((ex as ApolloError)?.message);
      }
    },
    [upsertContractMutation]
  );

  return { upsertContract, ...data };
};
