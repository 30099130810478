import { QueryHookOptions, gql, useQuery } from "@apollo/client";
import { useMemo } from "react";

type ProjectAssignmentData = {
  assignments: {
    id: string;
    employeeId: string;
    projectId: string;
    startDate: string;
    endDate: string;
    fte: number;
    billableStatus: boolean;
    categoryOfNonBillable?: string;
    reasonWhyNonBillable?: string;
    roleInProject?: { value: string; label: string };
    employee: {
      firstName: string;
      lastName: string;
      email: string;
      picture: string;
      role: {
        name: string;
      };
    };
  }[];
};

type ProjectAssignmentVars = {
  projectId: string;
};

export const GET_PROJECT_ASSIGNMENTS = gql`
  query GetProjectAssignments($projectId: uuid!) {
    assignments: project_employee_assignments(
      where: { project_id: { _eq: $projectId } }
    ) {
      id
      employeeId: employee_id
      projectId: project_id
      roleInProject: project_employee_role {
        value
        label
      }
      startDate: start_date
      endDate: end_date
      fte
      billableStatus: billable_status
      categoryOfNonBillable: category_of_non_billable
      reasonWhyNonBillable: reason_why_non_billable
      employee {
        firstName: first_name
        lastName: last_name
        email
        role {
          name: erp_employee_role
        }
      }
    }
  }
`;

export const useProjectAssignments = (
  options: QueryHookOptions<ProjectAssignmentData, ProjectAssignmentVars>
) => {
  const { data, loading, error } = useQuery<
    ProjectAssignmentData,
    ProjectAssignmentVars
  >(GET_PROJECT_ASSIGNMENTS, options);

  const preparedData = useMemo(() => {
    return {
      assignments: data?.assignments.map((assignment) => ({
        ...assignment,
        roleInProject: assignment.roleInProject?.value ?? "",
        roleInProjectLabel: assignment.roleInProject?.label ?? "",
        employee: {
          ...assignment.employee,
          role: assignment.employee.role?.name,
        },
      })),
    };
  }, [data]);

  return { data: preparedData, loading, error };
};
