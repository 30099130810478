import { USER_CATEGORY_ROLES_ENUM } from "config/constants";
import { AllQuestionsByRole, CareerPathEmployee } from "hooks";

export enum CURRENT_SURVEY {
  EMPLOYEE = "Employee",
  MANAGER = "Manager",
  EVALUATION = "Evaluation",
  DONE = "Done",
}

const CURRENT_SURVEY_TYPE: Record<string, CURRENT_SURVEY> = {
  "false false false": CURRENT_SURVEY.EMPLOYEE,
  "true false false": CURRENT_SURVEY.MANAGER,
  "true true false": CURRENT_SURVEY.EVALUATION,
  "true true true": CURRENT_SURVEY.DONE,
};

export type QuestionsByRoleCount = Record<string, number>;

export const getSurveyStatus = ({
  allQuestionsByRole,
  questionsByRoleCount,
  allAnswersCount,
  submittedAnswers,
  managerAnswers,
  finalAnswers,
  role,
}: {
  allQuestionsByRole: AllQuestionsByRole;
  questionsByRoleCount: QuestionsByRoleCount;
  allAnswersCount: number;
  submittedAnswers: CareerPathEmployee["submittedAnswers"];
  managerAnswers: CareerPathEmployee["managerAnswers"];
  finalAnswers: CareerPathEmployee["finalAnswers"];
  role: CareerPathEmployee["role"];
}): {
  isEmployeeSurveyCompleted: boolean;
  isManagerSurveyCompleted: boolean;
  isEvaluationSurveyCompleted: boolean;
  currentSurvey: CURRENT_SURVEY;
} => {
  if (!allAnswersCount) {
    return {
      isEmployeeSurveyCompleted: false,
      isManagerSurveyCompleted: false,
      isEvaluationSurveyCompleted: false,
      currentSurvey: CURRENT_SURVEY.EMPLOYEE,
    };
  }

  const allQuestionsByRoleCount =
    role?.categoryRole === USER_CATEGORY_ROLES_ENUM.GENERAL
      ? questionsByRoleCount?.[USER_CATEGORY_ROLES_ENUM.GENERAL] || 0
      : (questionsByRoleCount?.[USER_CATEGORY_ROLES_ENUM.GENERAL] || 0) +
        (questionsByRoleCount?.[role?.categoryRole ?? ""] || 0);

  // survey "Employee"
  const submittedAnswersByRole = submittedAnswers?.filter(
    ({ questionId }) =>
      allQuestionsByRole?.[role?.categoryRole ?? ""]?.[questionId] ||
      allQuestionsByRole?.[USER_CATEGORY_ROLES_ENUM.GENERAL]?.[questionId]
  );
  const isEmployeeSurveyCompleted =
    submittedAnswersByRole?.length === allQuestionsByRoleCount;

  // survey "Manager"
  const managerAnswersByRole = isEmployeeSurveyCompleted
    ? managerAnswers?.filter(
        ({ questionId }) =>
          allQuestionsByRole?.[role?.categoryRole ?? ""]?.[questionId] ||
          allQuestionsByRole?.[USER_CATEGORY_ROLES_ENUM.GENERAL]?.[questionId]
      )
    : [];
  const isManagerSurveyCompleted =
    managerAnswersByRole?.length === allQuestionsByRoleCount;

  // survey "Evaluation"
  const finalAnswersByRole = isManagerSurveyCompleted
    ? finalAnswers?.filter(
        ({ questionId, finalAnswer }) =>
          (allQuestionsByRole?.[role?.categoryRole ?? ""]?.[questionId] ||
            allQuestionsByRole?.[USER_CATEGORY_ROLES_ENUM.GENERAL]?.[
              questionId
            ]) &&
          finalAnswer !== null
      )
    : [];
  const isEvaluationSurveyCompleted =
    finalAnswersByRole?.length === allQuestionsByRoleCount;

  return {
    isEmployeeSurveyCompleted,
    isManagerSurveyCompleted,
    isEvaluationSurveyCompleted,
    currentSurvey:
      CURRENT_SURVEY_TYPE[
        `${isEmployeeSurveyCompleted} ${isManagerSurveyCompleted} ${isEvaluationSurveyCompleted}`
      ],
  };
};
