import { useCallback } from "react";
import { ApolloError, gql, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { GET_EMPLOYEE_CONTRACTS } from "hooks/useEmployeeContracts/useEmployeeContracts";

type DeleteContractData = {
  response: { id: string };
};

type DeleteContractVars = {
  id: string;
};

const DELETE_CONTRACT = gql`
  mutation DeleteContract($id: uuid!) {
    delete_contracts_by_pk(id: $id) {
      id
    }
  }
`;

export const useDeleteContract = (employeeId?: string) => {
  const [deleteContractMutation, data] = useMutation<
    DeleteContractData,
    DeleteContractVars
  >(DELETE_CONTRACT);

  const deleteContract = useCallback(
    async (values: DeleteContractVars) => {
      try {
        await deleteContractMutation({
          variables: values,
          refetchQueries: [
            {
              query: GET_EMPLOYEE_CONTRACTS,
              variables: {
                employeeId: employeeId,
              },
            },
          ],
        });
      } catch (ex) {
        toast.error((ex as ApolloError)?.message);
      }
    },
    [deleteContractMutation, employeeId]
  );

  return { deleteContract, ...data };
};
