import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React, { FC, useState } from "react";
import { Arrow2Icon } from "resources/icons";
import { getProjectIcon } from "utils/timesheets.utils";
import { ProjectsAccordionProps } from "../../ProjectsAccordion";
import { ProjectButton } from "../projectButton/ProjectButton";

type DesktopAccordionProps = ProjectsAccordionProps;

export const DesktopAccordion: FC<DesktopAccordionProps> = ({ projects }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const Icon = getProjectIcon();
  const [firstProject, ...restProjects] = projects;
  return (
    <Accordion
      expanded={isExpanded}
      onChange={() => setIsExpanded((prev) => !prev)}
      elevation={0}
      onClick={(e) => projects?.length && e.stopPropagation()}
      classes={{
        root: "bg-transparent h-full p-0 m-0 border-none shadow-none [&.Mui-disabled]:bg-transparent before:h-0",
      }}
      disabled={!projects?.length}
      disableGutters
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        classes={{
          root: "[&.Mui-disabled]:opacity-100 text-grey-1 px-0 min-h-10",
          content: "my-0",
        }}
        expandIcon={
          !!restProjects?.length && (
            <Arrow2Icon className="h-6 w-6 -rotate-90" />
          )
        }
      >
        {firstProject ? (
          <ProjectButton
            projectId={firstProject.id}
            projectName={firstProject.name}
          >
            <Icon className="h-6 w-6" />
          </ProjectButton>
        ) : (
          "-"
        )}
      </AccordionSummary>
      <AccordionDetails
        classes={{
          root: "py-0 px-0",
        }}
      >
        <div className="flex flex-col items-start gap-2">
          {!!restProjects?.length &&
            restProjects.map(({ id, name }) => (
              <ProjectButton key={id} projectId={id} projectName={name}>
                <Icon className="h-6 w-6" />
              </ProjectButton>
            ))}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
