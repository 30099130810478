const styles = {
  dropdownMenu: `
    z-50
    block
    mt-3
    text-base 
    text-purple-1
    list-none 
    bg-white 
    rounded 
    shadow`,
  dropdownItem: `
    w-full
    rounded-none
    font-normal
    flex
    items-center
    justify-end
    px-4 
    py-2 
    text-purple-1
    hover:bg-purple-7`,
};

export default styles;
