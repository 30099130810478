import ROUTES from "config/routes";
import { useIsMobile } from "hooks";
import { useEffect, useMemo, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { ANSWER_TYPE, useQuestions, useQuestionsActions } from "../../hooks";
import { EvaluationSurveyProps } from "./EvaluationSurvey";

type UseEvaluationSurveyProps = Omit<EvaluationSurveyProps, "employeeData">;

export const useEvaluationSurvey = ({
  careerPathConfig,
  answers,
}: UseEvaluationSurveyProps) => {
  const { pathname } = useLocation();
  const isEvaluationSurveyPreview = !!matchPath(
    pathname,
    ROUTES.manager.careerPathDetails
  );
  const { isMobile } = useIsMobile();
  const [showFullSurvey, setShowFullSurvey] = useState<boolean>(
    isEvaluationSurveyPreview
  );

  const {
    answersGroupedByQuestionIdState,
    answersGroupedByQuestionId,
    handleManagerAnswer,
    isFormCompleted: isAllManagersAnswers,
  } = useQuestionsActions({ answers, answerType: ANSWER_TYPE.FINAL_ANSWER });
  const {
    currentSubCategory,
    setCurrentSubCategory,
    mappedSubcategories,
    isLastSubcategory,
    categoryKey,
  } = useQuestions({
    careerPathConfig,
    answersGroupedByQuestionIdState,
    isEvaluationSurvey: true,
    isEvaluationSurveyPreview,
  });

  const isFormCompleted = useMemo(
    () =>
      (careerPathConfig?.subcategories?.every(({ questions }) =>
        questions?.every(
          ({ id }) => answersGroupedByQuestionId?.[id]?.finalAnswer !== null
        )
      ) &&
        isAllManagersAnswers) ||
      false,
    [
      isAllManagersAnswers,
      answersGroupedByQuestionId,
      careerPathConfig?.subcategories,
    ]
  );
  const unresolvedQuestionsBySubcategory = useMemo(
    () =>
      careerPathConfig?.subcategories?.reduce(
        (acc, { questions, name }) => ({
          ...acc,
          [name]:
            questions?.filter(
              ({ id }) => answersGroupedByQuestionId?.[id]?.finalAnswer === null
            )?.length || 0,
        }),
        {}
      ),
    [answersGroupedByQuestionId, careerPathConfig?.subcategories]
  );

  useEffect(() => {
    if (isEvaluationSurveyPreview) {
      setShowFullSurvey(!isMobile);
    } else {
      isMobile && setShowFullSurvey(false);
    }
  }, [isMobile, isEvaluationSurveyPreview]);

  return {
    isEvaluationSurveyPreview,
    isFormCompleted,
    showFullSurvey,
    setShowFullSurvey,
    answersGroupedByQuestionIdState,
    answersGroupedByQuestionId,
    handleManagerAnswer,
    currentSubCategory,
    setCurrentSubCategory,
    mappedSubcategories,
    isLastSubcategory,
    categoryKey,
    unresolvedQuestionsBySubcategory,
    isMobile,
  };
};
