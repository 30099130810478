import { useCallback, useMemo } from "react";
import { QuestionsFooterProps } from "./QuestionsFooter";

type UseQuestionsFooterProps = Omit<
  QuestionsFooterProps,
  "isFormCompleted" | "isLastSubcategory" | "lastStepButtonText" | "to"
>;

export enum CHANGE_QUESTIONS_SUBCATEGORY {
  NEXT = "nextSubCategoryKey",
  PREVIOUS = "previousSubCategoryKey",
}

export const useQuestionsFooter = ({
  mappedSubcategories,
  currentSubCategory,
  answersGroupedByQuestionIdState,
  setCurrentSubCategory,
}: UseQuestionsFooterProps) => {
  const disableGoToNextQuestions = useMemo(
    () =>
      !currentSubCategory?.questions?.every(
        ({ id }) =>
          answersGroupedByQuestionIdState?.[id]?.managerAnswer !== null
      ),
    [answersGroupedByQuestionIdState, currentSubCategory?.questions]
  );
  const disableGoToPreviousQuestions = useMemo(
    () =>
      !!currentSubCategory?.key &&
      !mappedSubcategories?.[currentSubCategory.key]?.previousSubCategoryKey,
    [currentSubCategory?.key, mappedSubcategories]
  );

  const handleChangeQuestionsSubcategory = useCallback(
    (type: CHANGE_QUESTIONS_SUBCATEGORY) => {
      const subCategoryKey =
        currentSubCategory?.key &&
        mappedSubcategories?.[currentSubCategory.key]?.[type];
      const questions =
        !!subCategoryKey && mappedSubcategories?.[subCategoryKey];
      questions && setCurrentSubCategory(questions);
    },
    [currentSubCategory?.key, mappedSubcategories, setCurrentSubCategory]
  );

  return {
    handleChangeQuestionsSubcategory,
    disableGoToNextQuestions,
    disableGoToPreviousQuestions,
  };
};
