import { useMemo } from "react";
import moment from "moment";
import { useEmployeesBasic } from "hooks";

type HookProps = {
  searchValue: string;
  startDate: string;
  endDate: string;
};

export const useEmployeeQueryOptions = ({
  searchValue,
  startDate,
  endDate,
}: HookProps) => {
  const { data } = useEmployeesBasic({ includeArchived: false });

  const ids = useMemo(
    () =>
      data?.employees
        .filter(
          (employee) =>
            `${employee.firstName} ${employee.lastName}`
              .toLocaleLowerCase()
              .match(searchValue) ||
            `${employee.lastName} ${employee.firstName}`
              .toLocaleLowerCase()
              .match(searchValue)
        )
        .map(({ id }) => id),
    [data?.employees, searchValue]
  );

  if (data?.employees == null || searchValue.length < 3) {
    return {
      skip: true,
    };
  }

  return {
    variables: {
      startDate,
      endDate,
      startMonthDate: moment().startOf("month").toISOString(),
      endMonthDate: moment().endOf("month").toISOString(),
      employeeIds: ids ?? [],
    },
  };
};
