import { LinkButton } from "components";
import { USER_ROLES_ENUM } from "config/constants";
import ROUTES from "config/routes";
import React, { FC } from "react";
import { generatePath, useParams } from "react-router-dom";
import { QueryParams } from "types/career-path";

export const CareerPathFormCloseButton: FC = () => {
  const { employeeId } = useParams<QueryParams>();

  return (
    <LinkButton
      to={generatePath(ROUTES[USER_ROLES_ENUM.EMPLOYEE].careerPathDetails, {
        employeeId,
      })}
      variant="surveySecondary"
    >
      Continue later
    </LinkButton>
  );
};
