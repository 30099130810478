import { SortingFn, SortingFns } from "@tanstack/react-table";
import { TableData } from "components/table/types";

export const customSortingFns: Record<
  keyof SortingFns,
  SortingFn<TableData>
> = {
  customSorting: (rowA, rowB, columnId) =>
    (rowA.getValue<TableData>(columnId) || "").localeCompare(
      rowB.getValue<TableData>(columnId),
      "pl"
    ),
  plannedAbsencesSorting: (rowA, rowB, columnId) => {
    const a = rowA.getValue<TableData>(columnId)?.[0]?.start_time;
    const b = rowB.getValue<TableData>(columnId)?.[0]?.start_time;
    return a ? (new Date(a).getTime() < new Date(b).getTime() ? 1 : -1) : 1;
  },
  assignmentProjectSorting: (rowA, rowB) => {
    // always place the "No Project" group at the end
    const projectA = rowA.original.project;
    const projectB = rowB.original.project;
    if (projectA.id === "" && projectB.id !== "") return 1;
    if (projectB.id === "" && projectA.id !== "") return -1;
    return projectA.name.localeCompare(projectB.name);
  },
};
