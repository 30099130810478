import ROUTES from "config/routes";
import { CareerPathFormProgress } from "pages/careerPathForm/components/careerPathForm/components";
import {
  SURVEY_TYPE,
  SurveyComponentProps,
} from "pages/managerCareerPathForm/ManagerCareerPathForm.page";
import React, { FC } from "react";
import { generatePath } from "react-router-dom";
import { Subcategory } from "types/career-path";
import {
  QuestionsFooter,
  QuestionsHeader,
  SurveyViewHeader,
  SurveyViewWrapper,
} from "..";
import {
  ANSWER_TYPE,
  useQuestions,
  useQuestionsActions,
  useQuestionsAnswersVisibility,
} from "../../hooks";
import { QuestionsTable, ShowAllButton } from "./components";

export type ManagerSurveyProps = SurveyComponentProps;

export const ManagerSurvey: FC<ManagerSurveyProps> = ({
  careerPathConfig,
  employeeData,
  answers,
}) => {
  const {
    toggleQuestionsAnswersVisibility,
    questionsVisibility,
    toggleAllQuestionAnswerVisibility,
  } = useQuestionsAnswersVisibility({ answers });
  const {
    answersGroupedByQuestionIdState,
    answersGroupedByQuestionId,
    isFormCompleted,
    handleManagerAnswer,
  } = useQuestionsActions({
    answers,
    employeeData,
    answerType: ANSWER_TYPE.MANAGER_ANSWER,
  });
  const {
    currentSubCategory,
    setCurrentSubCategory,
    mappedSubcategories,
    isLastSubcategory,
  } = useQuestions({ careerPathConfig, answersGroupedByQuestionIdState });

  return (
    <>
      <SurveyViewHeader employeeData={employeeData} />
      <CareerPathFormProgress
        activeSubcategory={currentSubCategory as Subcategory}
        isFormCompleted={isFormCompleted && isLastSubcategory}
        categories={careerPathConfig?.categories ?? []}
        subcategories={careerPathConfig?.subcategories ?? []}
      />
      <SurveyViewWrapper>
        <div className="flex w-full max-w-240 flex-col bg-white md:mt-12 md:rounded md:border md:border-grey-5">
          <QuestionsHeader employeeData={employeeData}>
            <ShowAllButton
              showAll={questionsVisibility?.showAll}
              toggleAllQuestionAnswerVisibility={
                toggleAllQuestionAnswerVisibility
              }
            />
          </QuestionsHeader>
          <div className="flex items-center justify-center gap-2 px-12 py-8 text-purple-1 xs:border-b md:flex-col">
            <p className="text-lg font-semibold md:text-xs md:font-medium md:uppercase">
              {currentSubCategory?.categoryName}
            </p>
            <span className="sm:hidden">/</span>
            <p className="text-sm font-medium md:text-lg md:font-semibold">
              {currentSubCategory?.subCategoryName}
            </p>
          </div>
          <QuestionsTable
            toggleQuestionsAnswersVisibility={toggleQuestionsAnswersVisibility}
            questionsVisibility={questionsVisibility}
            handleManagerAnswer={handleManagerAnswer}
            answersGroupedByQuestionIdState={answersGroupedByQuestionIdState}
            answersGroupedByQuestionId={answersGroupedByQuestionId}
            currentSubCategory={currentSubCategory}
          />
          <QuestionsFooter
            answersGroupedByQuestionIdState={answersGroupedByQuestionIdState}
            mappedSubcategories={mappedSubcategories}
            currentSubCategory={currentSubCategory}
            setCurrentSubCategory={setCurrentSubCategory}
            isLastSubcategory={isLastSubcategory}
            isFormCompleted={isFormCompleted}
            to={{
              pathname: generatePath(ROUTES.manager.careerPathForm, {
                employeeId: employeeData?.employee?.id || "",
              }),
              state: { surveyType: SURVEY_TYPE.EVALUATION_SURVEY },
            }}
          />
        </div>
      </SurveyViewWrapper>
    </>
  );
};
