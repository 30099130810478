import { ApolloError, gql, useMutation } from "@apollo/client";
import { GET_SUBCATEGORY_ANSWERS, useURLSearchParams } from "hooks";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { EmployeeCareerPath, QueryParams } from "types/career-path";

interface UpsertEmployeeAnswerData {
  upsertedAnswer: Pick<
    EmployeeCareerPath,
    "id" | "employeeId" | "questionId" | "answer"
  >;
}

interface UpsertEmployeeAnswerVars {
  answer: boolean;
  questionId: string;
  id?: string;
}

const UPSERT_EMPLOYEE_ANSWER = gql`
  mutation upsertEmployeeAnswer(
    $answer: Boolean!
    $questionId: String!
    $id: String
  ) {
    upsertedAnswer: upsert_employee_answer(
      answer: $answer
      question_id: $questionId
      id: $id
    ) {
      id
      employeeId: employee_id
      questionId: question_id
      answer
    }
  }
`;

export const useUpsertEmployeeAnswer = () => {
  const { employeeId } = useParams<QueryParams>();
  const { searchParams } = useURLSearchParams();
  const subcategoryIdParam = searchParams.get("subcategoryId") || "";

  const [upsertEmployeeSurveyAnswer] = useMutation<
    UpsertEmployeeAnswerData,
    UpsertEmployeeAnswerVars
  >(UPSERT_EMPLOYEE_ANSWER, {
    refetchQueries: [
      {
        query: GET_SUBCATEGORY_ANSWERS,
        variables: {
          employeeId,
          isManager: false,
          subcategoryId: subcategoryIdParam,
        },
      },
    ],
  });

  const upsertEmployeeAnswer = useCallback(
    async ({ answer, questionId, id }: UpsertEmployeeAnswerVars) => {
      try {
        await upsertEmployeeSurveyAnswer({
          variables: {
            answer,
            questionId,
            id,
          },
        });
      } catch (ex) {
        toast.error((ex as ApolloError)?.message);
      }
    },
    [upsertEmployeeSurveyAnswer]
  );

  return { upsertEmployeeAnswer };
};
