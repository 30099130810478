import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  GET_EMPLOYEE_TIMESHEETS_AND_ASSIGNMENTS_WEEKLY,
  GET_EMPLOYEE_TIMESHEETS_AND_ASSIGNMENTS_MONTHLY,
  useIsMobile,
} from "hooks";
import { useCallback } from "react";
import { toast } from "react-toastify";
import { TimeRange, TimesheetExceptionDay } from "types/timesheets";

interface UpsertExceptionDayData {
  upsertedExceptionDay: TimesheetExceptionDay;
}

type UpsertExceptionDayVars = Required<
  Pick<TimesheetExceptionDay, "employeeId" | "day" | "reason" | "enabled">
> &
  Partial<Pick<TimesheetExceptionDay, "id">>;

const UPSERT_TIMESHEETS_EXCEPTION_DAY = gql`
  mutation upsertExceptionDay(
    $id: String
    $employeeId: String!
    $day: String!
    $reason: String!
    $enabled: Boolean!
  ) {
    upsertedExceptionDay: upsert_exception_day(
      id: $id
      employee_id: $employeeId
      day: $day
      reason: $reason
      enabled: $enabled
    ) {
      id
      employeeId: employee_id
      day
      enabled
      reason
    }
  }
`;

interface Props {
  employeeId: string;
  activeWeek: TimeRange;
  activeMonth: TimeRange;
}

export const useUpsertTimesheetExceptionDay = ({
  activeWeek,
  activeMonth,
  employeeId,
}: Props) => {
  const { start: weekStart, end: weekEnd } = activeWeek;
  const { start: monthStart, end: monthEnd } = activeMonth;

  const { isMobile } = useIsMobile();
  const [upsertTimesheetsExceptionDay] = useMutation<
    UpsertExceptionDayData,
    UpsertExceptionDayVars
  >(UPSERT_TIMESHEETS_EXCEPTION_DAY, {
    refetchQueries: [
      {
        query: GET_EMPLOYEE_TIMESHEETS_AND_ASSIGNMENTS_WEEKLY,
        variables: {
          startDate: weekStart,
          endDate: weekEnd,
          contractStartDate: weekStart,
          contractEndDate: weekStart,
          exceptionsStartDate: weekStart,
          exceptionsEndDate: weekEnd,
          employeeId,
          isManager: true,
        },
      },
      ...(isMobile
        ? []
        : [
            {
              query: GET_EMPLOYEE_TIMESHEETS_AND_ASSIGNMENTS_MONTHLY,
              variables: {
                startDate: monthStart,
                endDate: monthEnd,
                contractStartDate: monthStart,
                contractEndDate: monthStart,
                exceptionsStartDate: monthStart,
                exceptionsEndDate: monthEnd,
                employeeId,
                isManager: false,
              },
            },
          ]),
    ],
  });

  const upsertExceptionDay = useCallback(
    async (values: UpsertExceptionDayVars) => {
      try {
        await upsertTimesheetsExceptionDay({ variables: values });
      } catch (ex) {
        toast.error((ex as ApolloError)?.message);
      }
    },
    [upsertTimesheetsExceptionDay]
  );

  return { upsertExceptionDay };
};
