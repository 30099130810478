import React, { FC, memo, useMemo } from "react";
import moment from "moment";
import { Loading, UserAvatar, WeekStatusChip } from "components";
import { useAuthContext } from "contexts";
import { useEmployeesTimesheetsState } from "hooks";
import styles from "../../BreadcrumbsSelectorContent.styles";
import {
  HandleOptionSelectType,
  SELECTOR_TABS,
} from "../../useBreadcrumbsSelectorContent";
import { useGetParams } from "../hooks";

type MyEmployeesTabProps = {
  searchValue: string;
  handleOptionSelect: HandleOptionSelectType;
};
export const MyEmployeesTab: FC<MyEmployeesTabProps> = ({
  searchValue,
  handleOptionSelect,
}) => {
  const { employeeId, startOfPrevWeek, endOfPrevWeek } = useGetParams();
  const { user } = useAuthContext();

  const { employeesData, loading } = useEmployeesTimesheetsState({
    variables: {
      startDate: startOfPrevWeek,
      endDate: endOfPrevWeek,
      directSupervisorId: user?.managerId,
      startMonthDate: moment().startOf("month").toISOString(),
      endMonthDate: moment().endOf("month").toISOString(),
    },
  });

  const employeesList = useMemo(
    () =>
      searchValue
        ? employeesData.filter(
            ({ firstName, lastName }) =>
              `${firstName} ${lastName}`
                .toLocaleLowerCase()
                .match(searchValue) ||
              `${lastName} ${firstName}`.toLocaleLowerCase().match(searchValue)
          )
        : employeesData,
    [employeesData, searchValue]
  );

  return (
    <>
      {loading && (
        <div className="pb-4">
          <Loading />
        </div>
      )}
      {!loading &&
        employeesList.map(({ id, firstName, lastName, weekState, picture }) => (
          <button
            key={id}
            className={`${styles.listItem} ${
              id === employeeId && styles.listItemSelected
            }`}
            onClick={() =>
              handleOptionSelect({
                selectedValue: {
                  id,
                  value: `${firstName} ${lastName}`,
                  type: SELECTOR_TABS.MY_EMPLOYEE,
                  iconProps: { firstName, lastName, picture },
                },
              })
            }
          >
            <div className="flex items-center">
              <UserAvatar
                styles={styles.avatar}
                {...{ firstName, lastName, picture }}
              />
              <p className={styles.listItemName}>
                {lastName} {firstName}
              </p>
              <span className={styles.chipWrapper}>
                <WeekStatusChip status={weekState} />
              </span>
            </div>
          </button>
        ))}
      {!employeesList.length && !loading && (
        <p className="pb-4 text-center">No data</p>
      )}
    </>
  );
};
export const MyEmployeesTabMemo = memo(MyEmployeesTab);
