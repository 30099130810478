import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export const useURLSearchParams = () => {
  const { search } = useLocation();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  return { searchParams };
};
