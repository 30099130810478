const styles = {
  background: `
    mx-4 
    py-8
    sm:mx-8 
    lg:py-0
    lg:my-auto
    flex 
    flex-col 
    justify-center
    items-center`,
  container: `
    m-auto
    flex 
    flex-col 
    w-full
    px-6
    py-12
    sm:px-32 
    sm:py-16 
    max-w-160
    rounded-sm 
    bg-white 
    items-center`,
  backButton: ` 
    mb-8
    lg:mt-0
    lg:absolute
    lg:top-8
    lg:left-8
    self-start`,
  saveLink: `
    hover:underline
    text-sm
    text-acai-dark-purple-4  
    p-1
    rounded 
    outline-none 
    focus:outline-none 
    ease-linear 
    transition-all 
    duration-150`,
};

export default styles;
