import { useProjectManageContext } from "hooks/useProjectManageContext/useProjectManageContext";
import React from "react";

const statusColorMap: Record<string, string> = {
  ACTIVE: "bg-green-4 text-[#37b400]",
  ARCHIVED: "bg-red-3 text-[#f31700]",
  CLOSED: "bg-red-3 text-[#f31700]",
  UPCOMING: "bg-[#0058e91f] text-[#0058e9]",
};

type ProjectStatusChipProps = {
  status: string;
};

export const ProjectStatusChip: React.FC<ProjectStatusChipProps> = ({
  status,
}) => {
  const { data: context } = useProjectManageContext();
  const label = context?.statuses.find((item) => item.value === status)?.label;

  return (
    <div
      className={`${statusColorMap[status]} w-max whitespace-nowrap rounded px-2 py-1 text-xs font-medium`}
    >
      {label}
    </div>
  );
};
