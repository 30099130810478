import { CellContext } from "@tanstack/react-table";
import { LinkButton, Tooltip, UserAvatar } from "components";
import React from "react";
import { LinkProps } from "react-router-dom";
import { Employee } from "types/employee";
import { ProjectsAccordion } from "./components";

type ExtendLastNameCellType = Pick<
  Employee,
  "firstName" | "lastName" | "picture"
> & { subtitle?: string };

export const LastNameCell = <L extends object>({
  firstName,
  lastName,
  picture,
  path,
  subtitle,
}: ExtendLastNameCellType & {
  path?: LinkProps<L>["to"];
}) => {
  const textClasses = `w-42 max-w-42 overflow-hidden text-ellipsis whitespace-nowrap text-sm text-grey-1`;
  return (
    <div className="flex h-10 grow items-center">
      <UserAvatar
        styles="w-9 h-9 mr-2 text-xs"
        {...{ picture, firstName, lastName }}
      />
      <span className="flex flex-col items-start">
        {path ? (
          <LinkButton
            to={path}
            onClick={(e) => e.stopPropagation()}
            className="group p-0"
          >
            <span
              className={`${textClasses} font-semibold group-hover:underline group-hover:underline-offset-2`}
            >
              {lastName} {firstName}
            </span>
          </LinkButton>
        ) : (
          <span className={`${textClasses} font-semibold`}>
            {lastName} {firstName}
          </span>
        )}
        {subtitle && <span className={`mt-1 ${textClasses}`}>{subtitle}</span>}
      </span>
    </div>
  );
};

export type ExtendsProjectsCellType = {
  projects: {
    id: string;
    name: string;
  }[];
};

export const projectsCell = <D extends ExtendsProjectsCellType>({
  getValue,
}: CellContext<D, ExtendsProjectsCellType["projects"]>) => (
  <ProjectsAccordion projects={getValue()} />
);

export const ContractNoteCell = (note: string = "") => {
  return (
    <Tooltip
      content={<span className="block max-w-[400px] break-words">{note}</span>}
    >
      <span className="line-clamp-3  max-w-[16vw] text-ellipsis">{note}</span>
    </Tooltip>
  );
};
