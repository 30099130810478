const styles = {
  background: `
    my-12
    sm:mx-8 
    lg:py-0
    lg:my-auto
    flex`,
  container: `
    text-left
    m-auto
    flex 
    flex-col 
    w-full
    px-6
    py-6
    sm:px-16
    sm:py-16 
    max-w-lg
    rounded-sm`
}

export default styles
