import { ApolloError, gql, useMutation } from "@apollo/client";
import moment from "moment";
import { useCallback } from "react";
import { toast } from "react-toastify";

interface RequestExceptionDayData {
  result: { status: string };
}
interface RequestExceptionDayVars {
  day: string;
}

const REQUEST_EXCEPTION_DAY = gql`
  mutation requestExceptionDay($day: String!) {
    result: request_exception_day(day: $day) {
      status
    }
  }
`;

export const useRequestExceptionDay = () => {
  const [requestExceptionDayMutation] = useMutation<
    RequestExceptionDayData,
    RequestExceptionDayVars
  >(REQUEST_EXCEPTION_DAY);

  const requestExceptionDay = useCallback(
    async ({ day }: RequestExceptionDayVars) => {
      try {
        await requestExceptionDayMutation({
          variables: {
            day: moment(day).format("YYYY-MM-DD"),
          },
        });
        toast.success("Request sent");
      } catch (ex) {
        toast.error((ex as ApolloError)?.message);
      }
    },
    [requestExceptionDayMutation]
  );

  return { requestExceptionDay };
};
