import moment, { Moment } from "moment";
import { CONTRACTS_TYPE } from "types/employee";
import {
  TIMESHEETS_CONFIG,
  Timesheet,
  TimesheetFormType,
} from "types/timesheets";
import { createValidator } from "utils/forms.utils";
import {
  calculateTimeBetweenDates,
  formatTotalMinutes,
  getOverlappingHoursTimesheets,
  getTimesheetsBetweenDates,
  sumTotalMinutes,
  transformLogEndTime,
} from "utils/timesheets.utils";

export const validator = createValidator({
  description: { presence: { allowEmpty: false } },
  taskId: {
    presence: { allowEmpty: false, message: "^Task ID can't be blank" },
  },
  startTime: {
    datetime: { notValid: "^Start time is invalid" },
    presence: { allowEmpty: false, message: "^Start time can't be blank" },
    equality: {
      attribute: "endTime",
      message: "^Start time can't be the same as end time",
      comparator: (start: Moment | string, end: Moment | string) => {
        return !moment(start).isSame(end);
      },
    },
  },
  endTime: (_: any, attributes: any) => ({
    presence: { allowEmpty: false, message: "^End time can't be blank" },
    datetime: {
      notValid: "^End time is invalid",
      earliest: attributes.startTime,
      tooEarly: "^End time can't be before start time",
    },
    equality: {
      attribute: "startTime",
      message: "^End time can't be the same as start time",
      comparator: (end: Moment | string, start: Moment | string) => {
        return !moment(end).isSame(start);
      },
    },
  }),
});

export const getCustomValidationErrors = ({
  timesheet,
  date,
  activeWeekTimesheets,
  timesheetValues,
  maxMinutes,
  contracts,
}: {
  timesheet: Timesheet | undefined;
  date: string | undefined;
  activeWeekTimesheets: Timesheet[];
  timesheetValues: TimesheetFormType;
  maxMinutes: number;
  contracts: Array<{
    type: CONTRACTS_TYPE;
  }> | null | undefined;
}): {
  overlapping: string | undefined;
  maxHours: string | undefined;
} => {
  const currentDayTimesheets = getTimesheetsBetweenDates(
    activeWeekTimesheets || [],
    moment(date).startOf("day").toISOString(),
    moment(date).endOf("day").toISOString()
  );
  const currentDayTimesheetsFiltered = currentDayTimesheets.filter(
    ({ id }) => id !== timesheet?.id
  );

  // error: overlapping
  const overlappingTimesheets = getOverlappingHoursTimesheets(
    activeWeekTimesheets.filter(({ id }) => id !== timesheet?.id),
    moment(timesheetValues.startTime).toISOString(),
    moment(timesheetValues.endTime).toISOString()
  );

  // error: max hours
  const dayTimesheetsTotalTime = sumTotalMinutes(currentDayTimesheetsFiltered);
  const currentTimesheetTotalTime = calculateTimeBetweenDates(
    timesheetValues.startTime,
    timesheetValues.endTime
  );

  const isUOP = contracts?.some((c) => c.type === CONTRACTS_TYPE.UOP);

  const maxHoursError =
    isUOP
      ? dayTimesheetsTotalTime + currentTimesheetTotalTime > maxMinutes
      : false;

  return {
    overlapping: overlappingTimesheets.length
      ? "Overlapping timesheets: " +
        overlappingTimesheets
          .map(
            (t) =>
              `${moment(t.startTime).format("HH:mm")} - ${transformLogEndTime(
                moment(t.endTime),
                "add"
              ).format("HH:mm")}`
          )
          .join(", ")
      : undefined,
    maxHours: maxHoursError
      ? `Already logged for current day: ${formatTotalMinutes(
          dayTimesheetsTotalTime
        )}\nMaximum hours per day: ${maxMinutes / 60}h`
      : undefined,
  };
};
