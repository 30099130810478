import { USER_CATEGORY_ROLES_ENUM } from "config/constants";
import { AllQuestionsByRole, CareerPathSubcategory } from "hooks";

export const generateQuestions = (
  subcategories?: CareerPathSubcategory[]
): AllQuestionsByRole =>
  subcategories?.reduce<AllQuestionsByRole>(
    (acc, { questions, subcategoryRoles: [subcategoryRole] }) => ({
      ...acc,
      [subcategoryRole?.role || USER_CATEGORY_ROLES_ENUM.GENERAL]:
        questions?.reduce<Record<string, boolean>>(
          (acc, { id }) => ({
            ...acc,
            [id]: true,
          }),
          {
            ...acc?.[subcategoryRole?.role || USER_CATEGORY_ROLES_ENUM.GENERAL],
          }
        ),
    }),
    {}
  ) || {};
