import { GetLastSubmittedAnswersData } from "hooks";
import {
  Category,
  EmployeeCareerPath,
  FormQuestionsState,
  LastSubmittedAnswer,
  Subcategory,
} from "types/career-path";
import { tokenIsManager } from "./general.utils";

export const groupAnswersBySubcategories = (answers: EmployeeCareerPath[]) => {
  const result: { [key: string]: EmployeeCareerPath[] } = {};
  for (let i = 0; i < answers.length; i++) {
    const subcategoryId = answers[i].question.subcategoryId;
    try {
      result[subcategoryId].push(answers[i]);
    } catch {
      result[subcategoryId] = [answers[i]];
    }
  }
  return result;
};

export const groupSubcategories = (
  categories: Category[],
  subcategories: Subcategory[]
): Category[] => {
  return categories.reduce((arr, currentCategory) => {
    const currentCategorySubcategories = subcategories.filter(
      (s) => s.category.id === currentCategory.id
    );
    return [
      ...arr,
      { ...currentCategory, subcategories: currentCategorySubcategories },
    ];
  }, [] as Category[]);
};

export const calculateSubcategoryLevel = (
  subcategoryId: string,
  answers: EmployeeCareerPath[],
  isFinal: boolean
) => {
  const subcategoryAnswers = answers.filter(
    ({ question }) => question.subcategoryId === subcategoryId
  );
  const firstNoAnswer = isFinal
    ? subcategoryAnswers.find(({ finalAnswer }) => finalAnswer === false)
    : subcategoryAnswers.find(({ answer }) => !answer);

  if (firstNoAnswer) {
    const firstNoQuestionLevel = firstNoAnswer?.question?.level.value || 1;
    return firstNoQuestionLevel > 1 ? firstNoQuestionLevel - 1 : 1;
  }

  const lastYesAnswer = isFinal
    ? subcategoryAnswers.reverse().find(({ finalAnswer }) => finalAnswer)
    : subcategoryAnswers.reverse().find(({ answer }) => answer);
  if (lastYesAnswer) {
    return lastYesAnswer?.question.level.value;
  }

  return 0;
};

export const getQuestionsState = (
  subcategory: Subcategory,
  subcategoryAnswers: EmployeeCareerPath[]
): FormQuestionsState => {
  const subcategoryQuestions = subcategory.questions ?? [];

  // get questions employee/manager already answered from given subcategory
  const answeredQuestions = tokenIsManager()
    ? subcategoryAnswers.filter((a) => typeof a.managerAnswer === "boolean")
    : subcategoryAnswers.filter((a) => typeof a.answer === "boolean");

  const question =
    answeredQuestions.length === subcategoryQuestions.length
      ? // if employee already answered to all questions from given subcategory, return last question
        subcategoryQuestions[answeredQuestions.length - 1]
      : // else return first question without any answer
        subcategoryQuestions.find((q) =>
          tokenIsManager()
            ? !subcategoryAnswers.find((a) => a.questionId === q.id) ||
              subcategoryAnswers.find(
                (a) => a.questionId === q.id && a.managerAnswer === null
              )
            : !subcategoryAnswers.find((a) => a.questionId === q.id)
        );
  return { activeQuestionId: question?.id, focusedQuestionId: question?.id };
};

export const getActiveSubcategory = (
  subcategories: Subcategory[],
  allAnswers: EmployeeCareerPath[]
): Subcategory => {
  const subcategory = subcategories.find((sub) => {
    // find first subcategory which is not completed
    return !checkIsSubcategoryCompleted(allAnswers, sub);
  });
  return subcategory ?? subcategories[subcategories.length - 1];
};

export const checkIsSubcategoryCompleted = (
  answers: EmployeeCareerPath[],
  activeSubcategory?: Subcategory
): boolean => {
  const subcategoryQuestions = activeSubcategory?.questions ?? [];
  // get questions employee/manager already answered from given subcategory
  const answeredQuestions = subcategoryQuestions.filter(
    (q) =>
      !!answers.find((a) =>
        tokenIsManager()
          ? a.questionId === q.id && typeof a.managerAnswer === "boolean"
          : a.questionId === q.id && typeof a.answer === "boolean"
      )
  );
  const areAllSubcategoryQuestionsAnswered =
    subcategoryQuestions?.length === answeredQuestions.length;

  return areAllSubcategoryQuestionsAnswered;
};

export const checkIsBackDisabled = (
  subcategories: Subcategory[],
  activeSubcategory?: Subcategory
) => {
  const isFirstSubcategory = subcategories[0]?.id === activeSubcategory?.id;
  return isFirstSubcategory;
};

export const checkIsNextDisabled = (
  subcategoryAnswers: EmployeeCareerPath[],
  activeSubcategory?: Subcategory
): boolean => {
  return !checkIsSubcategoryCompleted(subcategoryAnswers, activeSubcategory);
};

export const getSubcategoriesCompletedPercentage = (
  answers: EmployeeCareerPath[],
  subcategories: Subcategory[]
) => {
  let filledSubcategoriesCounter = 0;
  subcategories.forEach((subcategory: Subcategory) => {
    if (checkIsSubcategoryCompleted(answers, subcategory)) {
      filledSubcategoriesCounter += 1;
    }
  });
  return (
    Math.round((filledSubcategoriesCounter / subcategories.length) * 100) || 0
  );
};

export const checkIsSubcategorySubmitted = (
  answers: LastSubmittedAnswer[],
  activeSubcategory?: Subcategory
): boolean => {
  const subcategoryQuestions = activeSubcategory?.questions ?? [];
  // get questions employee/manager submitted from given subcategory
  return subcategoryQuestions.every(({ id }) =>
    Boolean(answers.find(({ questionId }) => id === questionId))
  );
};

export const isAllAnswersSubmitted = (
  answers: GetLastSubmittedAnswersData | undefined,
  subcategories: Subcategory[]
) => {
  if (!answers?.lastSubmittedAnswers?.length) {
    return false;
  }
  return subcategories.every((subcategory) =>
    checkIsSubcategorySubmitted(answers.lastSubmittedAnswers, subcategory)
  );
};
