import { TypeOptions } from "react-toastify";

export const styles: Record<TypeOptions, string> = {
  error: `
    bg-red-2 
    text-white`,
  success: `
    bg-green-2 
    text-black`,
  default: "",
  info: "",
  warning: "",
};
