import { DefaultProjectIcon } from "resources/icons";

export const PORTAL_START_DAY = "2023-08-01";

export const FORM_INTERLUDE_SCREEN_TYPES = [
  {
    img: "https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExZGM5MThjOWIxYzAwMDUyODY4MTA5YjQwM2JkZGNmZjdiYjc0YjE1OCZjdD1n/ZmuUzqOIEojjnd4tN8/giphy.gif",
    title:
      "According to a study by the University of California, helping others can boost your own happiness and well-being?",
    subtitle:
      "So, next time you have the opportunity to help out a colleague or lend a hand to someone in need, go for it! It might just make you feel happier and more fulfilled at work.",
  },
  {
    img: "https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExNDBjZDZlMDdlYzQ4NjZkNjM4MGNiMmE5ZGU3ZjYwMjY2ODYxYTZmNyZjdD1n/IhQLcuiKt622s/giphy-downsized-large.gif",
    title:
      "According to research by the University of Sussex, listening to music while working can improve productivity and creativity for some people?",
    subtitle:
      "However, the benefits of music on work performance depend on the type of music, the person's personality, and the nature of the task at hand.",
  },
];

export const T2G_INFO_URL =
  "https://acaisoft.atlassian.net/wiki/spaces/IHT/pages/1703510017/Employee+Evaluation+Process+talk2grow";

export const HELP_CENTER_EMPLOYEE_PORTAL_URL =
  "https://acaisoft.atlassian.net/servicedesk/customer/portal/34";

export const PROJECT_ICON_MAP = {
  // TODO add all projects with their icons
  general: DefaultProjectIcon,
  // TODO currently we don't use other icons than general one
  // TODO to reduce build size currently we remove unused project icons, which reduce main.js gzip size from ~ 1.1 MB to ~375 KB
  // "Platform 9": Platform9Icon,
  // "J&J - Robony": J_JIcon,
  // "Affinity Solutions": AffinityIcon,
  // "AT&T": ATTIcon,
  // "Frontdoor Customer Service Port": FrontdoorIcon
};
