type Props = {
  isActive: boolean
  completed: boolean
}

const getStyles = ({ isActive, completed }: Props) => ({
  circle: `
    rounded-xl 
    flex
    w-6
    h-6 
    ${
      completed
        ? "bg-acai-dark-purple-4 items-center justify-center"
        : isActive
        ? "ease-in-out duration-700 border-acai-dark-purple-4 border-8"
        : "border-acai-dark-purple-4/15 border-2"
    }`,
  categoryName: `
    ${
      completed
        ? "text-acai-dark-purple-3/50"
        : isActive
        ? "text-acai-dark-purple-4"
        : "text-acai-dark-purple-3/25"
    }
    uppercase 
    text-xs
    whitespace-nowrap
    absolute
    top-8
    font-bold`,
  progressWrapper: `
    w-48 
    m-4
    mt-0
    h-2 
    rounded-full `,
  progressBar: `
    bg-acai-dark-purple-4
    h-full 
    ease-in-out 
    duration-700`,
  progressPart: `
    flex 
    items-center
    w-full

    before:content-[''] 
    before:w-full 
    before:h-1 
    before:border-b
    before:border-6
    before:-mx-2 
  `,
  progressPartChecked: `
    before:border-acai-dark-purple-4
  `,
  progressPartNotChecked: `
    before:bg-acai-dark-purple-3/5
  `,
  bulletChecked: `
    flex
    items-center 
    justify-center 
    w-5 
    h-5 
    bg-acai-dark-purple-4 
    rounded-full 
    z-10 
    shrink-0
    `,
  bulletNotChecked: `
    flex
    items-center 
    justify-center 
    w-2 
    h-2 
    bg-white 
    rounded-full 
    shrink-0 
    `
})
export default getStyles
