import { ExternalLink } from "components";
import { HELP_CENTER_EMPLOYEE_PORTAL_URL } from "config/general";
import React, { FC } from "react";
import { ErrorFaceIcon } from "resources/icons";

interface ErrorProps {
  fullScreen?: boolean;
}

export const Error: FC<ErrorProps> = ({ fullScreen = false }) => {
  return (
    <div
      className={`flex h-full w-full grow flex-col items-center justify-center gap-10 bg-white px-6 text-purple-2 sm:mt-0 sm:justify-start sm:pb-20 sm:pt-20 ${
        fullScreen ? "min-h-screen" : ""
      }`}
    >
      <div className="flex w-full justify-center">
        <ErrorFaceIcon />
      </div>
      <div className="flex w-full max-w-120 flex-col gap-8">
        <div className="flex flex-col items-center justify-center gap-3">
          <h1 className="text-center text-lg font-bold sm:text-2xl">
            Oops! Something went wrong
          </h1>
          <p className="text-center text-sm sm:text-base">
            We&apos;re sorry, but it seems like there was an unexpected error.
            Our team has been notified and is working to fix it.
          </p>
        </div>
        <div className="flex flex-col items-center justify-center gap-3 rounded bg-purple-7 px-6 py-4 text-sm">
          <p className="text-center">
            {" "}
            To help us resolve this problem, please visit our{" "}
            <ExternalLink
              url={HELP_CENTER_EMPLOYEE_PORTAL_URL}
              title="Help Center"
              className="font-semibold xs:mb-8"
              isIcon={false}
            />{" "}
            and report an issue.
          </p>
        </div>
      </div>
    </div>
  );
};
