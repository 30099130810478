import { AxiosError } from "axios";
import { useAuthContext } from "contexts";
import {
  GetConfigData,
  useGetConfig,
  useGetYearHolidays,
  useURLSearchParams,
} from "hooks";
import { Moment } from "moment";
import React, {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
} from "react";
import { HolidayEvent, TIMESHEETS_CONFIG } from "types/timesheets";
import { getBorderDate } from "utils/timesheets.utils";

type TimesheetsContextType = {
  error?: AxiosError;
  holidaysData: HolidayEvent[];
  loading: boolean;
  configData?: GetConfigData;
  timesheetsConfig: {
    borderDate: Moment;
    maxMinutes: number;
    maxMinutesWithoutExceptionDay: number;
  };
  isManager: boolean;
};

const TimeSheetsContext = createContext<TimesheetsContextType>(
  {} as TimesheetsContextType
);

export const TimeSheetsContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const { isManager: getIsManager } = useAuthContext();
  const isManager = getIsManager();
  const { searchParams } = useURLSearchParams();
  const startDateParam = searchParams.get("startDate") || "";

  const { data: configData } = useGetConfig();

  // check holidays in google api
  const {
    data: holidaysData,
    error: holidaysError,
    loading: holidaysLoading,
  } = useGetYearHolidays({ date: startDateParam });

  const timesheetsConfig = useMemo(
    () => ({
      borderDate: getBorderDate(
        configData?.config?.TIMESHEETS_BORDER_TIME ||
          TIMESHEETS_CONFIG.BORDER_TIME
      ),
      maxMinutes:
        configData?.config?.TIMESHEETS_MAX_MINUTES ||
        TIMESHEETS_CONFIG.MAX_MINUTES,
      maxMinutesWithoutExceptionDay:
        configData?.config?.TIMESHEETS_MAX_MINUTES_WITHOUT_EXCEPTION_DAY ||
        TIMESHEETS_CONFIG.MAX_MINUTES_WITHOUT_EXCEPTION_DAY,
    }),
    [
      configData?.config?.TIMESHEETS_BORDER_TIME,
      configData?.config?.TIMESHEETS_MAX_MINUTES,
      configData?.config?.TIMESHEETS_MAX_MINUTES_WITHOUT_EXCEPTION_DAY
    ]
  );

  return (
    <TimeSheetsContext.Provider
      value={{
        holidaysData,
        loading: holidaysLoading,
        error: holidaysError,
        timesheetsConfig,
        configData,
        isManager,
      }}
    >
      {children}
    </TimeSheetsContext.Provider>
  );
};

export const useTimeSheetsContext = () => useContext(TimeSheetsContext);
