import { Button, Field, FieldError, TextControl } from "components";
import moment from "moment";
import { FormCommonPropsType, formInitialData } from "pages/timesheets/context";
import React, { FC } from "react";
import { Form } from "react-final-form";
import { LoadingIcon } from "resources/icons";
import { useExceptionDayForm } from "./useExceptionDayForm";
import { validator } from "./validators/validators";

export const ExceptionDayForm: FC<FormCommonPropsType> = ({
  formData = formInitialData,
  handleModalClose,
}) => {
  const { handleSubmit, initialValues, isExceptionDayEnabled } =
    useExceptionDayForm({
      formData,
      handleModalClose,
    });

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      keepDirtyOnReinitialize
      validate={validator}
    >
      {({ handleSubmit, submitting, hasValidationErrors, submitError }) => {
        return (
          <form className="flex h-full flex-col justify-between">
            <div>
              {Boolean(submitError) && <FieldError>{submitError}</FieldError>}
              <p className="mb-6 text-purple-1">
                {isExceptionDayEnabled
                  ? "Disable employee to add and edit logs and absences on this day."
                  : "Enable employee to add and edit logs and absences on this day."}
              </p>
              <p className="mb-1 text-xs text-purple-1">DATE</p>
              <p className="mb-5 text-sm">
                {moment(formData.date).format("dddd, D MMMM YYYY")}
              </p>
              <Field
                name="reason"
                label="Reason *"
                multiline
                rows={4}
                component={TextControl}
                dataCy="exception-day-reason"
              />
            </div>
            <div className="flex justify-center gap-2">
              <div className="flex gap-2">
                <Button
                  variant="secondary"
                  onClick={handleModalClose}
                  data-cy="exception-day-cancel-button"
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  className="flex min-w-18 items-center justify-center"
                  disabled={submitting || hasValidationErrors}
                  data-cy="exception-day-submit-button"
                  onClick={handleSubmit}
                >
                  {submitting ? (
                    <LoadingIcon className="animate-spin" />
                  ) : (
                    "Confirm"
                  )}
                </Button>
              </div>
            </div>
          </form>
        );
      }}
    </Form>
  );
};
