const styles = {
  barContainer: `
    fixed 
    w-full 
    bottom-0 
    right-0 
    flex
    justify-end 
    shadow-3xl
    bg-white
    xs
    py-6
    px-5`,
  leftArrow: `
    mr-2`,
  rightArrow: `
    ml-2
    rotate-180`,
};

export default styles;
