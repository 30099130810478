import { Checkbox } from "@mui/material";
import { ColumnFilterValue } from "@tanstack/react-table";
import { ColumnMultiFilterType } from "components/table/types";
import React, { FC } from "react";
import { CheckboxCheckIcon, CheckboxUncheckIcon } from "resources/icons";
import styles from "./ColumnMultiFilterItemsList.styles";

type ColumnMultiFilterItemsListProps = {
  checkedValues: ColumnFilterValue;
  handleCheck: (item: string) => void;
} & Pick<ColumnMultiFilterType, "items" | "columnId">;

export const ColumnMultiFilterItemsList: FC<
  ColumnMultiFilterItemsListProps
> = ({ checkedValues, handleCheck, items, columnId }) => {
  return (
    <ul
      role="listbox"
      aria-labelledby={`dropdownRoot-${columnId}`}
      className={styles.list}
    >
      {items.map((item) => {
        const itemValue = typeof item === "string" ? item : item.value;
        const itemLabel = typeof item === "string" ? item : item.label;
        const isChecked = !!checkedValues?.[itemValue];
        return (
          <li
            key={itemValue}
            role="option"
            aria-selected={isChecked}
            className={styles.listOption}
            onClick={() => handleCheck(itemValue)}
          >
            <Checkbox
              name={itemValue}
              id={itemValue}
              checked={isChecked}
              icon={<CheckboxUncheckIcon />}
              checkedIcon={<CheckboxCheckIcon />}
              classes={{
                root: "p-0",
              }}
            />
            <label
              htmlFor={itemValue}
              className="pointer-events-none ml-3 cursor-pointer select-none"
            >
              {itemLabel}
            </label>
          </li>
        );
      })}
    </ul>
  );
};
