import mapValues from "lodash.mapvalues";
import { MomentInput, utc } from "moment";
import validate from "validate.js";

type FinalFormValues = {
  [key: string]: any;
};

validate.extend(validate.validators.datetime, {
  parse: (value: MomentInput) => utc(value),
  format: (value: MomentInput) => utc(value).format("L"),
});

export const createValidator = (schema: object) => {
  const validator = (values: FinalFormValues) => {
    const errors = validate(values, schema);

    if (!errors) {
      return {};
    }

    return mapValues(errors, (err) =>
      Array.isArray(err) && err.length > 0 ? err[0] : err
    );
  };

  return validator;
};
