import { QueryHookOptions, gql, useQuery } from "@apollo/client";
import { USER_CATEGORY_ROLES_ENUM } from "config/constants";
import { Category, Subcategory } from "types/career-path";

export type GetCareerPathConfigData = {
  categories: Category[];
  subcategories: Subcategory[];
};

export interface GetCareerPathConfigVars {
  role1: string;
  role2: string;
  role3: string;
}

const GET_CAREER_PATH_CONFIG = gql`
  query getCareerPathConfig(
    $role1: String!
    $role2: category_role_enum!
    $role3: category_role_enum!
  ) {
    categories: career_path_categories(
      order_by: { order: asc }
      where: {
        _or: [
          {
            category_role: {
              erp_employee_roles: { erp_employee_role: { _eq: $role1 } }
            }
          }
          { for_role: { _eq: $role2 } }
        ]
      }
    ) {
      id
      name
      order
      icon
      color
    }
    subcategories: career_path_subcategories(
      order_by: { order: asc, career_path_category: { order: asc } }
      where: {
        _or: [
          { subcategory_roles: { role: { _eq: $role3 } } }
          { _not: { subcategory_roles: {} } }
        ]
      }
    ) {
      id
      name
      order
      category: career_path_category {
        id
        name
      }
      questions: career_path_questions(
        order_by: { order: asc }
        where: { career_path_version: { current: { _eq: true } } }
      ) {
        id
        text
        order
        level: career_path_level {
          id
          value
        }
      }
    }
  }
`;
interface Props {
  erpRole?: string;
  options?: QueryHookOptions<GetCareerPathConfigData, GetCareerPathConfigVars>;
}

export const useGetCareerPathConfig = ({
  erpRole = USER_CATEGORY_ROLES_ENUM.GENERAL,
  options,
}: Props) =>
  useQuery<GetCareerPathConfigData, GetCareerPathConfigVars>(
    GET_CAREER_PATH_CONFIG,
    {
      variables: {
        role1: erpRole,
        role2: USER_CATEGORY_ROLES_ENUM.GENERAL,
        role3: erpRole,
      },
      ...options,
    }
  );
