import { Row } from "@tanstack/react-table";
import { TableData } from "components/table/types";
import { useURLSearchParams } from "hooks";
import { useHistory } from "react-router-dom";

export type TableRowClickType<D extends TableData> = (row: Row<D>) => void;

export const useTableRowClick = (pathname: string, searchParam?: string) => {
  const history = useHistory();
  const { searchParams } = useURLSearchParams();

  const handleOnRowClick = <D extends TableData>(row: Row<D>) => {
    searchParam && searchParams.set(searchParam, row.original.id);
    history.push({
      pathname,
      search: searchParam ? `?${searchParams}` : "",
    });
  };

  return handleOnRowClick;
};
