import {
  Content,
  Portal,
  Provider,
  Root,
  TooltipContentProps,
  Trigger,
} from "@radix-ui/react-tooltip";
import React, { FC, PropsWithChildren } from "react";
import styles from "./Tooltip.styles";

// created with https://ui.shadcn.com/docs/components/tooltip
interface TooltipProps extends PropsWithChildren {
  align?: TooltipContentProps["align"];
  className?: string;
  triggerClassName?: string;
  content: React.ReactNode | null;
  side?: TooltipContentProps["side"];
  sideOffset?: TooltipContentProps["sideOffset"];
  asChild?: TooltipContentProps["asChild"];
}

export const Tooltip: FC<TooltipProps> = ({
  align = "center",
  className,
  triggerClassName,
  children,
  content,
  side = "bottom",
  sideOffset = 8,
  asChild = true,
}) => {
  return (
    <Provider delayDuration={0}>
      <Root>
        <Trigger asChild={asChild} className={triggerClassName}>
          {children}
        </Trigger>
        <Portal>
          <Content
            sideOffset={sideOffset}
            align={align}
            side={side}
            onClick={(e) => e.stopPropagation()}
            className={`${styles.tooltipContent} ${className}`}
          >
            {content}
          </Content>
        </Portal>
      </Root>
    </Provider>
  );
};
