import { BreadcrumbsSelector, Button, Dropdown, UserAvatar } from "components";
import { GIT_COMMIT_HASH } from "config/git";
import { useAuthContext } from "contexts";
import { useIsMobile } from "hooks";
import React, { FC, FocusEvent, useEffect, useState } from "react";
import { Arrow2Icon, HamburgerIcon } from "resources/icons";
import { NotificationsInfo } from "./components/notificationsInfo/NotificationsInfo";
import styles from "./Navbar.styles";

type NavbarProps = {
  sidebarOpen: boolean;
  setSidebarOpen: (value: boolean) => void;
  mobileSidebarOpen: boolean;
  setMobileSidebarOpen: (value: boolean) => void;
};

export const Navbar: FC<NavbarProps> = ({
  sidebarOpen,
  setSidebarOpen,
  mobileSidebarOpen,
  setMobileSidebarOpen,
}) => {
  const { user, logout } = useAuthContext();
  const [isUserMenuOpen, setUserMenuOpen] = useState(false);
  const { isMobile } = useIsMobile();

  useEffect(() => {
    GIT_COMMIT_HASH && console.log(`Version: ${GIT_COMMIT_HASH}`);
  }, []);

  const handleUserMenuBlur = (e: FocusEvent<HTMLButtonElement, Element>) => {
    if (!e.relatedTarget) {
      setUserMenuOpen(false);
    }
  };

  return (
    <header className={styles.topbar}>
      <div className="flex min-h-15 px-3 py-2 md:px-10">
        <div className="flex w-full items-center justify-between">
          <div className="flex items-center justify-start">
            {(isMobile ? !mobileSidebarOpen : !sidebarOpen) && (
              <Button
                aria-controls="sidebar"
                className={styles.openSidebarButton}
                onClick={() =>
                  isMobile ? setMobileSidebarOpen(true) : setSidebarOpen(true)
                }
              >
                <span className="sr-only">Open sidebar</span>
                <HamburgerIcon />
              </Button>
            )}

            <div className={`hidden md:block ${sidebarOpen ? "ml-57.5" : ""}`}>
              <BreadcrumbsSelector />
            </div>
          </div>

          <div className="flex items-center">
            <div className="ml-3 flex items-center gap-2">
              <NotificationsInfo />
              <Dropdown
                rootProps={{
                  open: isUserMenuOpen,
                  onOpenChange: setUserMenuOpen,
                }}
                triggerContent={
                  <Button
                    className="p-0 font-normal"
                    aria-expanded={isUserMenuOpen}
                    data-cy="dropdown-user"
                    onClick={() => setUserMenuOpen((prev) => !prev)}
                    onBlur={handleUserMenuBlur}
                  >
                    {user && (
                      <UserAvatar
                        picture={user.picture}
                        firstName={user.firstName || ""}
                        lastName={user.lastName || ""}
                        styles="w-8 h-8 text-base"
                      />
                    )}
                    <p className="hidden text-sm text-purple-1 md:block">
                      {user?.email}
                    </p>
                    <Arrow2Icon className="h-5 w-5 rotate-[270deg]" />
                  </Button>
                }
                contentProps={{ align: "end" }}
                contentContent={
                  <div
                    className={styles.userDropdownMenu}
                    data-cy="dropdown-user-menu"
                  >
                    <ul className="py-1" role="menu">
                      <li role="menuitem">
                        <Button
                          onClick={() => logout()}
                          className={styles.userMenuItem}
                        >
                          Sign out
                        </Button>
                      </li>
                    </ul>
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
