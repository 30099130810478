import { QueryHookOptions, QueryResult, gql, useQuery } from "@apollo/client";
import { useMemo } from "react";
import { Manager } from "types/general";

export type GetProjectManageContextData = {
  statuses: {
    value: string;
    label: string;
  }[];
  categories: {
    value: string;
    label: string;
  }[];
  serviceTypes: {
    value: string;
    label: string;
  }[];
  billingTypes: {
    value: string;
    label: string;
  }[];
  roles: {
    value: string;
    label: string;
  }[];
  nonBillableCategories: {
    value: string;
    label: string;
  }[];
  managers: Pick<Manager, "id" | "firstName" | "lastName" | "profile">[];
};

type HookType = QueryResult<GetProjectManageContextData> & {
  data: GetProjectManageContextData;
};

const GET_PROJECT_MANAGE_CONTEXT = gql`
  query {
    statuses: project_status {
      value
      label
    }
    categories: project_category {
      value
      label
    }
    serviceTypes: project_service_type {
      value
      label
    }
    billingTypes: project_billing_type {
      value
      label
    }
    roles: project_employee_role {
      value
      label
    }
    nonBillableCategories: project_non_billable_category {
      value
      label
    }
    managers {
      id
      firstName: first_name
      lastName: last_name
      profile: manager_employee_profile {
        status
      }
    }
  }
`;

export const useProjectManageContext = (
  options?: QueryHookOptions<GetProjectManageContextData>
): HookType => {
  const { data, ...rest } = useQuery<GetProjectManageContextData>(
    GET_PROJECT_MANAGE_CONTEXT,
    options
  );

  const parsedData: GetProjectManageContextData = useMemo(
    () => ({
      statuses: data?.statuses ?? [],
      categories: data?.categories ?? [],
      serviceTypes: data?.serviceTypes ?? [],
      billingTypes: data?.billingTypes ?? [],
      roles: data?.roles ?? [],
      nonBillableCategories: data?.nonBillableCategories ?? [],
      managers: data?.managers ?? [],
    }),
    [data]
  );

  return { data: parsedData, ...rest };
};
