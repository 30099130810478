import { UserAvatar } from "components";
import { ManagerSurveyProps } from "pages/managerCareerPathForm/components/managerSurvey/ManagerSurvey";
import React, { FC, PropsWithChildren } from "react";

type QuestionsHeaderProps = Pick<ManagerSurveyProps, "employeeData">;

export const QuestionsHeader: FC<PropsWithChildren<QuestionsHeaderProps>> = ({
  employeeData,
  children,
}) => {
  return (
    <div className="flex justify-between border-b px-12 py-3 xs:bg-purple-7 md:border-grey-5">
      <div className="hidden items-center md:flex">
        <UserAvatar
          styles="w-9 h-9 mr-2 text-xs"
          picture={employeeData?.employee?.picture || ""}
          firstName={employeeData?.employee?.firstName || ""}
          lastName={employeeData?.employee?.lastName || ""}
        />
        <p className="text-sm font-semibold text-purple-1">
          {`${employeeData?.employee?.firstName} ${employeeData?.employee?.lastName}`}
        </p>
      </div>
      {children}
    </div>
  );
};
