import React, { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

type TabPanelProps<K extends string> = PropsWithChildren & {
  activeTab: K;
  className?: string;
};

export const TabPanel = <K extends string>({
  children,
  activeTab,
  className,
}: TabPanelProps<K>) => {
  return (
    <div
      className={twMerge("px-5 py-5 md:px-0 md:pt-10", className)}
      role="tabpanel"
      id={`tabpanel-${activeTab}`}
      aria-labelledby={`tab-${activeTab}`}
    >
      {children}
    </div>
  );
};
