import { Loading, UserAvatar, WeekStatusChip } from "components";
import { useEmployeesTimesheetsState } from "hooks";
import React, { FC, memo } from "react";
import styles from "../../BreadcrumbsSelectorContent.styles";
import {
  HandleOptionSelectType,
  SELECTOR_TABS,
} from "../../useBreadcrumbsSelectorContent";
import { useGetParams } from "../hooks";
import { useEmployeeQueryOptions } from "../hooks/useEmployeeQueryOptions/useEmployeeQueryOptions";

type EmployeesTabProps = {
  searchValue: string;
  handleOptionSelect: HandleOptionSelectType;
};

export const EmployeesTab: FC<EmployeesTabProps> = ({
  searchValue,
  handleOptionSelect,
}) => {
  const { employeeId, startOfPrevWeek, endOfPrevWeek } = useGetParams();

  const options = useEmployeeQueryOptions({
    searchValue,
    startDate: startOfPrevWeek,
    endDate: endOfPrevWeek,
  });

  const { employeesData, loading } = useEmployeesTimesheetsState(options);

  return (
    <>
      {loading && (
        <div className="pb-4">
          <Loading />
        </div>
      )}
      {!loading &&
        employeesData.map(({ id, firstName, lastName, weekState, picture }) => (
          <button
            key={id}
            className={`${styles.listItem} ${
              id === employeeId && styles.listItemSelected
            }`}
            onClick={() =>
              handleOptionSelect({
                selectedValue: {
                  id,
                  value: `${firstName} ${lastName}`,
                  type: SELECTOR_TABS.EMPLOYEE,
                  iconProps: { firstName, lastName, picture },
                },
              })
            }
          >
            <div className="flex items-center">
              <UserAvatar
                styles={styles.avatar}
                {...{ firstName, lastName, picture }}
              />
              <p className={styles.listItemName}>
                {lastName} {firstName}
              </p>
              <span className={styles.chipWrapper}>
                <WeekStatusChip status={weekState} />
              </span>
            </div>
          </button>
        ))}
      {searchValue.length < 3 && (
        <p className="pb-4 text-center">
          Enter at least 3 characters to start searching
        </p>
      )}
      {searchValue.length >= 3 && !employeesData.length && !loading && (
        <p className="pb-4 text-center">No data</p>
      )}
    </>
  );
};

export const EmployeesTabMemo = memo(EmployeesTab);
