import axios, { AxiosError } from "axios";
import API from "config/api";
import { useAuthContext } from "contexts";
import { AccountingReportType } from "pages/accounting/useAccounting";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";

type ExportReportParams = {
  filename?: string;
  params: { startDate: string; endDate: string };
};

export const useGetAccountingReport = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { token } = useAuthContext();

  const getAccountingReportJson = useCallback(
    async ({ params }: ExportReportParams) => {
      setLoading(true);
      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}${API.employeeReport}`,
          {
            start_day: params.startDate,
            end_day: params.endDate,
          },
          {
            headers: {
              "Authorization": token ? `Bearer ${token}` : "",
              "Content-Type": "application/json",
            },
            transformResponse: (response) => {
              const data: AccountingReportType = JSON.parse(response);
              //TODO to remove when handleOnRowClick will provide whole row object instead of id
              return (data ?? []).map((employee) => ({
                id: employee.employee_id,
                ...employee,
              }));
            },
          }
        );
        return data;
      } catch (err) {
        setError(true);
        toast.error((err as AxiosError).message);
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  return { loading, error, getAccountingReportJson };
};
