import {
  Button,
  Drawer,
  Field,
  Modal,
  ModalRefType,
  TextControl,
} from "components";
import { useApproveRejectTimesheet } from "hooks";
import React, { FC, useCallback, useRef } from "react";
import { Form } from "react-final-form";
import { LoadingIcon } from "resources/icons";
import {
  TimesheetRejectionFormType,
  TimesheetStateType,
} from "types/timesheets";
import { createValidator } from "utils/forms.utils";
import {
  FormCommonPropsType,
  useTimeSheetsWeekContext,
} from "../../../context";
import { DayOffInfo, TimesheetInfo, TimesheetProjectInfo } from "../components";

const validator = createValidator({
  stateInfo: { presence: { allowEmpty: false } },
});

// form to reject pending/approved log/day-off or edit rejected log
export const TimesheetRejectionForm: FC<FormCommonPropsType> = ({
  handleModalClose,
  formData,
  switchApproveRejectForm,
}) => {
  const timesheets = formData?.timesheets;
  const isDayOff = timesheets?.[0]?.logType !== "standard_hours";
  const isEdit = timesheets?.[0]?.state === TimesheetStateType.DECLINED;

  const initialValues = {
    stateInfo: timesheets?.[0]?.stateInfo,
  };
  const context = useTimeSheetsWeekContext();
  const { rejectTimesheet } = useApproveRejectTimesheet(context);

  const modalRef = useRef<ModalRefType>(null);
  const handleSubmit = useCallback(
    async (values: TimesheetRejectionFormType) => {
      modalRef.current?.closeModal();
      const mappedValues = timesheets?.map((t) => ({
        id: t.id,
        stateInfo: values.stateInfo,
      }));

      mappedValues && (await rejectTimesheet(mappedValues));
      handleModalClose();
    },
    [rejectTimesheet, handleModalClose, timesheets]
  );

  return (
    <Drawer
      isOpen
      onClose={handleModalClose}
      title={`${isEdit ? "Edit rejected" : "Rejecting"} ${
        isDayOff ? "absence" : "working hours"
      }`}
    >
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        keepDirtyOnReinitialize
        validate={validator}
      >
        {({ handleSubmit, submitting, pristine, hasValidationErrors }) => {
          return (
            <form className="flex h-full flex-col justify-between">
              <div>
                <div className="mb-8">
                  {!!timesheets?.[0].assignment && (
                    <TimesheetProjectInfo
                      name={timesheets[0].assignment.project.name}
                    />
                  )}
                  {!!timesheets?.[0] && (
                    <>
                      {isDayOff ? (
                        <DayOffInfo dayOff={timesheets[0]} />
                      ) : (
                        <TimesheetInfo timesheet={timesheets[0]} />
                      )}
                    </>
                  )}
                </div>

                <Field
                  name="stateInfo"
                  label="Write a reason for rejection *"
                  multiline
                  rows={5}
                  component={TextControl}
                />
              </div>
              <div className="flex justify-end gap-2">
                <Button variant="secondary" onClick={handleModalClose}>
                  Cancel
                </Button>
                {isEdit && (
                  <Button
                    variant="secondary"
                    onClick={() => switchApproveRejectForm?.(timesheets)}
                  >
                    Change to approved
                  </Button>
                )}
                <Button
                  variant="primary"
                  onClick={() => modalRef.current?.openModal()}
                  disabled={submitting || pristine || hasValidationErrors}
                  className={`${
                    isEdit ? "min-w-18" : "min-w-24"
                  } flex items-center justify-center`}
                >
                  {submitting ? (
                    <LoadingIcon className="animate-spin" />
                  ) : isEdit ? (
                    "Save"
                  ) : (
                    "Reject"
                  )}
                </Button>
              </div>
              <Modal
                ref={modalRef}
                onSubmit={handleSubmit}
                contentMessage={
                  isDayOff
                    ? "Reject employee absence plan."
                    : "Reject employee logged hours."
                }
              />
            </form>
          );
        }}
      </Form>
    </Drawer>
  );
};
