const styles = {
  tabs: `
    flex 
    justify-around 
    rounded-t`,
  tab: `
    flex w-1/2 
    items-center 
    justify-center 
    border-b-2 
    p-3`,
  tabLabel: `
    ml-2 
    text-sm`,
};
export default styles;
