import { List } from "@mui/material";
import { PageChangeBase } from "components";
import React, { FC, Fragment } from "react";
import { NoNotifcationsIcon } from "resources/icons";
import { NotificationAssignment } from "types/notifications";
import { NotificationItem } from "./NotificationItem";
import { NotificationsPaginationTabs } from "../../notificationsInfo/useNotificationsInfo";

type NotificationsListProps = {
  changePage: (page: number) => void;
  pagination: NotificationsPaginationTabs;
  onItemClick: () => void;
  notifications: NotificationAssignment[];
};

export const NotificationsList: FC<NotificationsListProps> = ({
  changePage,
  pagination: { currentPage, pageCount },
  onItemClick,
  notifications,
}) => {
  return (
    <>
      <div
        className={`mb-4 flex w-full flex-1 basis-auto flex-col ${
          !notifications.length ? "justify-center text-center" : ""
        }`}
      >
        {notifications.length > 0 ? (
          <List dense className="flex-1 basis-0 overflow-auto py-2">
            {notifications.map((notification) => (
              <Fragment key={notification.id}>
                <NotificationItem
                  notification={notification}
                  onItemClick={onItemClick}
                />
                <hr />
              </Fragment>
            ))}
          </List>
        ) : (
          <div className="mb-20">
            <NoNotifcationsIcon className="mx-auto mt-5 h-44 w-44" />
            <p className="mb-5 text-sm">
              You don&apos;t have any notifications.
            </p>
          </div>
        )}
      </div>
      {notifications.length ? (
        <PageChangeBase
          currentPage={currentPage}
          isNextDisabled={currentPage + 1 === pageCount}
          isPrevDisabled={currentPage === 0}
          goToNextPage={() => changePage(currentPage + 1)}
          goToPage={(page) => changePage(page)}
          goToPrevPage={() => changePage(currentPage - 1)}
          pageOptions={Array.from(Array(pageCount), (_, i) => i)}
        />
      ) : null}
    </>
  );
};
