import { gql, QueryHookOptions, QueryResult, useQuery } from "@apollo/client";
import { useMemo } from "react";
import { Manager } from "types/general";

type GetManagersData = {
  managers: Pick<Manager, "id" | "firstName" | "lastName">[];
};

type HookType = QueryResult<GetManagersData> & {
  data: GetManagersData;
};

const GET_MANAGERS = gql`
  query getManagers {
    managers {
      id
      firstName: first_name
      lastName: last_name
    }
  }
`;

const GET_ACTIVE_MANAGERS = gql`
  query getActiveManagers {
    managers(
      where: { manager_employee_profile: { status: { _neq: ARCHIVED } } }
    ) {
      id
      firstName: first_name
      lastName: last_name
    }
  }
`;

export const useManagers = (
  includeArchived = false,
  options?: QueryHookOptions<GetManagersData>
): HookType => {
  const query = includeArchived ? GET_MANAGERS : GET_ACTIVE_MANAGERS;

  const { data, ...rest } = useQuery<GetManagersData>(query, options);

  const parsedData = useMemo<GetManagersData>(
    () => ({
      managers: data?.managers ?? [],
    }),
    [data]
  );

  return {
    data: parsedData,
    ...rest,
  };
};
