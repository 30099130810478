const styles = {
  searchContainer: `
    w-full
    md:w-auto
    relative
    flex
    items-center`,
  searchInput: `
    w-full
    rounded
    border 
    border-grey-3
    focus:border-purple-2
    focus:outline-none
    py-2.5
    px-11
    placeholder:text-grey-1
    placeholder:font-medium
    placeholder:text-sm
    text-grey-1
    font-medium 
    text-sm`,
  icon: `
    text-grey-2 
    absolute
    top-1/2
    -translate-y-1/2
    w-4
    h-4`,
};

export default styles;
