import React, { FC } from "react";
import { Rating, SvgIconProps, SvgIcon } from "@mui/material";

const CircleIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 10 10" {...props}>
    <circle cx="5" cy="5" r="5" />
  </SvgIcon>
);

type SkillsRatingProps = {
  value: number;
};

export const SkillsRating: FC<SkillsRatingProps> = ({ value }) => {
  return (
    <Rating
      value={value}
      icon={<CircleIcon className="!text-[0.5rem] text-acai-dark-purple-4" />}
      emptyIcon={<CircleIcon className="!text-[0.5rem]" />}
      max={4}
      readOnly
      IconContainerComponent={(containerProps) => (
        <span
          {...containerProps}
          className={`${containerProps.className} pr-2`}
        />
      )}
    />
  );
};
