const styles = {
  dropdown: `
    mt-2
    max-w-60
    rounded
    border
    border-grey-3
    shadow-[0px 5px 30px 0px rgba(30, 7, 58, 0.05)]
    bg-white
    w-auto 
    flex
    flex-col
    text-purple-1`,
};

export default styles;
