export const styles: Record<"button" | "liElement" | "label", string> = {
  button: `
    sm:text-purple-1
    sm:text-sm 
    sm:w-full
    sm:bg-grey-7
    sm:hover:bg-grey-6
    sm:rounded-t-none
    sm:hover:text-purple-1
    sm:px-8 
    sm:py-3`,
  liElement: `
  h-12
  flex
  items-center`,
  label: `
  flex
  text-sm
  text-grey-1
  gap-3
  grow
  `,
};
