import { createValidator } from "utils/forms.utils";
import moment, { Moment } from "moment";
import { canAddDayOff } from "utils/timesheets.utils";
import { TimesheetExceptionDay } from "types/timesheets";

export const validator = createValidator({
  logType: {
    presence: { allowEmpty: false, message: "^Type can't be blank" },
  },
  startTime: {
    datetime: { notValid: "^Start date is invalid" },
    presence: { allowEmpty: false, message: "^Start date can't be blank" },
  },
  endTime: (_: any, attributes: any) => ({
    presence: { allowEmpty: false, message: "^End date can't be blank" },
    datetime: {
      notValid: "^End date is invalid",
      earliest: attributes.startTime,
      tooEarly: "^End date can't be before start date",
    },
  }),
  description: (_: any, attributes: any) => ({
    ...(attributes.logType === "other_reason" && {
      presence: { allowEmpty: false },
    }),
  }),
});

export const customDateValidator = (
  exceptionDaysData: TimesheetExceptionDay[],
  borderDate: Moment,
  value?: string | Moment
) => {
  if (!value || !moment(value).isValid()) {
    // empty value handled by validator
    return undefined;
  }
  return canAddDayOff(
    moment(value).toISOString(),
    exceptionDaysData,
    borderDate
  )
    ? undefined
    : "Past date is not allowed";
};
