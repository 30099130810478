import { gql, useQuery } from "@apollo/client";

export type Subtask = {
  dueDate: string;
  key: string;
  status: string;
  summary: string;
  type: string;
};

type CategoryLayout = {
  icon: string;
  color: string;
};

export type ObjectiveIssue = {
  key: string;
  summary: string;
  status: string;
  category: string;
  subcategory: string;
  subtasks: Subtask[];
  layout?: CategoryLayout;
};

type GetObjectivesData = {
  objectives: ObjectiveIssue[];
};

type GetObjectivesVariables = {
  email: string;
};

const GET_OKR = gql`
  query getOKR($email: String) {
    objectives: issues_per_user(email: $email) {
      key
      summary
      category
      subcategory
      layout {
        color
        icon
      }
      status
      subtasks {
        key
        dueDate: due_date
        summary
        status
        type
      }
    }
  }
`;

export const useGetObjectives = (email: GetObjectivesVariables["email"]) =>
  useQuery<GetObjectivesData, GetObjectivesVariables>(GET_OKR, {
    variables: { email },
    skip: !email,
  });
