import {
  ApolloError,
  gql,
  MutationHookOptions,
  useMutation,
} from "@apollo/client";
import { GET_TICKETS } from "hooks/useTickets/useTickets";
import { useCallback } from "react";
import { toast } from "react-toastify";

type SetTicketStatusData = {
  response: { id: string };
};

type SetTicketStatusVars = {
  ticketId: string;
  status: string;
};

const ASSIGN_TICKET = gql`
  mutation SetTicketStatus($ticketId: UUID!, $status: ManagerTaskStatusEnum!) {
    response: update_manager_task_status(id: $ticketId, status: $status) {
      id
    }
  }
`;

export const useSetTicketStatus = (
  options?: MutationHookOptions<SetTicketStatusData, SetTicketStatusVars>
) => {
  const [setTicketStatusMutation, data] = useMutation<
    SetTicketStatusData,
    SetTicketStatusVars
  >(ASSIGN_TICKET, { refetchQueries: [{ query: GET_TICKETS }], ...options });

  const setTicketStatus = useCallback(
    async (values: SetTicketStatusVars) => {
      try {
        await setTicketStatusMutation({ variables: values });
      } catch (ex) {
        toast.error((ex as ApolloError)?.message);
        throw ex;
      }
    },
    [setTicketStatusMutation]
  );

  return { setTicketStatus, ...data };
};
