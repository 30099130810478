import { Button, Dropdown } from "components";
import React, { FC, ReactNode, useState } from "react";
import { SettingsIcon } from "resources/icons";
import styles from "./DropdownMenu.styles";

type DropdownMenuItem = {
  label: string;
  icon?: ReactNode;
  onClick: () => void;
  disabled?: boolean;
};
type DropdownMenuProps = {
  items: DropdownMenuItem[];
  closeOnClick?: boolean | false;
};

export const DropdownMenu: FC<DropdownMenuProps> = ({
  items,
  closeOnClick,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <Dropdown
      rootProps={{
        open: isDropdownOpen,
        onOpenChange: setIsDropdownOpen,
      }}
      triggerContent={
        <Button
          className="h-6 p-0 font-normal"
          aria-expanded={isDropdownOpen}
          onClick={() => setIsDropdownOpen((prev) => !prev)}
        >
          <SettingsIcon className="h-6 w-6" />
        </Button>
      }
      contentProps={{ align: "end" }}
      contentContent={
        <div className={styles.dropdownMenu}>
          <ul className="py-1" role="menu">
            {items.map(({ disabled, icon, label, onClick }) => (
              <li key={label} role="menuitem">
                <Button
                  disabled={disabled}
                  className={styles.dropdownItem}
                  onClick={() => {
                    if (closeOnClick) {
                      setIsDropdownOpen(false);
                    }
                    onClick();
                  }}
                >
                  <span>{label}</span>
                  {icon}
                </Button>
              </li>
            ))}
          </ul>
        </div>
      }
    />
  );
};
