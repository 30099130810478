import { ProjectEmployeeAssignment } from "./assignments";
import { Timesheet } from "./timesheets";

export type Employee = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  picture?: string;
  timesheets?: Timesheet[];
  assignments?: ProjectEmployeeAssignment[];
  externalId?: string;
  role_id?: string;
  role?: Role;
  accountingId?: string;
  directSupervisorId?: string;
  directSupervisor?: Pick<
    Employee,
    "id" | "firstName" | "lastName" | "picture"
  >;
  department?: Department;
  departmentId?: Department["id"];
  status?: string;
  employee_personal_details_id?: string;
  personal?: {
    id: string;
    city?: string;
    country?: string;
    office?: Office;
    office_id?: Office["id"];
    phone?: string;
    email?: string;
    employment_start?: string;
    employment_end?: string;
    reason_for_termination?: string;
    is_treated_as_billable?: boolean;
    effigy_approval?: boolean;
    linkedin?: string;
    github?: string;
    resume?: string;
    candidate_source_id?: string;
    source?: {
      id: string;
      label: string;
    };
    english_level?: string;
    english?: {
      value: string;
      label: string;
    };
    employee_grade_id?: string;
    grade?: {
      id: string;
      label: string;
    };
    onboarding_buddy_id?: string;
    onboarding_buddy?: {
      id: string;
      firstName: string;
      lastName: string;
    };
  };
  has_children_below_8?: boolean;
  children?: EmployeeChild[];
};

export type Role = {
  id: string;
  name: string;
};

export type Department = {
  id: string;
  name: string;
};

export type Position = {
  id: string;
  name: string;
};

export type Office = {
  id: string;
  name: string;
};

export type EmployeeChild = {
  id: string;
  firstName: string;
  lastName: string;
  externalId: string;
  dob: string;
  employeeId: string;
};

export type Contract = {
  id?: string;
  employeeId: string;
  type: CONTRACTS_TYPE;
  workTime?: CONTRACTS_WORK_TIME_ENUM;
  startDate: string;
  endDate: string;
  isIndefiniteDate: boolean;
  paidDaysOff: string;
  noticePeriod: CONTRACT_NOTICE_PERIOD;
  note?: string;
};

export enum CONTRACTS_TYPE {
  B2B = "B2B",
  INTERNSHIP = "INTERNSHIP",
  UOD = "UOD",
  UOP = "UOP",
  UOZ = "UOZ",
}

export const ContractsTypeLabels: Record<CONTRACTS_TYPE, string> = {
  B2B: "B2B",
  INTERNSHIP: "Internship",
  UOD: "UOD",
  UOP: "UOP",
  UOZ: "UOZ",
};

export enum CONTRACT_NOTICE_PERIOD {
  "3 days" = "3 days",
  "30 days" = "30 days",
  "40 days" = "40 days",
  "1 week" = "1 week",
  "2 week" = "2 week",
  "3 week" = "3 week",
  "6 week" = "6 week",
  "1 Month" = "1 Month",
  "2 Months" = "2 Months",
  "3 Months" = "3 Months",
  "N/A" = "N/A",
}

export enum CONTRACTS_WORK_TIME_ENUM {
  TIME_1_8 = "TIME_1_8",
  TIME_2_8 = "TIME_2_8",
  TIME_3_8 = "TIME_3_8",
  TIME_4_8 = "TIME_4_8",
  TIME_5_8 = "TIME_5_8",
  TIME_6_8 = "TIME_6_8",
  TIME_7_8 = "TIME_7_8",
  TIME_8_8 = "TIME_8_8",
}

export const WORK_TIME_FRACTION_VALUE: Record<
  CONTRACTS_WORK_TIME_ENUM,
  number
> = {
  TIME_1_8: 1,
  TIME_2_8: 2,
  TIME_3_8: 3,
  TIME_4_8: 4,
  TIME_5_8: 5,
  TIME_6_8: 6,
  TIME_7_8: 7,
  TIME_8_8: 8,
};
export const WORK_TIME_MAX_VALUE = 8;

export const CONTRACTS_WORK_TIME_LABELS: Record<
  CONTRACTS_WORK_TIME_ENUM,
  string
> = {
  TIME_1_8: "1/8",
  TIME_2_8: "2/8",
  TIME_3_8: "3/8",
  TIME_4_8: "4/8",
  TIME_5_8: "5/8",
  TIME_6_8: "6/8",
  TIME_7_8: "7/8",
  TIME_8_8: "8/8",
};
