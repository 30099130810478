import { useIsMobile } from "hooks";
import {
  AnswersGroupedByQuestionIdType,
  CurrentSubcategory,
  HandleManagerAnswerType,
  QuestionsVisibilityType,
  ToggleQuestionsAnswersVisibilityType,
} from "pages/managerCareerPathForm/hooks";
import React, { FC } from "react";
import { DesktopQuestionsTable, MobileQuestionsTable } from "./components";

export type QuestionsTableProps = {
  answersGroupedByQuestionId: AnswersGroupedByQuestionIdType;
  answersGroupedByQuestionIdState: AnswersGroupedByQuestionIdType;
  currentSubCategory: CurrentSubcategory;
  questionsVisibility: QuestionsVisibilityType;
  toggleQuestionsAnswersVisibility: ToggleQuestionsAnswersVisibilityType;
  handleManagerAnswer: HandleManagerAnswerType;
};

export const QuestionsTable: FC<QuestionsTableProps> = (props) => {
  const { isMobile } = useIsMobile();

  return (
    <>
      {isMobile ? (
        <MobileQuestionsTable {...props} />
      ) : (
        <DesktopQuestionsTable {...props} />
      )}
    </>
  );
};
