import { Button } from "components";
import { GiveAnswerArgs } from "hooks";
import React, { FC, useEffect, useRef } from "react";
import { EmployeeCareerPath, Question } from "types/career-path";
import getStyles from "./FormQuestion.styles";

interface Props {
  question: Question;
  isActive: boolean;
  isFocused: boolean;
  pathAnswer?: EmployeeCareerPath;
  giveAnswer: (args: GiveAnswerArgs) => void;
}

export const CareerPathFormQuestion: FC<Props> = ({
  question,
  pathAnswer,
  isActive,
  isFocused,
  giveAnswer,
}) => {
  const answer = pathAnswer?.answer;
  const hasAnswer = typeof answer === "boolean";
  const isYesAnswer = answer === true;
  const isNoAnswer = answer === false;

  const isDisabled = !(hasAnswer || isActive);

  const styles = getStyles({ isActive, hasAnswer, isDisabled });

  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (isFocused && ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  }, [isFocused]);

  return (
    <div ref={ref} className={styles.questionContainer}>
      <p className={styles.questionText}>{question.text}</p>

      <div className={styles.buttonsContainer}>
        <Button
          className={`${styles.answerButton} ${
            isYesAnswer ? styles.chosen : styles.notChosen
          }`}
          disabled={isDisabled}
          type="button"
          onClick={
            isYesAnswer
              ? undefined // don't send mutation again if already answered
              : () =>
                  giveAnswer({
                    hasAnswer,
                    questionId: question.id,
                    answer: true,
                    id: pathAnswer?.id,
                  })
          }
        >
          Yes
        </Button>
        <Button
          className={`${styles.answerButton} ${
            isNoAnswer ? styles.chosen : styles.notChosen
          }`}
          disabled={isDisabled}
          type="button"
          onClick={
            isNoAnswer
              ? undefined // don't send mutation again if already answered
              : () =>
                  giveAnswer({
                    hasAnswer,
                    questionId: question.id,
                    answer: false,
                    id: pathAnswer?.id,
                  })
          }
        >
          No
        </Button>
      </div>
    </div>
  );
};
