import React, {
  ComponentType,
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import { ProjectEmployeeAssignment } from "types/assignments";
import { Timesheet, TimesheetExceptionDay } from "types/timesheets";
import {
  DayOffForm,
  TimesheetApprovalForm,
  TimesheetForm,
  TimesheetPreview,
  TimesheetRejectionForm,
} from "../../components/forms";

type FormData = {
  date?: string | undefined;
  assignment?: ProjectEmployeeAssignment | undefined;
  timesheets?: Timesheet[] | undefined;
  exceptionDay?: TimesheetExceptionDay;
};

export enum OPEN_FORM {
  TIMESHEET_FORM = "timesheetForm",
  REJECT_FORM = "rejectForm",
  APPROVE_FORM = "approveForm",
  DAY_OFF_FORM = "dayOffForm",
  PREVIEW = "preview",
}
interface TimesheetsFormsContextType {
  handleOpenForm: (type: OPEN_FORM, data?: FormData) => void;
  handleCloseForm: () => void;
}

export type FormCommonPropsType = {
  handleModalClose: () => void;
  formData?: FormData;
  switchApproveRejectForm?: (timesheets?: Timesheet[]) => void;
};
const FORM_COMPONENT: Record<OPEN_FORM, ComponentType<FormCommonPropsType>> = {
  [OPEN_FORM.TIMESHEET_FORM]: TimesheetForm,
  [OPEN_FORM.REJECT_FORM]: TimesheetRejectionForm,
  [OPEN_FORM.APPROVE_FORM]: TimesheetApprovalForm,
  [OPEN_FORM.PREVIEW]: TimesheetPreview,
  [OPEN_FORM.DAY_OFF_FORM]: DayOffForm,
};

const TimeSheetsFormsContext = createContext<TimesheetsFormsContextType>(
  {} as TimesheetsFormsContextType
);

const switchRejectApproveForm: Partial<Record<OPEN_FORM, OPEN_FORM>> = {
  [OPEN_FORM.APPROVE_FORM]: OPEN_FORM.REJECT_FORM,
  [OPEN_FORM.REJECT_FORM]: OPEN_FORM.APPROVE_FORM,
};

export const formInitialData: FormData = {
  date: undefined,
  assignment: undefined,
  timesheets: undefined,
  exceptionDay: undefined,
};

export const TimeSheetsFormsContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [openFormType, setOpenFormType] = useState<OPEN_FORM | undefined>();
  const [formData, setFormData] = useState<FormData>(formInitialData);

  const switchApproveRejectForm = useCallback<
    Required<FormCommonPropsType>["switchApproveRejectForm"]
  >((timesheets) => {
    setOpenFormType((prevState) =>
      prevState ? switchRejectApproveForm?.[prevState] : undefined
    );
    setFormData({ timesheets });
  }, []);

  //--------Context methods----------
  const handleCloseForm = useCallback(() => {
    setOpenFormType(undefined);
  }, []);

  const handleOpenForm = useCallback<
    TimesheetsFormsContextType["handleOpenForm"]
  >((type, data) => {
    setOpenFormType(type);
    setFormData({ ...formInitialData, ...data });
  }, []);
  //---------------------------------

  const FormComponent = openFormType && FORM_COMPONENT?.[openFormType];

  return (
    <TimeSheetsFormsContext.Provider
      value={{ handleOpenForm, handleCloseForm }}
    >
      {children}
      {!!FormComponent && (
        <FormComponent
          handleModalClose={handleCloseForm}
          formData={formData}
          switchApproveRejectForm={switchApproveRejectForm}
        />
      )}
    </TimeSheetsFormsContext.Provider>
  );
};

export const useTimeSheetsFormsContext = () =>
  useContext(TimeSheetsFormsContext);
