export const styles: Record<"pageNumber" | "arrowButton", string> = {
  pageNumber: `
    text-purple-2 
    w-8 
    h-8 
    p-1
    hover:bg-grey-6`,
  arrowButton: `
    rounded-[50%]
    text-purple-2
    [&>span>svg]:text-2xl
    w-8 
    h-8 
    disabled:text-grey-2
    hover:bg-grey-4
    p-0`,
};
