import { gql, useQuery } from "@apollo/client";
import { LastSubmittedAnswer } from "types/career-path";

export const GET_LAST_SUBMITTED_ANSWERS = gql`
  query lastSubmittedAnswersEmployee($employeeId: uuid) {
    lastSubmittedAnswers: last_submitted_answers(
      where: { employee_id: { _eq: $employeeId } }
    ) {
      answer
      questionId: question_id
      submittedOn: submitted_on
      employeeId: employee_id
      current
    }
  }
`;

export type GetLastSubmittedAnswersData = {
  lastSubmittedAnswers: LastSubmittedAnswer[];
};

type GetLastSubmittedAnswersVars = {
  employeeId: string;
};

interface Props {
  employeeId: string;
}

export const useLastSubmittedAnswers = ({ employeeId }: Props) => {
  return useQuery<GetLastSubmittedAnswersData, GetLastSubmittedAnswersVars>(
    GET_LAST_SUBMITTED_ANSWERS,
    { variables: { employeeId } }
  );
};
