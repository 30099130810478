import { gql, useQuery } from "@apollo/client";
import { Employee } from "../../types/employee";

export const GET_MY_EMPLOYEES = gql`
  query getMyEmployees($directId: uuid) {
    employees(where: { direct_supervisor_id: { _eq: $directId } }) {
      id
    }
  }
`;

type GetMyEmployeesVars = {
  directId: string;
};
export interface GetMyEmployeesData {
  employees: Array<Pick<Employee, "id">>;
}

interface UseGetMyEmployeesProps {
  managerId: string | undefined;
}

export const useGetMyEmployees = ({ managerId }: UseGetMyEmployeesProps) => {
  return useQuery<GetMyEmployeesData, GetMyEmployeesVars>(GET_MY_EMPLOYEES, {
    variables: {
      directId: managerId!,
    },
    skip: !managerId,
    fetchPolicy: "cache-and-network",
  });
};
