const styles = {
  label: `
    text-xs
    text-purple-1
    mb-1`,
  value: `
    text-sm 
    whitespace-pre-wrap
    mb-5`,
};
export default styles;
